import * as React from "react";
import { SVGProps } from "react";
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={512}
    height={512}
    viewBox="0 0 52 52"
    {...props}
  >
    <path
      fill="#deddff"
      d="M1.983 13.769v32.773a3.465 3.465 0 0 0 3.463 3.463h36.255a3.46 3.46 0 0 0 3.463-3.463V13.769z"
    />
    <path
      fill="#c6c6f7"
      d="M7.47 50.005H5.446a3.465 3.465 0 0 1-3.463-3.463V13.769h2.024v32.773a3.465 3.465 0 0 0 3.463 3.463z"
    />
    <path
      fill="#5b5e8b"
      d="M45.164 10.188v3.653H1.983v-3.653a3.465 3.465 0 0 1 3.463-3.463h36.255a3.46 3.46 0 0 1 3.463 3.463z"
    />
    <path
      fill="#312e4b"
      d="M7.47 6.725a3.465 3.465 0 0 0-3.463 3.463v3.653H1.983v-3.653a3.465 3.465 0 0 1 3.463-3.463z"
    />
    <circle cx={6.855} cy={10.264} r={1.257} fill="#deddff" />
    <circle cx={10.754} cy={10.264} r={1.257} fill="#deddff" />
    <circle cx={14.653} cy={10.264} r={1.257} fill="#deddff" />
    <path
      fill="#deddff"
      d="M41.55 10.269a.86.86 0 0 1-.855.854H24.133a.854.854 0 0 1-.855-.854c0-.477.378-.864.855-.864h16.562c.467 0 .854.387.854.864z"
    />
    <path
      fill="#c6c6f7"
      d="M37.936 32.504H20.507a.638.638 0 0 1 0-1.276h17.429a.638.638 0 0 1 0 1.276zM34.81 36.133H20.507a.638.638 0 0 1 0-1.276H34.81a.638.638 0 0 1 0 1.276zM33.247 39.761h-12.74a.638.638 0 0 1 0-1.276h12.74a.638.638 0 0 1 0 1.276zM31.685 43.39H20.507a.638.638 0 0 1 0-1.277h11.178a.638.638 0 0 1 0 1.277zM35.428 47.018H5.888a.638.638 0 0 1 0-1.276h29.54a.638.638 0 0 1 0 1.276z"
    />
    <path
      fill="#65b9ff"
      d="M5.25 27.292v-8.994c0-.875.71-1.584 1.584-1.584h33.479c.875 0 1.584.71 1.584 1.584v8.994c0 .875-.71 1.584-1.584 1.584H6.834c-.875 0-1.584-.71-1.584-1.584z"
    />
    <path
      fill="#3e7fff"
      d="M9.356 28.875h-2.52c-.88 0-1.59-.71-1.59-1.58v-9c0-.87.71-1.58 1.59-1.58h2.52c-.87 0-1.58.71-1.58 1.58v9c0 .87.71 1.58 1.58 1.58z"
    />
    <path fill="#46cc6b" d="m35.266 41.421 5.275 3.2 7.342-11.986-5.275-3.2z" />
    <path fill="#179c5f" d="m45.246 31.035 2.637 1.6-7.342 11.986-2.637-1.6z" />
    <path fill="#c6c6f7" d="m47.893 32.62-5.275-3.203.96-1.582 5.276 3.202z" />
    <path
      fill="#312e4b"
      d="m43.569 27.852 5.274 3.2.617-1.046a3.085 3.085 0 1 0-5.275-3.2z"
    />
    <path
      fill="#ffaf93"
      d="m35.264 41.418.01 3.324.006 2.176c.004.19.21.316.375.228l1.933-1 2.953-1.527z"
    />
    <path
      fill="#312e4b"
      d="m35.274 44.742.006 2.176c.004.19.21.316.375.228l1.939-.997z"
    />
    <path
      fill="#deddff"
      d="M15.27 22.549c.156-.118.295-.26.412-.425.207-.288.313-.652.313-1.08 0-1.1-.763-2.006-2.436-2.006h-2.355a.652.652 0 0 0-.65.66v6.057a.64.64 0 0 0 .189.46v.001a.64.64 0 0 0 .46.189h2.416c2.01 0 2.765-.93 2.765-2.157 0-.797-.429-1.359-1.115-1.7zm-.164 1.66c0 .811-.765.947-1.577.947h-1.666v-1.885h1.677c1.566 0 1.566.705 1.566.937zm-3.243-2.157v-1.766h1.627c.613 0 1.236.217 1.236.829 0 .78-.622.937-1.406.937zM21.842 25.096H18.78v-5.449c0-.863-1.309-.868-1.309 0v5.929c0 .455.366.829.83.829h3.542a.65.65 0 0 0 .66-.65c0-.36-.29-.659-.66-.659zM26.503 18.938c-2.112 0-3.656 1.44-3.703 3.787.046 2.284 1.539 3.78 3.703 3.78 2.256 0 3.663-1.612 3.704-3.787-.042-2.192-1.472-3.78-3.704-3.78zm2.395 3.784c0 1.478-.91 2.534-2.395 2.534-1.514 0-2.394-1.087-2.394-2.534 0-1.437.868-2.535 2.394-2.535 1.468 0 2.395 1.036 2.395 2.535zM36.793 22.182h-1.966c-.798 0-.816 1.219 0 1.219h1.446v1.485c-.478.239-.997.36-1.546.36-1.576 0-2.515-1.056-2.515-2.515 0-1.609 1.036-2.514 2.465-2.514.637 0 1.202.143 1.867.537a.647.647 0 0 0 .748-.11c.303-.32.163-.817-.123-.957-.88-.552-1.655-.749-2.591-.749-2.035 0-3.674 1.499-3.674 3.793 0 2.236 1.529 3.773 3.703 3.773.806 0 1.605-.195 2.372-.578a.872.872 0 0 0 .493-.8v-2.265a.676.676 0 0 0-.68-.679z"
    />
    <path
      fill="#e83d62"
      d="M50.008 9.519a7.831 7.831 0 0 1-2.88 6.462 7.886 7.886 0 0 1-4.335 1.762.49.49 0 0 0-.306.149c-1.266 1.258-2.755 1.514-3.516 1.555-.19.009-.298-.215-.182-.364.422-.537.579-1.026.604-1.44a.51.51 0 0 0-.323-.487 7.883 7.883 0 0 1-4.832-7.273c0-4.427 3.657-8.01 8.125-7.885 2.168.058 4.154 1.042 5.552 2.565 1.224 1.332 2.01 3.061 2.093 4.956z"
    />
    <path
      fill="#ce2955"
      d="M40.988 17.644c-.025.413-.182.902-.612 1.44a.255.255 0 0 0-.05.107c-.53.174-1.009.24-1.348.256-.19.009-.306-.215-.182-.364.422-.537.579-1.026.604-1.44a.518.518 0 0 0-.331-.487 7.876 7.876 0 0 1-4.824-7.273c0-4.427 3.657-8.01 8.117-7.885.19.008.389.017.579.041a7.886 7.886 0 0 0-7.107 7.844 7.876 7.876 0 0 0 4.823 7.273c.199.082.34.273.331.488z"
    />
    <path
      fill="#fcb73e"
      d="M16.727 43.39H6.881a1.254 1.254 0 0 1-1.254-1.254v-9.654c0-.692.561-1.254 1.254-1.254h9.846c.693 0 1.255.562 1.255 1.254v9.654c0 .692-.562 1.254-1.255 1.254z"
    />
    <path
      fill="#fd982e"
      d="M9.877 43.385h-3c-.69 0-1.25-.56-1.25-1.25v-9.65c0-.7.56-1.26 1.25-1.26h3c-.69 0-1.25.56-1.25 1.26v9.65c0 .69.56 1.25 1.25 1.25z"
    />
    <path
      fill="#deddff"
      d="M47.942 9.223c-.01-.21-.178-.357-.366-.388l-.409-.063a.44.44 0 0 1-.356-.314 4.084 4.084 0 0 0-.346-.755.468.468 0 0 1 .01-.482l.241-.356a.458.458 0 0 0-.031-.555 14.738 14.738 0 0 0-.398-.42 7.031 7.031 0 0 0-.44-.376.456.456 0 0 0-.555 0l-.357.24a.43.43 0 0 1-.471.032 7.91 7.91 0 0 0-.776-.304.455.455 0 0 1-.335-.335l-.073-.398a.434.434 0 0 0-.398-.356 4.645 4.645 0 0 0-1.205.041c-.189.021-.336.19-.356.388l-.053.335a.456.456 0 0 1-.314.367 4.32 4.32 0 0 0-.744.325.45.45 0 0 1-.472-.01l-.282-.19a.456.456 0 0 0-.556.032 5.126 5.126 0 0 0-.419.409c-.136.136-.251.283-.367.43a.441.441 0 0 0-.01.555l.199.272a.47.47 0 0 1 .031.472c-.115.23-.21.492-.282.754a.425.425 0 0 1-.357.325l-.335.073c-.199.032-.356.189-.367.377-.052.41-.031.818.01 1.216a.457.457 0 0 0 .378.367l.409.073c.167.02.303.136.356.304.084.262.21.513.335.754.084.157.094.335-.01.482l-.23.356a.489.489 0 0 0 .03.566c.126.136.263.283.41.42.135.125.282.261.429.376a.456.456 0 0 0 .555 0l.346-.251a.47.47 0 0 1 .482-.042c.251.136.503.241.775.304a.526.526 0 0 1 .325.356l.084.398c.042.189.199.336.398.357.388.03.807.02 1.194-.042.2-.032.346-.2.378-.388l.041-.346a.436.436 0 0 1 .325-.367c.252-.083.493-.199.734-.314a.45.45 0 0 1 .471.01l.273.179a.442.442 0 0 0 .555-.032 4.98 4.98 0 0 0 .419-.398c.136-.136.262-.283.377-.44a.456.456 0 0 0 .01-.555l-.198-.262a.469.469 0 0 1-.042-.482c.125-.23.22-.482.293-.734a.45.45 0 0 1 .346-.345l.346-.063a.444.444 0 0 0 .366-.388 5.683 5.683 0 0 0-.02-1.194zm-5.469 3.049a2.388 2.388 0 1 1 0-4.778c1.31 0 2.378 1.069 2.378 2.389s-1.068 2.389-2.378 2.389z"
    />
  </svg>
);
export default SvgComponent;
