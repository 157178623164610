import React from "react";

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      id="Layer_3"
      enableBackground="new 0 0 64 64"
      height="512"
      viewBox="0 0 64 64"
      width="512"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g>
        <path
          d="m54 25v28c0-2.209 1.791-4 4-4s4 1.791 4 4-1.791 4-4 4h-52c-2.209 0-4-1.791-4-4s1.791-4 4-4 4 1.791 4 4v-28z"
          fill="#f7d881"
        />
        <path
          d="m38.961 28.385c1.217.514 2.039 1.493 2.039 2.615 0 1.657-1.791 3-4 3s-4-1.343-4-3c0-1.122.822-2.101 2.039-2.615"
          fill="#f2c351"
        />
        <path
          d="m10 39h4c1.105 0 2-.895 2-2v-2.838c0-1.291.826-2.438 2.051-2.846l4.897-1.632c1.226-.409 2.052-1.555 2.052-2.846v-1.838h4v2.675c0 2.583-1.653 4.875-4.103 5.692l-4.214 1.405c-.408.136-.683.518-.683.949v1.279c0 1.105.895 2 2 2h20c1.105 0 2-.895 2-2v-12h4v12c0 1.105.895 2 2 2h4v4h-5c-.552 0-1 .448-1 1v4c0 .552.448 1 1 1h5v4h-5c-.552 0-1 .448-1 1v3h-4v-12c0-1.105-.895-2-2-2h-9c-1.105 0-2 .895-2 2v12h-4v-14h-7v.789c0 4.702-1.392 9.299-4 13.211h-5l.931-1.304c2.647-3.704 4.069-8.143 4.069-12.696h-6z"
          fill="#8892a0"
        />
        <path
          d="m2 53c0-2.209 1.791-4 4-4s4 1.791 4 4v-28c0-2.209-1.791-4-4-4s-4 1.791-4 4z"
          fill="#78b75b"
        />
        <path
          d="m54 53c0-2.209 1.791-4 4-4s4 1.791 4 4v-28c0-2.209-1.791-4-4-4s-4 1.791-4 4z"
          fill="#78b75b"
        />
        <path
          d="m37 31 7.2-9.6c1.168-1.558 1.8-3.453 1.8-5.4 0-4.971-4.029-9-9-9-4.971 0-9 4.029-9 9 0 1.947.632 3.842 1.8 5.4z"
          fill="#e47c6e"
        />
        <circle cx="37" cy="15" fill="#e7e4dd" r="4" />
        <g fill="#96cc7f">
          <path d="m54 25v19.855c3.752-4.722 6-10.693 6-17.193 0-2.221-.269-4.378-.763-6.447-.392-.128-.802-.215-1.237-.215-2.209 0-4 1.791-4 4z" />
          <path d="m2 25v19.855c3.752-4.722 6-10.693 6-17.193 0-2.221-.269-4.378-.763-6.447-.392-.128-.802-.215-1.237-.215-2.209 0-4 1.791-4 4z" />
        </g>
      </g>
    </svg>
  );
}

export default SvgComponent;
