import React from "react";

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 512 512" {...props}>
      <path
        d="M406.087 198.786c-11.578 0-22.908 1.852-33.657 5.453a105.872 105.872 0 009.707-44.306c0-58.4-47.513-105.913-105.913-105.913s-105.913 47.513-105.913 105.913c0 6.954.665 13.814 1.985 20.529-15.99-7.552-33.474-11.481-51.482-11.481C54.197 168.981 0 223.179 0 289.796c0 63.483 49.156 116.08 112.232 120.509.697.199 291.087.305 293.855.305 58.4 0 105.913-47.513 105.913-105.913s-47.513-105.911-105.913-105.911z"
        fill="#e5e5e5"
      />
      <g fill="#ccc">
        <path d="M332.64 117.356c14.238 0 28.205 1.04 41.824 3.012-15.707-38.858-53.821-66.347-98.24-66.347-58.4 0-105.913 47.513-105.913 105.913 0 2.716.105 5.417.308 8.102 44.498-31.724 100.791-50.68 162.021-50.68zM71.85 351.534c0-70.089 34.301-132.974 88.638-175.891-12.64-4.393-26.005-6.662-39.673-6.662C54.197 168.981 0 223.179 0 289.796c0 51.466 32.31 95.775 78.151 113.066a212.052 212.052 0 01-6.301-51.328z" />
      </g>
      <path
        d="M308.158 230.187h-15.991c20.18-7.39 34.619-26.789 34.619-49.497 0-29.053-23.637-52.69-52.69-52.69s-52.69 23.637-52.69 52.69c0 22.708 14.439 42.106 34.619 49.497h-15.991c-22.011 0-39.917 17.906-39.917 39.917v93.672a7.982 7.982 0 007.983 7.983h131.992a7.982 7.982 0 007.983-7.983v-93.672c0-22.011-17.906-39.917-39.917-39.917z"
        fill="#ffd652"
      />
      <path
        d="M225.131 363.775v-93.672c0-22.011 17.906-39.917 39.917-39.917h15.991c-20.18-7.39-34.619-26.789-34.619-49.497 0-24.743 17.149-45.547 40.183-51.178a52.632 52.632 0 00-12.507-1.513c-29.053 0-52.69 23.637-52.69 52.69 0 22.708 14.439 42.106 34.619 49.497h-15.991c-22.011 0-39.917 17.906-39.917 39.917v93.672a7.982 7.982 0 007.983 7.983h25.015a7.98 7.98 0 01-7.984-7.982z"
        fill="#fb9d46"
      />
      <circle cx={171.909} cy={377.081} r={80.898} fill="#ff7452" />
      <path
        d="M199.584 430.304c-44.679 0-80.898-36.219-80.898-80.898 0-15.244 4.222-29.499 11.552-41.67-23.504 14.153-39.228 39.91-39.228 69.346 0 44.679 36.219 80.898 80.898 80.898 29.435 0 55.193-15.724 69.346-39.228-12.171 7.329-26.426 11.552-41.67 11.552z"
        fill="#e24642"
      />
      <path
        d="M208.1 364.84h-23.95v-21.821c0-6.76-5.481-12.241-12.241-12.241s-12.241 5.481-12.241 12.241v21.821h-21.821c-6.76 0-12.241 5.481-12.241 12.241s5.481 12.241 12.241 12.241h21.821v23.95c0 6.76 5.481 12.241 12.241 12.241s12.241-5.481 12.241-12.241v-23.95h23.95c6.76 0 12.241-5.481 12.241-12.241S214.86 364.84 208.1 364.84z"
        fill="#fff"
      />
    </svg>
  );
}

export default SvgComponent;
