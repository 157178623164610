import DoctorGroup from "./DoctorGroup";
import Settings from "./Settings";
import Healthcare from "./Healthcare";
import InsurancePerson from "./InsurancePerson";
import PeopleGroup from "./PeopleGroup";
import CalendarWriteColor from "./CalendarWriteColor";
import DocumentMedical from "./DocumentMedical";
import DropDown from "./DropDown";
import RadioButton from "./RadioButton";
import CheckBox from "./CheckBox";
import MedicalCertificate from "./MedicalCertificate";
import Prescription from "./Prescription";
import PrescriptionControlled from "./PrescriptionControlled";
import MedicalReport from "./MedicalReport";
import Exam from "./Exam";
import Location from "./Location";
import DrugsHeart from "./DrugsHeart";
import Certificate from "./Certificate";
import TextBox from "./TextBox";
import AddAppoType from "./AddAppoType";
import CollectUpload from "./CollectUpload";
import DocumentEdit from "./DocumentEdit";
import Calendar from "./Calendar";
import ChatMessages from "./ChatMessages";
import MedicalComputer from "./MedicalComputer";
import PrescriptionAntimicrobial from "./PrescriptionAntimicrobial";
import TwoDoctors from "./TwoDoctors";
import Patient from "./Patient";
import BankColor from "./BankColor";
import Budget from "./Budget";
import Voucher from "./Voucher";
import VideoCall from "./VideoCall";
import CalendarBlock from "./CalendarBlock";
import QrCodeExample from "./QrCodeExample";
import ImageExample from "./ImageExample";
import TemplateEditor from "./TemplateEditor";
import BuildingClinicColor from "./BuildingClinicColor";
import SocialCareColor from "./SocialCareColor";
import DatabaseColor from "./DatabaseColor";
import Procedures from "./Procedures";
import Invite from "./Invite";
import DocumentSize from "./DocumentSize";
import OtherDocuments from "./OtherDocuments";
import File from "./File";
import FileError from "./FileError";
import GenericError from "./GenericError";
import NoBlocks from "./NoBlocks";
import DoctorsEmptyCalendar from "./DoctorsEmptyCalendar";
import StethoscopeClipboard from "./StethoscopeClipboard";
import CreditCardCVV from "./CreditCardCVV";
import CCGeneric from "./CCGeneric";
import AppointmentAlert from "./AppointmentAlert";
import AppointmentSuccess from "./AppointmentSuccess";
import StylizedDoctorGroup from "./StylizedDoctorGroup";
import ProfessionalProfile from "./ProfessionalProfile";
import MyLocation from "./MyLocation";
import DoctorSchedule from "./DoctorSchedule";
import OnlineDoctor from "./OnlineDoctor";
import SelfScheduling from "./SelfScheduling";
import UdokDoctorGroup from "./UdokDoctorGroup";
import UdokOneDoctor from "./UdokOneDoctor";
import Subscription from "./Subscription";
import Franchise from "./Franchise";
import GenericTimeout from "./GenericTimeout";
import Algorithm from "./Algorithm";
import VideoAppointment from "./VideoAppointment";
import ExamProcedure from "./ExamProcedure";
import SpecialtiesDoctors from "./SpecialtiesDoctors";
import Healthplan from "./Healthplan";
import Blog from "./Blog";
import Invoice from "./Invoice";
import Verified from "./Verified";
import Feedback from "./Feedback";
import Rating from "./Rating";

export default {
  DoctorGroup,
  Settings,
  Healthcare,
  InsurancePerson,
  PeopleGroup,
  DocumentMedical,
  MedicalCertificate,
  Prescription,
  PrescriptionControlled,
  MedicalReport,
  Exam,
  Location,
  DrugsHeart,
  CalendarWriteColor,
  Certificate,
  CheckBox,
  RadioButton,
  DropDown,
  TextBox,
  AddAppoType,
  CollectUpload,
  DocumentEdit,
  Calendar,
  ChatMessages,
  MedicalComputer,
  PrescriptionAntimicrobial,
  TwoDoctors,
  Patient,
  BankColor,
  Budget,
  Voucher,
  VideoCall,
  CalendarBlock,
  QrCodeExample,
  ImageExample,
  TemplateEditor,
  BuildingClinicColor,
  SocialCareColor,
  DatabaseColor,
  Procedures,
  Invite,
  DocumentSize,
  OtherDocuments,
  File,
  FileError,
  GenericError,
  NoBlocks,
  DoctorsEmptyCalendar,
  StethoscopeClipboard,
  CreditCardCVV,
  CCGeneric,
  AppointmentAlert,
  AppointmentSuccess,
  StylizedDoctorGroup,
  ProfessionalProfile,
  MyLocation,
  DoctorSchedule,
  OnlineDoctor,
  SelfScheduling,
  UdokDoctorGroup,
  Subscription,
  UdokOneDoctor,
  Franchise,
  GenericTimeout,
  Algorithm,
  VideoAppointment,
  ExamProcedure,
  SpecialtiesDoctors,
  Healthplan,
  Blog,
  Invoice,
  Verified,
  Feedback,
  Rating,
};
