import * as React from "react";
import { SVGProps } from "react";

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    height={512}
    width={512}
    viewBox="0 0 512 512"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M408 488H104a32 32 0 0 1-32-32V56a32 32 0 0 1 32-32h240l96 96v336a32 32 0 0 1-32 32z"
      fill="#e9eef2"
    />
    <g fill="#c3c6c7">
      <circle cx={192} cy={256} r={16} />
      <circle cx={256} cy={256} r={16} />
      <circle cx={320} cy={256} r={16} />
      <path d="M440 120h-64a32 32 0 0 1-32-32V24z" />
    </g>
    <path d="M344 16H104a40.045 40.045 0 0 0-40 40v400a40.045 40.045 0 0 0 40 40h304a40.045 40.045 0 0 0 40-40V120a8 8 0 0 0-2.343-5.657l-96-96A8 8 0 0 0 344 16zm88 440a24.028 24.028 0 0 1-24 24H104a24.028 24.028 0 0 1-24-24V56a24.028 24.028 0 0 1 24-24h232v56a40.045 40.045 0 0 0 40 40h56zM352 43.313 420.687 112H376a24.028 24.028 0 0 1-24-24z" />
    <path d="M192 232a24 24 0 1 0 24 24 24.028 24.028 0 0 0-24-24zm0 32a8 8 0 1 1 8-8 8.009 8.009 0 0 1-8 8zM256 232a24 24 0 1 0 24 24 24.028 24.028 0 0 0-24-24zm0 32a8 8 0 1 1 8-8 8.009 8.009 0 0 1-8 8zM320 232a24 24 0 1 0 24 24 24.028 24.028 0 0 0-24-24zm0 32a8 8 0 1 1 8-8 8.009 8.009 0 0 1-8 8z" />
  </svg>
);

export default SvgComponent;
