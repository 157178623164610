import * as React from "react";
import clsx from "clsx";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import {
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  ListItemSecondaryAction,
  IconButton,
  Collapse,
} from "@material-ui/core";
import Drawer, { DrawerProps } from "@material-ui/core/Drawer";
import { Omit } from "@material-ui/types";
import { NavLink } from "react-router-dom";
import Icons from "components/Icon";
import { MenuView } from "ducks/menu";

const useStyles = makeStyles<Theme, NavigatorProps>((theme: Theme) =>
  createStyles({
    categoryHeader: {},
    item: {
      color: theme.palette.neutral.main,
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      textDecoration: "none",
    },
    itemCategory: {
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...(theme.mixins.toolbar as any),
    },
    itemActiveItem: {
      color: `${theme.palette.primary.main} !important`,
    },
    itemPrimary: {
      fontSize: "inherit",
    },
    itemIcon: {
      color: "inherit",
      minWidth: 42,
    },
    itemAction: {
      color: theme.palette.neutral.main,
    },
    drawer: {
      flexShrink: 0,
      whiteSpace: "nowrap",
    },
    drawerOpen: {
      width: (props) => props.drawerWidth,
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    drawerClose: {
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      overflowX: "hidden",
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(7) + 1,
      },
    },
    toolbar: {
      height: 80,
      minHeight: 80,
    },
  })
);

export interface CategoryItem {
  title: string;
  to: string;
  icon: string;
}

export interface NavigatorProps extends Omit<DrawerProps, "classes"> {
  headerTitle?: string | React.ReactNode | React.ReactNodeArray;
  homeLink?: string;
  categories?: MenuView[];
  navigationPrefix?: string;
  open?: boolean;
  drawerWidth?: number;
  onClose?: () => void;
  onOpen?: () => void;
}

function getIcon(n: string, color?: string): any {
  const MappedIcons = {
    ...(Icons as any),
  };
  const Icon = MappedIcons[n] || Icons.Settings;
  return <Icon style={{ color }} />;
}

function Navigator(props: NavigatorProps) {
  const {
    headerTitle,
    categories = [],
    navigationPrefix = "",
    open,
    onOpen,
    homeLink: _,
    drawerWidth: __,
    ...other
  } = props;
  const classes = useStyles(props);
  const [expanded, setExpanded] = React.useState(-1);

  const handleExpandRetract = (idx: number) => {
    setExpanded(expanded === idx ? -1 : idx);
  };

  return (
    <Drawer
      variant="permanent"
      className={clsx(classes.drawer, {
        [classes.drawerOpen]: open,
        [classes.drawerClose]: !open,
      })}
      classes={{
        paper: clsx({
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        }),
      }}
      {...other}
      open={other.variant === "temporary" ? open : undefined}
    >
      <List>
        <ListItem
          className={clsx(classes.item, classes.itemCategory, classes.toolbar)}
        >
          {headerTitle}
        </ListItem>
        {categories.map(({ title, children, to, icon: parentIcon }, idx) => (
          <React.Fragment key={(title ?? "") + idx}>
            <ListItem
              button
              className={clsx(classes.categoryHeader, classes.item)}
              {...(to
                ? {
                    component: NavLink,
                    to,
                    activeClassName: classes.itemActiveItem,
                  }
                : {})}
            >
              <ListItemIcon className={classes.itemIcon}>
                {getIcon((parentIcon as string) ?? "DashboardOutlined")}
              </ListItemIcon>
              <ListItemText>{title}</ListItemText>

              {open && children.length > 0 ? (
                <ListItemSecondaryAction className={classes.itemAction}>
                  <ActiveLinkNoAction
                    url={navigationPrefix + to}
                    activeClassName={classes.itemActiveItem}
                    className={classes.itemIcon}
                  >
                    <IconButton
                      color="inherit"
                      edge="end"
                      aria-label="expand or retract submenu"
                      onClick={() => handleExpandRetract(idx)}
                    >
                      {expanded === idx ? (
                        <Icons.ExpandMore />
                      ) : (
                        <Icons.ChevronRight />
                      )}
                    </IconButton>
                  </ActiveLinkNoAction>
                </ListItemSecondaryAction>
              ) : null}
            </ListItem>
            <Collapse in={expanded === idx} timeout="auto">
              {children.map(({ title, to, icon }, idx) => (
                <ListItem
                  key={title + idx}
                  button
                  className={clsx(classes.item)}
                  component={NavLink}
                  to={navigationPrefix + to}
                  activeClassName={classes.itemActiveItem}
                >
                  <ListItemIcon className={classes.itemIcon}></ListItemIcon>
                  <ListItemText>{title}</ListItemText>
                </ListItem>
              ))}
            </Collapse>
          </React.Fragment>
        ))}
      </List>
    </Drawer>
  );
}

export default Navigator;

const ActiveLinkNoAction = (props: {
  url: string;
  activeClassName: string;
  className?: string;
  children?: React.ReactNodeArray | React.ReactNode;
}) => {
  const { url, activeClassName, className, children } = props;
  return (
    <NavLink
      to={url}
      className={className}
      activeClassName={activeClassName}
      component={({ href, ...other }: any) => <div {...other} />}
    >
      {children}
    </NavLink>
  );
};
