import * as React from "react";

function SvgComponent(
  props: React.SVGProps<SVGSVGElement> & {
    text?: string;
    backgroundColor?: string;
    textColor?: string;
    borderColor?: string;
  }
) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={75}
      height={75}
      viewBox="0 0 75 75"
      fill="#bcbcbc"
      {...props}
    >
      <defs>
        <filter
          id="prefix__a"
          x={0}
          y={0}
          width={75}
          height={75}
          filterUnits="userSpaceOnUse"
        >
          <feOffset dy={3} />
          <feGaussianBlur stdDeviation={3} result="blur" />
          <feFlood floodOpacity={0.161} />
          <feComposite operator="in" in2="blur" />
          <feComposite in="SourceGraphic" />
        </filter>
      </defs>
      <g data-name="Grupo 5">
        <g filter="url(#prefix__a)">
          <g
            data-name="Elipse 1"
            transform="translate(9 6)"
            fill={props?.backgroundColor ?? "#fff"}
            stroke={props?.borderColor}
            strokeWidth={2}
          >
            <circle cx={28.5} cy={28.5} r={28.5} stroke="none" />
            <circle cx={28.5} cy={28.5} r={27.5} fill="none" />
          </g>
        </g>
        {!props?.text ? (
          <path
            data-name={1442912}
            d="M48.822 25.906a2.049 2.049 0 010 2.9L35.075 42.551a2.05 2.05 0 01-2.9 0l-6.544-6.544a2.05 2.05 0 112.9-2.9l5.1 5.1 12.3-12.3a2.05 2.05 0 012.9 0z"
            fill={props?.textColor}
          />
        ) : (
          <text
            data-name={2}
            transform="translate(32 43)"
            fill={props?.textColor}
            fontSize={20}
            fontFamily="SegoeUI, Segoe UI"
          >
            <tspan x={0} y={0}>
              {props?.text ?? "1"}
            </tspan>
          </text>
        )}
      </g>
    </svg>
  );
}

export default SvgComponent;
