import * as React from "react";
import { SVGProps } from "react";

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    style={{
      isolation: "isolate",
    }}
    viewBox="0 0 550 450"
    width="550pt"
    height={600}
    {...props}
  >
    <defs>
      <clipPath id="a">
        <path d="M0 0h550v450H0z" />
      </clipPath>
    </defs>
    <g clipPath="url(#a)">
      <path
        d="M300.887 89.413c14.339-4.172 21.748-18.485 34.91-24.698 52.785-22.738 80.13 43.376 122.682 55.336 19.132 5.066 36.658 1.737 55.998 9.975 23.637 10.066 35.85 36.85 35.516 63.427-1.51 24.542-21.122 40.411-32.264 61.356-13.222 21.092-8.434 30.325-1.767 52.174 11.732 35.315 5.672 73.256-34.517 81.317-45.31 9.475-69.377-22.87-107.984-38.285-49.28-18.935-105.404-15.091-152.997 7.294-66.917 30.077-194.387 54.133-217.807-47.305-8.753-37.643 4.116-64.018 38.683-78.075 13.233-5.156 26.936-12.924 34.406-26.182 10.313-17.703-1.899-44.487-4.197-63.453-14.703-127.53 173.143-34.163 232.444-53.098"
        fill="#607D8B"
        opacity={0.2}
      />
      <path
        d="M203.66 169.304v71.992h142.664v-71.992c0-39.328-32-71.328-71.336-71.328s-71.328 32-71.328 71.328Zm12 0c0-32.768 26.56-59.336 59.328-59.336s59.328 26.568 59.328 59.336v59.992H215.66v-59.992Z"
        fill="#F3F3F3"
      />
      <path
        d="M215.66 169.304v59.992h118.664v-59.992c0-32.768-26.56-59.336-59.328-59.336s-59.336 26.568-59.336 59.336Zm12 0c0-26.096 21.232-47.328 47.336-47.328s47.336 21.232 47.336 47.328v47.992H227.66v-47.992Z"
        fill="#C8C6C6"
      />
      <path
        d="M195.38 352.024h159.24c8.832 0 16-7.16 16-16v-115.64c0-8.832-7.168-16-16-16H195.38c-8.84 0-16 7.168-16 16v115.64c0 8.84 7.16 16 16 16Z"
        fill="#FFCC5B"
      />
      <path
        d="M302.852 288.44h6.056c5.136 0 9.296-4.184 9.264-9.32v-.776c.032-5.408-4.368-9.832-9.768-9.832h-7.832c-5.984 0-11.352-4.48-11.664-10.456-.336-6.376 4.736-11.648 11.04-11.648h28.256c5.344 0 9.328-4.488 9.264-9.832v-.272c.064-5.344-3.92-9.824-9.264-9.824h-9.136c-5.984 0-11.352-4.48-11.664-10.456-.336-6.376 4.736-11.648 11.04-11.648h36.176c8.832 0 16 7.16 16 16v115.64c0 8.84-7.168 16-16 16h-46.856c-6.104 0-11.056-4.952-11.056-11.056 0-6.104 4.952-11.056 11.056-11.056h14.784c5.112 0 9.272-4.184 9.248-9.296v-.12c0-.224.008-.448.024-.672.304-5.04-3.696-9.288-8.744-9.288h-19.6c-5.984 0-11.352-4.48-11.664-10.456-.328-6.36 4.744-11.632 11.04-11.632Z"
        fill="#FDBC4B"
      />
      <path
        d="M250.956 264.576c0-13.28 10.768-24.04 24.04-24.04 13.28 0 24.04 10.768 24.04 24.04 0 8.864-4.856 16.528-12 20.696l3.296 30.6h-30.68l3.296-30.6c-7.144-4.168-11.992-11.824-11.992-20.696Z"
        fill="#464646"
      />
      <g fill="#FFD769">
        <path d="M203.66 263.776c4.416 0 8 3.576 8 8v46.032c0 4.424-3.584 8-8 8s-8-3.576-8-8v-46.032c0-4.416 3.584-8 8-8ZM203.66 230.6c4.416 0 8 3.576 8 8v9.368c0 4.424-3.584 8-8 8s-8-3.576-8-8V238.6c0-4.424 3.584-8 8-8Z" />
      </g>
      <circle
        vectorEffect="non-scaling-stroke"
        cx={344.778}
        cy={332.778}
        r={35.778}
        fill="#E24C4B"
      />
      <path
        d="M380.556 332.778c0 19.678-16.1 35.778-35.778 35.778-11.404 0-21.467-5.143-27.952-13.417 6.038 4.92 13.864 7.827 22.361 7.827 19.678 0 35.778-16.1 35.778-35.778 0-8.497-2.907-16.324-7.826-22.361 8.274 6.484 13.417 16.547 13.417 27.951Z"
        fill="#D1403F"
      />
      <path
        d="M361.102 349.102c-1.342 1.341-3.578 1.341-4.92 0l-11.404-11.405-11.404 11.405c-1.342 1.341-3.578 1.341-4.92 0-1.341-1.342-1.341-3.578 0-4.92l11.405-11.404-11.405-11.404c-1.341-1.342-1.341-3.578 0-4.92 1.342-1.341 3.578-1.341 4.92 0l11.404 11.405 11.404-11.405c1.342-1.341 3.578-1.341 4.92 0 1.341 1.342 1.341 3.578 0 4.92l-11.405 11.404 11.405 11.404c1.341 1.342 1.341 3.578 0 4.92Z"
        fill="#FFF"
      />
    </g>
  </svg>
);

export default SvgComponent;
