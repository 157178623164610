import React from "react";
import Loadable from "react-loadable";
import Loader from "@udok/lib/components/PageLoader";
import { Route, Switch } from "react-router";
import OTPContainer from "containers/Auth/OTPContainer";
import Paperbase from "components/UI/Paperbase";
import Menu from "containers/Auth/Menu";
import UserLoader from "containers/Auth/UserLoader";
import { Link } from "react-router-dom";
import UdokLogoLarge from "@udok/lib/components/Illustrations/UdokLogoLarge";
import ChatRegister from "containers/Chat/Register";
import { useConnectRealtime } from "hooks/realtime";
import AlertConversations from "containers/Chat/Alert/AlertConversations";
import { ExtendedMenu } from "containers/Auth/ProfileMenu";
import EventsAlert from "containers/EventsAlert";
import FloatingChat from "containers/Chat/Alert/FloatingChat";

export const Home = Loadable({
  loader: () => import("screens/admin/home"),
  loading: Loader,
});

export const Appointments = Loadable({
  loader: () => import("screens/admin/appointments"),
  loading: Loader,
});

export const Prescription = Loadable({
  loader: () => import("screens/admin/prescription"),
  loading: Loader,
});

export const Conversations = Loadable({
  loader: () => import("screens/admin/conversations"),
  loading: Loader,
});

export const Profile = Loadable({
  loader: () => import("screens/admin/profile"),
  loading: Loader,
});

export const DoctorReview = Loadable({
  loader: () => import("screens/admin/doctorReview"),
  loading: Loader,
});

const ConnectRealtime = () => {
  useConnectRealtime();
  return null;
};

export default () => {
  return (
    <OTPContainer>
      <UserLoader />
      <ChatRegister />
      <ConnectRealtime />
      <Paperbase
        navigator={Menu}
        headerItems={
          <Link to={`/`}>
            <UdokLogoLarge height={45} style={{ margin: 5 }} />
          </Link>
        }
        toolbarItems={
          <div
            style={{
              display: "flex",
              flex: 1,
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            <AlertConversations id="header-chat-alert" />
            <EventsAlert />
            <ExtendedMenu />
          </div>
        }
      >
        <Switch>
          <Route exact path="/" component={Home} />
          <Route path="/appointments" strict={false} component={Appointments} />
          <Route path="/documents" component={Prescription} />
          <Route path="/conversations/:userID?" component={Conversations} />
          <Route path="/profile" component={Profile} />
          <Route path="/doctor-review/:doctID" component={DoctorReview} />
        </Switch>
        <FloatingChat />
      </Paperbase>
    </OTPContainer>
  );
};
