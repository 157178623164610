import { toQueryString } from "@udok/lib/internal/util";
import {
  DoctorReview,
  DoctorReviewView,
  DoctorReviewForm,
  FilterDoctorReview,
} from "@udok/lib/api/models";
import {
  makeItemFetch,
  makeItemsFetch,
  makeItemPost,
  makeItemPut,
} from "@udok/lib/api/axios";

export const createDoctorReview = async (
  token: string,
  data: DoctorReviewForm
) =>
  makeItemPost<DoctorReview, DoctorReviewForm>({
    url: `/api/doctor-reviews`,
    errLabel: "dr1",
    token,
    data,
  });

export const fetchDoctorReviews = (token: string, f?: FilterDoctorReview) => {
  const filter = f ? toQueryString(f) : "";
  return makeItemsFetch<DoctorReviewView[]>({
    url: `/api/patient-reviews${filter}`,
    errLabel: "dr2",
    token,
  });
};

export const fetchDoctorReview = (token: string, patiID: string) =>
  makeItemFetch<DoctorReviewView>({
    url: `/api/patient-reviews/${patiID}`,
    errLabel: "dr3",
    token,
  });

export const fetchPublicDoctorReviews = (
  doctID: string,
  f?: FilterDoctorReview
) => {
  const filter = f ? toQueryString(f) : "";
  return makeItemsFetch<DoctorReviewView[]>({
    url: `/api/doctor-reviews-public/${doctID}${filter}`,
    errLabel: "dr4",
  });
};

export const updateDoctorReviews = async (
  token: string,
  doreID: number,
  data: {
    rejected: boolean;
    publishedAt: string;
  }
) =>
  makeItemPut<DoctorReview, { rejected: boolean; publishedAt: string }>({
    url: `/api/doctor-reviews/${doreID}`,
    errLabel: "dr5",
    token,
    data,
  });
