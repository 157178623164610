import * as Icons from "@material-ui/icons";
import SpeechBubble from "./SpeechBubble";
import CommentFlash from "./CommentFlash";
import CommentFlashOutline from "./CommentFlashOutline";
import CreditCardGeneric from "./CreditCardGeneric";
import Eraser from "./Eraser";
import User from "./User";
import Template from "./Template";
import Prescription from "./Prescription";
import Plus from "./Plus";
import Menu from "./Menu";
import Eye from "./Eye";
import Examination from "./Examination";
import Dashboard from "./Dashboard";
import Config from "./Config";
import Chat from "./Chat";
import Calendar from "./Calendar";
import ArrowRight from "./ArrowRight";
import ArrowDown from "./ArrowDown";
import StepIcon from "./StepIcon";
import PinHouse from "./PinHouse";
import VideoCall from "./VideoCall";
import ChatCircular from "./ChatCircular";
import AnnouncementCircular from "./AnnouncementCircular";
import PinMap from "./PinMap";
import FillForm from "./FillForm";
import PIX from "./PIX";
import Barcode from "./Barcode";
import PDFOutlined from "./PDFOutlined";

export default {
  ...Icons,
  SpeechBubble,
  CommentFlash,
  CommentFlashOutline,
  CreditCardGeneric,
  Eraser,
  User,
  Template,
  Prescription,
  Plus,
  Menu,
  Eye,
  Examination,
  Dashboard,
  Config,
  Chat,
  Calendar,
  ArrowRight,
  ArrowDown,
  StepIcon,
  PinHouse,
  VideoCall,
  ChatCircular,
  AnnouncementCircular,
  PinMap,
  FillForm,
  PIX,
  Barcode,
  PDFOutlined,
};
