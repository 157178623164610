import { RootState, AppThunk } from "ducks/state";
import { createSelector } from "reselect";
import { newNotification } from "./notification";
import { hen, Hen } from "@udok/lib/internal/store";
import { Specialty, SpecialtyFilter } from "@udok/lib/api/models";
import { fetchSpecialties, fetchSpecialty } from "@udok/lib/api/specialty";

export type InitialState = {
  SpecialtyByID: { [specID: string]: Specialty };
};

//Reducers
const initialState: InitialState = {
  SpecialtyByID: {},
};

class SpecialtySlice extends Hen<InitialState> {
  loadSpecialty(spec: Specialty) {
    this.state.SpecialtyByID[spec.specID] = spec;
  }
  loadSpecialties(s: Specialty[]) {
    s.forEach((spec) => {
      this.state.SpecialtyByID[spec.specID] = spec;
    });
  }
  loadRemoveSpecialty(spec: Specialty) {
    delete this.state.SpecialtyByID[spec.specID];
  }
}

export const [Reducer, actions] = hen(new SpecialtySlice(initialState));

// Selectors
const mainSelector = (state: RootState) => state.specialty;

export const specialtyListView = createSelector([mainSelector], (state) => {
  return {
    list: Object.keys(state.SpecialtyByID).map(
      (specID) => state.SpecialtyByID[specID]!
    ),
  };
});

//Actions
export function fetchOneSpecialty(specID: string): AppThunk<Promise<void>> {
  return async (dispatch) => {
    return fetchSpecialty(specID)
      .then((r) => {
        dispatch(actions.loadSpecialty(r));
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: (e?.response?.data?.error || e).message,
          })
        );
        throw e;
      });
  };
}

export function fetchAllSpecialties(
  f?: SpecialtyFilter
): AppThunk<Promise<void>> {
  return async (dispatch) => {
    return fetchSpecialties(f)
      .then((r) => {
        dispatch(actions.loadSpecialties(r));
      })
      .catch((e: any) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: (e?.response?.data?.error || e).message,
          })
        );
        throw e;
      });
  };
}
