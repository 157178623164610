import React from "react";

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg height={512} viewBox="0 0 512 512" width={512} {...props}>
      <path d="M225.89 418.064h60.22v78.483h-60.22z" fill="#c7c2c9" />
      <path
        d="M481.095 433.517H30.905C13.837 433.517 0 419.68 0 402.611V140.294c0-17.069 13.837-30.905 30.905-30.905h450.189c17.069 0 30.905 13.837 30.905 30.905v262.318c.001 17.068-13.836 30.905-30.904 30.905z"
        fill="#655e67"
      />
      <path
        d="M481.095 109.388H450.19c17.069 0 30.905 13.837 30.905 30.905v262.318c0 17.068-13.837 30.905-30.905 30.905h30.905c17.069 0 30.905-13.837 30.905-30.905V140.294c0-17.069-13.837-30.906-30.905-30.906z"
        fill="#544f56"
      />
      <path
        d="M30.905 399.521V143.384a3.091 3.091 0 013.091-3.091h444.008a3.091 3.091 0 013.091 3.091v256.137a3.091 3.091 0 01-3.091 3.091H33.996a3.091 3.091 0 01-3.091-3.091z"
        fill="#ecf5ff"
      />
      <path
        d="M478.004 140.294h-30.905a3.091 3.091 0 013.091 3.091v256.137a3.091 3.091 0 01-3.091 3.091h30.905a3.091 3.091 0 003.091-3.091V143.384a3.09 3.09 0 00-3.091-3.09z"
        fill="#dceafc"
      />
      <path
        d="M303.597 481.095h-95.194c-15.362 0-27.815 12.453-27.815 27.815a3.091 3.091 0 003.091 3.091h144.643a3.091 3.091 0 003.091-3.091c-.001-15.362-12.454-27.815-27.816-27.815z"
        fill="#655e67"
      />
      <path
        d="M303.597 481.095h-30.905c15.362 0 27.815 12.453 27.815 27.815a3.091 3.091 0 01-3.091 3.091h30.905a3.091 3.091 0 003.091-3.091c0-15.362-12.453-27.815-27.815-27.815z"
        fill="#544f56"
      />
      <path
        d="M415.599 402.611v-83.565c0-22.106-14.126-41.744-35.099-48.793l-74.579-25.069a17.162 17.162 0 01-11.7-16.264v-27.944h-76.442v27.944a17.16 17.16 0 01-11.7 16.264L131.5 270.253c-20.973 7.05-35.099 26.688-35.099 48.793v83.565z"
        fill="#b3e59f"
      />
      <path
        d="M380.5 270.253l-74.579-25.069a17.161 17.161 0 01-11.7-16.264v-27.944h-76.442v15.453h45.536v27.944a17.161 17.161 0 0011.7 16.264l74.579 25.069c20.973 7.05 35.099 26.688 35.099 48.793v68.112h30.905v-83.565c.001-22.105-14.126-41.743-35.098-48.793z"
        fill="#95d6a4"
      />
      <path
        d="M205.501 245.379l46.989 46.941a6.181 6.181 0 008.737 0l46.557-46.509-1.863-.627a17.161 17.161 0 01-11.7-16.264v-27.944h-76.442v27.944a17.161 17.161 0 01-11.7 16.264z"
        fill="#f1ccbd"
      />
      <path
        d="M263.315 244.373a17.161 17.161 0 0011.7 16.264l13.414 4.509 19.355-19.335-1.863-.627a17.161 17.161 0 01-11.7-16.264v-27.944h-30.905v43.397z"
        fill="#eaad9c"
      />
      <path
        d="M345.764 105.174H166.236V38.973C166.236 17.449 183.684 0 205.209 0h101.583c21.524 0 38.973 17.449 38.973 38.973v66.201z"
        fill="#c17d4f"
      />
      <path
        d="M306.791 0h-30.905c21.524 0 38.973 17.449 38.973 38.973v66.201h30.905V38.973C345.764 17.449 328.316 0 306.791 0z"
        fill="#b16e3d"
      />
      <path
        d="M255.683 215.532c-49.611-.171-89.447-41.085-89.447-90.646V93.488c17.898.053 44.788-2.209 74.522-14.173a195.13 195.13 0 0034.966-18.391c2.043-1.354 4.776-.876 6.279 1.059 4.752 6.12 12.732 14.594 24.834 21.303 16.29 9.031 31.532 10.145 38.928 10.202v32.372c0 35.869-21.081 66.82-51.542 81.161-5.187 2.442-17.818-.175-17.818-.175s-8.248 8.016-13.317 8.412c-2.443.19-4.912.283-7.405.274z"
        fill="#f1ccbd"
      />
      <path
        d="M314.859 87.156v23.252c0 49.63-40.36 89.844-90.081 89.672a88.75 88.75 0 01-23.185-3.153c14.995 11.59 33.717 18.536 54.091 18.606 49.721.172 90.081-40.042 90.081-89.672V93.489a82.288 82.288 0 01-30.906-6.333z"
        fill="#eaad9c"
      />
      <path
        d="M348.544 331.303c3.794 1.75 8.445.139 10.253-3.778 5.353-11.599 8.305-24.462 8.536-37.199.156-8.576-.914-17.217-3.129-25.55l-18.398-6.184c4.172 9.912 6.272 20.744 6.076 31.453-.192 10.615-2.653 21.336-7.116 31.006a7.726 7.726 0 003.778 10.252z"
        fill="#dfdce0"
      />
      <path
        d="M345.806 258.592a78.016 78.016 0 016.047 27.938 51.416 51.416 0 0115.214 9.2 90.53 90.53 0 00.266-5.403c.156-8.576-.914-17.217-3.129-25.55z"
        fill="#c7c2c9"
      />
      <path
        d="M155.067 313.432a7.73 7.73 0 007.283 5.148c5.169 0 9.032-5.372 7.283-10.309a70.196 70.196 0 01-3.974-20.116c-.501-10.551 1.477-21.292 5.726-31.308l-18.392 6.182c-2.226 8.484-3.179 17.216-2.769 25.86a85.613 85.613 0 004.843 24.543z"
        fill="#dfdce0"
      />
      <g>
        <path
          d="M188.694 380.987h-7.219a7.726 7.726 0 110-15.452h4.812v-23.02c0-13.199-10.737-23.937-23.937-23.937-13.199 0-23.937 10.737-23.937 23.937v23.02h4.812a7.726 7.726 0 110 15.452h-7.219c-7.193 0-13.045-5.852-13.045-13.045v-25.428c0-21.719 17.67-39.389 39.389-39.389s39.389 17.67 39.389 39.389v25.428c0 7.193-5.852 13.045-13.045 13.045z"
          fill="#f1eff1"
        />
      </g>
      <ellipse
        cx={353.577}
        cy={345.553}
        fill="#f1eff1"
        rx={27.736}
        ry={27.708}
      />
    </svg>
  );
}

export default SvgComponent;
