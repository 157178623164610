import React from "react";

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      id="Layer_1"
      enableBackground="new 0 0 512 512"
      height="512"
      viewBox="0 0 512 512"
      width="512"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g>
        <path
          d="m400 152h111v-18.32l-41.901-90.011c-5.381-11.559-21.816-11.559-27.198 0l-41.901 90.011z"
          fill="#fdbf00"
        />
        <g>
          <path
            d="m486 512h-61c-8.284 0-15-6.716-15-15v-61c0-8.284 6.716-15 15-15h61c8.284 0 15 6.716 15 15v61c0 8.284-6.716 15-15 15z"
            fill="#00429d"
          />
        </g>
        <path
          d="m486 421h-30.5v91h30.5c8.284 0 15-6.716 15-15v-61c0-8.284-6.716-15-15-15z"
          fill="#00347b"
        />
        <g>
          <path
            clipRule="evenodd"
            d="m16 45h334v451.999h-334z"
            fill="#fff4f4"
            fillRule="evenodd"
          />
        </g>
        <path
          clipRule="evenodd"
          d="m183 45h167v451.999h-167z"
          fill="#f6efea"
          fillRule="evenodd"
        />
        <g>
          <path
            d="m350 511.999h-334c-8.284 0-15-6.716-15-15v-451.999c0-8.284 6.716-15 15-15h334c8.284 0 15 6.716 15 15v451.999c0 8.284-6.716 15-15 15zm-319-30h304v-421.999h-304z"
            fill="#fdbf00"
          />
        </g>
        <path
          d="m350 30h-167v30h152v421.999h-152v30h167c8.284 0 15-6.716 15-15v-451.999c0-8.284-6.716-15-15-15z"
          fill="#ff8856"
        />
        <g>
          <path
            clipRule="evenodd"
            d="m76.001 310c8.262 0 15-6.738 15-15 0-8.261-6.738-15-15-15-8.261 0-15 6.738-15 15s6.738 15 15 15z"
            fill="#bee75e"
            fillRule="evenodd"
          />
        </g>
        <g>
          <path
            d="m289.998 310h-153.998c-8.284 0-15-6.716-15-15s6.716-15 15-15h153.997c8.284 0 15 6.716 15 15s-6.715 15-14.999 15z"
            fill="#7dd5f4"
          />
        </g>
        <g>
          <path
            clipRule="evenodd"
            d="m76.001 370c8.262 0 15-6.739 15-15 0-8.262-6.738-15-15-15-8.261 0-15 6.738-15 15s6.738 15 15 15z"
            fill="#bee75e"
            fillRule="evenodd"
          />
        </g>
        <g>
          <path
            d="m290.001 370h-154.001c-8.284 0-15-6.716-15-15s6.716-15 15-15h154.001c8.284 0 15 6.716 15 15s-6.715 15-15 15z"
            fill="#7dd5f4"
          />
        </g>
        <g>
          <path
            clipRule="evenodd"
            d="m76.001 430c8.262 0 15-6.738 15-15s-6.738-15-15-15c-8.261 0-15 6.738-15 15s6.738 15 15 15z"
            fill="#bee75e"
            fillRule="evenodd"
          />
        </g>
        <g>
          <path
            d="m290.272 430h-154.272c-8.284 0-15-6.716-15-15s6.716-15 15-15h154.271c8.284 0 15 6.716 15 15s-6.715 15-14.999 15z"
            fill="#7dd5f4"
          />
        </g>
        <g>
          <path
            d="m258 90h-150c-8.284 0-15-6.716-15-15v-60c0-8.284 6.716-15 15-15h150c8.284 0 15 6.716 15 15v60c0 8.284-6.716 15-15 15z"
            fill="#7dd5f4"
          />
        </g>
        <path
          d="m219.999 156.999h-21.999v-21.999c0-8.284-6.716-15-15-15s-15 6.716-15 15v21.999h-21.999c-8.284 0-15 6.716-15 15s6.716 15 15 15h21.999v22c0 8.284 6.716 15 15 15s15-6.716 15-15v-22h21.999c8.284 0 15-6.716 15-15s-6.716-15-15-15z"
          fill="#ff8856"
        />
        <path d="m400 133h111v233.319h-111z" fill="#7dd5f4" />
        <path
          d="m415 451h81c8.284 0 15-6.716 15-15v-75.001h-111v75.001c0 8.284 6.716 15 15 15z"
          fill="#fdbf00"
        />
        <g fill="#4793ff">
          <path d="m258 0h-75v90h75c8.284 0 15-6.716 15-15v-60c0-8.284-6.716-15-15-15z" />
          <path d="m289.998 280h-106.998v30h106.997c8.284 0 15-6.716 15-15s-6.715-15-14.999-15z" />
          <path d="m290.001 340h-107.001v30h107.001c8.284 0 15-6.716 15-15s-6.715-15-15-15z" />
          <path d="m290.272 400h-107.272v30h107.272c8.284 0 15-6.716 15-15s-6.716-15-15-15z" />
        </g>
        <path
          d="m219.999 156.999h-21.999v-21.999c0-8.284-6.716-15-15-15v103.999c8.284 0 15-6.716 15-15v-22h21.999c8.284 0 15-6.716 15-15s-6.716-15-15-15z"
          fill="#ff435b"
        />
        <path
          d="m469.099 43.669c-2.691-5.779-8.145-8.669-13.599-8.669v117h55.5v-18.32z"
          fill="#ff8856"
        />
        <path d="m455.5 133h55.5v233.319h-55.5z" fill="#4793ff" />
        <path
          d="m455.5 360.999v90.001h40.5c8.284 0 15-6.716 15-15v-75.001z"
          fill="#ff8856"
        />
      </g>
    </svg>
  );
}

export default SvgComponent;
