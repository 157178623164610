import { RootState, AppThunk } from "ducks/state";
import { createSelector } from "reselect";
import { newNotification } from "./notification";
import { hen, Hen } from "@udok/lib/internal/store";
import { Invitation } from "@udok/lib/api/models";
import { createRelationship, Relationship } from "@udok/lib/api/invitation";
import { getToken, UNAUTHORIZED } from "./auth";

export type InitialState = {
  invitation?: Invitation;
};

// Reducers
const initialState: InitialState = {
  invitation: undefined,
};

class InvitationSlice extends Hen<InitialState> {
  invitationLoaded(inv: Invitation) {
    this.state.invitation = inv;
  }
}

export const [Reducer, actions] = hen(new InvitationSlice(initialState), {
  [UNAUTHORIZED]: () => {
    return initialState;
  },
});

// Selectors
const mainSelector = (state: RootState) => state.invitation;
export const getInvitationCreate = createSelector(mainSelector, (state) => {
  return {
    invitation: state.invitation,
  };
});

// Actions
export function createRelation(
  relationship: Relationship,
  hideNotification?: boolean
): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;

    return createRelationship(apiToken, relationship)
      .then((r) => {
        dispatch(actions.invitationLoaded(r));
        if (!hideNotification) {
          dispatch(
            newNotification("general", {
              status: "success",
              message: "Você um novo contato agora",
            }) as any
          );
        }
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
      });
  };
}
