import * as React from "react";

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      height={512}
      viewBox="0 0 512.047 512.047"
      width={512}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M55.62 200.21v288.305c0 8.854 7.178 16.032 16.032 16.032h368.742c8.854 0 16.032-7.178 16.032-16.032V200.21z"
        fill="#ffd064"
      />
      <path
        d="M79.669 200.21H55.62v288.305c0 8.854 7.179 16.032 16.032 16.032h8.016V200.21z"
        fill="#ffc269"
      />
      <path
        d="M445.061 173.543L283.781 18.714c-15.51-14.889-40.004-14.889-55.514 0L66.987 173.543a36.969 36.969 0 000 53.335l189.037 181.475 189.037-181.475a36.97 36.97 0 000-53.335z"
        fill="#ffa95f"
      />
      <path
        d="M400.314 55.644H111.733a8.016 8.016 0 00-8.016 8.016v198.48l118.906 106.408h66.801L408.33 228.803V63.66a8.016 8.016 0 00-8.016-8.016z"
        fill="#fff5d7"
      />
      <path
        d="M289.425 376.289L408.33 262.14v-33.337l-88.304 84.772a7.98 7.98 0 01-5.551 2.233 7.98 7.98 0 01-5.552-2.233l-7.401-7.105c-9.589-9.204-20.839-15.299-32.535-17.625a66.582 66.582 0 00-12.964-1.285c-16.636-.001-32.409 6.344-44.411 17.867l-8.489 8.148a7.98 7.98 0 01-5.551 2.233 7.98 7.98 0 01-5.552-2.233l-88.304-84.772v33.337l118.905 114.15h66.804z"
        fill="#f9eac7"
      />
      <path
        d="M191.895 111.757h-48.097l16.032 24.048-16.032 24.048h64.129v-32.065c0-8.853-7.178-16.031-16.032-16.031zM320.153 111.757h48.097l-16.032 24.048 16.032 24.048h-64.13v-32.065c0-8.853 7.178-16.031 16.033-16.031z"
        fill="#ff8086"
      />
      <path
        d="M228.267 322.775L55.62 488.515c0 8.854 7.178 16.032 16.032 16.032h368.742c8.854 0 16.032-7.178 16.032-16.032L283.78 322.775c-15.509-14.89-40.004-14.89-55.513 0z"
        fill="#ffe07d"
      />
      <path
        d="M119.611 55.644l-15.894 35.609v39.334l33.451-74.943z"
        fill="#fff5d7"
      />
      <path
        d="M304.12 191.918h-96.194c-8.854 0-16.032-7.178-16.032-16.032v-32.065h128.258v32.065c.001 8.854-7.177 16.032-16.032 16.032z"
        fill="#e5646e"
      />
      <path
        d="M440.395 504.547c8.854 0 16.032-7.178 16.032-16.032l-8.351-8.016H87.043c-4.066 0-7.374-3.308-7.374-7.375v-7.696L55.62 488.515c0 8.854 7.179 16.032 16.032 16.032z"
        fill="#ffd064"
      />
      <path d="M450.255 168.132l-34.424-33.047V63.66c0-8.556-6.96-15.516-15.516-15.516h-75.048l-36.292-34.84a47.457 47.457 0 00-65.901 0l-36.292 34.84h-75.048c-8.556 0-15.516 6.96-15.516 15.516v71.425l-34.424 33.047c-8.689 8.342-13.672 20.034-13.672 32.078v288.305c0 12.976 10.557 23.532 23.532 23.532h368.742c12.976 0 23.532-10.557 23.532-23.532V200.21c-.001-12.044-4.984-23.736-13.673-32.078zM233.461 24.124a32.498 32.498 0 0145.126 0l25.02 24.02H208.44zm-121.728 39.02h288.581c.285 0 .516.231.516.516v152.307a7.5 7.5 0 0015 0v-60.088l24.037 23.075c5.758 5.527 9.06 13.275 9.06 21.257s-3.302 15.729-9.06 21.257L314.475 341.844l-25.5-24.479a47.46 47.46 0 00-65.902 0L63.12 470.918V233.563l105.387 101.171a7.475 7.475 0 005.193 2.09 7.5 7.5 0 005.195-12.91l-67.678-64.971V63.66c0-.285.231-.516.516-.516zm213.571 289.097l123.623-118.678v237.355zM72.18 178.953l24.037-23.075v88.665L72.18 221.468c-5.758-5.528-9.06-13.276-9.06-21.257s3.303-15.73 9.06-21.258zm368.215 318.094H71.653c-3.741 0-6.92-2.424-8.068-5.781L233.46 328.185a32.499 32.499 0 0145.127 0l169.876 163.082c-1.148 3.356-4.327 5.78-8.068 5.78z" />
      <path d="M137.186 163.392a7.498 7.498 0 006.612 3.961h40.597v8.532c0 12.976 10.557 23.532 23.532 23.532h96.193c12.976 0 23.532-10.557 23.532-23.532v-8.532h40.597a7.5 7.5 0 006.24-11.66l-13.259-19.889 13.259-19.888a7.5 7.5 0 00-6.24-11.66h-48.097c-12.976 0-23.532 10.557-23.532 23.532v8.532h-81.193v-8.532c0-12.976-10.557-23.532-23.532-23.532h-48.097a7.5 7.5 0 00-6.24 11.66l13.259 19.888-13.259 19.889a7.5 7.5 0 00-.372 7.699zm175.467 12.494c0 4.704-3.828 8.532-8.532 8.532h-96.193c-4.705 0-8.532-3.828-8.532-8.532v-24.564h113.258v24.564zm-1.033-48.097c0-4.705 3.828-8.532 8.532-8.532h34.083l-8.259 12.388a7.497 7.497 0 000 8.32l8.259 12.389h-26.583v-8.532a7.5 7.5 0 00-7.5-7.5h-8.532zm-145.55 3.856l-8.259-12.388h34.083c4.705 0 8.532 3.827 8.532 8.532v8.532h-8.532a7.5 7.5 0 00-7.5 7.5v8.532h-26.583l8.259-12.389a7.496 7.496 0 000-8.319zM328.169 224.499h-144.29a7.5 7.5 0 000 15h144.29a7.5 7.5 0 000-15zM312.136 264.579H199.911a7.5 7.5 0 000 15h112.226a7.5 7.5 0 10-.001-15z" />
    </svg>
  );
}

export default SvgComponent;
