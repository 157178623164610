import { Store, createStore, applyMiddleware } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import { History } from "history";
import { RootState, rootReducer } from "ducks";
import thunk from "redux-thunk";
import axios from "axios";
import { unauthorized } from "ducks/auth";

export function configureStore(
  history: History,
  initialState?: RootState
): Store<RootState> {
  let middleware = applyMiddleware(thunk);

  if (process.env.NODE_ENV !== "production") {
    middleware = composeWithDevTools(middleware);
  }

  const store = createStore(
    rootReducer as any,
    initialState,
    middleware
  ) as Store<RootState>;

  const validateToken = (auth?: string) => {
    if (!auth) {
      return true;
    }
    const token = store.getState().auth.token.raw;
    return auth === "Bearer " + token;
  };

  axios.interceptors.response.use(
    (response) => {
      if (!validateToken(response?.config?.headers?.Authorization)) {
        return Promise.reject({ message: "invalid request token" });
      }
      return response;
    },
    (error) => {
      if (error?.response?.status === 401) {
        store.dispatch(unauthorized());
      }
      return Promise.reject(error);
    }
  );

  // @ts-ignore
  if (process.env.NODE_ENV !== "production" && module.hot) {
    // @ts-ignore
    module.hot.accept("ducks", () => store.replaceReducer(rootReducer));
  }

  return store;
}
