import { RootState, AppThunk } from "ducks/state";
import { createSelector } from "reselect";
import { newNotification } from "./notification";
import { hen, Hen } from "@udok/lib/internal/store";
import { QuickResponse, QuickResponseFilter } from "@udok/lib/api/models";
import {
  fetchQuickResponse,
  createQuickResponse,
  fetchQuickResponseSearch,
} from "@udok/lib/api/messaging";
import { getToken, UNAUTHORIZED } from "./auth";
import moment from "moment";
import "moment/locale/pt-br";
moment.locale("pt-br");

export type InitialState = {
  quickResponseByID: { [quickID: string]: QuickResponse };
  searchResultIDList: string[];
};

// Reducers
const initialState: InitialState = {
  quickResponseByID: {},
  searchResultIDList: [],
};

class QuickResponseSlice extends Hen<InitialState> {
  quickResponseLoaded(q: QuickResponse) {
    this.state.quickResponseByID[q.quickID] = q;
  }
  quickResponsesLoaded(q: QuickResponse[]) {
    q.forEach((r) => {
      this.state.quickResponseByID[r.quickID] = r;
    });
  }
  quickResponseSearchLoaded(q: QuickResponse[]) {
    this.state.searchResultIDList = q.map((r) => {
      this.state.quickResponseByID[r.quickID] = r;
      return String(r.quickID);
    });
  }
}

export const [Reducer, actions] = hen(new QuickResponseSlice(initialState), {
  [UNAUTHORIZED]: () => {
    return initialState;
  },
});

// Selectors
const mainSelector = (state: RootState) => state.messaging;
const quickSearchSelector = (state: RootState) =>
  state.messaging.searchResultIDList;
const quickRepoSelector = (state: RootState) =>
  state.messaging.quickResponseByID;

export const quickResponseSearchView = createSelector(
  [quickSearchSelector, quickRepoSelector],
  (search, repo) => ({
    options: search.map((id) => repo[id]),
  })
);

export const getOneQuickResponses = (
  state: RootState,
  props: { quickID: string }
) =>
  createSelector(mainSelector, (state) => {
    return {
      location: state.quickResponseByID[props.quickID],
    };
  });

//Actions
export function createOneQuickResponse(
  pref: QuickResponse
): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;
    return createQuickResponse(apiToken, pref)
      .then((r) => {
        dispatch(actions.quickResponseLoaded(r));
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: (e.response?.data?.error || e).message,
          }) as any
        );
        throw e;
      });
  };
}

export function fetchOneQuickResponse(
  quickID: string
): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;
    return fetchQuickResponse(apiToken, quickID)
      .then((r) => {
        dispatch(actions.quickResponseLoaded(r));
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: (e.response?.data?.error || e).message,
          }) as any
        );
      });
  };
}

export function searchQuickResponse(
  filter?: QuickResponseFilter
): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;
    return fetchQuickResponseSearch(apiToken, filter)
      .then((r) => {
        dispatch(actions.quickResponseSearchLoaded(r));
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: (e.response?.data?.error || e).message,
          }) as any
        );
      });
  };
}
