import * as React from "react";

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      height={512}
      viewBox="0 0 256 256"
      width={512}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M90.866 152.915H76.847v-30.777H36.95v30.777H22.97c-11.029 0-19.97 8.94-19.97 19.969v43.721h107.835v-43.721c0-11.029-8.94-19.969-19.969-19.969z"
        fill="#f9cfcf"
      />
      <path
        d="M19.77 218.604a2 2 0 01-2-2v-33.286a2 2 0 014 0v33.286a2 2 0 01-2 2zM94.056 218.604a2 2 0 01-2-2v-33.286a2 2 0 014 0v33.286a2 2 0 01-2 2z"
        fill="#3762cc"
      />
      <path
        d="M110.835 218.604H3a2 2 0 01-2-2v-43.721c0-12.114 9.855-21.969 21.969-21.969h11.98v-28.777a2 2 0 012-2h39.897a2 2 0 012 2v28.777h12.019c12.114 0 21.97 9.855 21.97 21.969v43.721a2 2 0 01-2 2zM5 214.604h103.835v-41.721c0-9.908-8.061-17.969-17.97-17.969H76.847a2 2 0 01-2-2v-28.777H38.95v28.777a2 2 0 01-2 2H22.97c-9.909.001-17.97 8.062-17.97 17.97z"
        fill="#3762cc"
      />
      <path
        d="M56.918 138.897c-25.835 0-46.779-20.944-46.779-46.779V72.525c0-6.494 5.264-11.758 11.758-11.758h81.8v31.352c0 25.834-20.944 46.778-46.779 46.778z"
        fill="#4671c6"
      />
      <path
        d="M56.917 140.897c-26.896 0-48.779-21.882-48.779-48.779V72.525c0-7.586 6.172-13.758 13.758-13.758h81.8a2 2 0 012 2v31.352c.001 26.896-21.882 48.778-48.779 48.778zm-35.02-78.13c-5.381 0-9.758 4.377-9.758 9.758v19.593c0 24.691 20.088 44.779 44.779 44.779s44.779-20.088 44.779-44.779V62.767z"
        fill="#3762cc"
      />
      <path
        d="M96.759 95.28v-3.73c0-21.806-17.838-39.483-39.842-39.483-22.004 0-39.842 17.677-39.842 39.483v3.731c-6.918-.14-12.407 5.371-12.407 12.033 0 7.114 6.21 12.741 13.459 11.974 4.112 17.469 19.913 30.483 38.789 30.483 18.878 0 34.678-13.016 38.789-30.483 7.239.766 13.459-4.851 13.459-11.974.002-6.666-5.491-12.174-12.405-12.034z"
        fill="#f9cfcf"
      />
      <path
        d="M56.913 131.326c-1.666 0-3.122-.197-4.451-.601-2.206-.671-4.144-1.803-5.603-3.273a2 2 0 012.84-2.818c.994 1.002 2.352 1.785 3.927 2.264.947.288 2.022.428 3.288.428s2.34-.14 3.287-.428c1.575-.479 2.933-1.261 3.927-2.264a2 2 0 012.84 2.818c-1.459 1.471-3.397 2.603-5.604 3.273-1.33.404-2.786.601-4.451.601zM77.602 115.518a5.036 5.036 0 01-5.036-5.036v-5.371a5.036 5.036 0 0110.072 0v5.371a5.036 5.036 0 01-5.036 5.036zM36.223 115.518a5.036 5.036 0 01-5.036-5.036v-5.371a5.036 5.036 0 0110.072 0v5.371a5.036 5.036 0 01-5.036 5.036z"
        fill="#3762cc"
      />
      <path
        d="M56.917 151.771c-18.986 0-35.335-12.425-40.324-30.417a14.23 14.23 0 01-9.276-3.635 14.025 14.025 0 01-4.648-10.406c0-3.786 1.496-7.336 4.211-9.998a14.046 14.046 0 018.195-3.935v-1.83c0-22.874 18.77-41.483 41.842-41.483S98.759 68.676 98.759 91.55v1.832a14.04 14.04 0 018.196 3.935c2.715 2.661 4.211 6.211 4.211 9.997 0 3.955-1.694 7.747-4.646 10.405a14.244 14.244 0 01-9.278 3.636c-4.99 17.991-21.339 30.416-40.325 30.416zm-38.788-34.483c.918 0 1.731.63 1.945 1.542 4.011 17.041 19.161 28.941 36.843 28.941S89.75 135.87 93.76 118.83a1.998 1.998 0 012.157-1.531 10.259 10.259 0 007.927-2.554 10.018 10.018 0 003.322-7.431 9.924 9.924 0 00-3.011-7.14c-1.961-1.922-4.57-2.947-7.356-2.894a1.96 1.96 0 01-1.44-.571 2 2 0 01-.6-1.429v-3.73c0-20.668-16.976-37.483-37.842-37.483S19.076 70.882 19.076 91.55v3.73c0 .538-.216 1.052-.6 1.428s-.904.589-1.44.571c-2.771-.059-5.394.971-7.355 2.893a9.928 9.928 0 00-3.011 7.141c0 2.823 1.211 5.532 3.324 7.433 2.174 1.957 4.989 2.861 7.925 2.553.07-.008.141-.011.21-.011z"
        fill="#3762cc"
      />
      <path
        d="M103.697 87.622H41.189c-13.317 0-24.113-10.796-24.113-24.113s10.796-24.113 24.113-24.113h40.915c11.925 0 21.593 9.667 21.593 21.593z"
        fill="#4671c6"
      />
      <path
        d="M103.697 89.622H41.189c-14.399 0-26.113-11.714-26.113-26.113S26.79 37.396 41.189 37.396h40.915c13.009 0 23.593 10.583 23.593 23.593v26.634a2 2 0 01-2 1.999zM41.189 41.396c-12.193 0-22.113 9.92-22.113 22.113s9.92 22.113 22.113 22.113h60.508V60.988c0-10.804-8.789-19.593-19.593-19.593H41.189z"
        fill="#3762cc"
      />
      <g>
        <path
          d="M245 23H130.667a8 8 0 00-8 8v86.068l-15.987 9.198c-1.337.769-1.337 2.698 0 3.467l15.987 9.198V225a8 8 0 008 8H245a8 8 0 008-8V31a8 8 0 00-8-8z"
          fill="#e0ebfc"
        />
      </g>
      <g>
        <path
          d="M221.21 69.802h-21.606V48.196a3.717 3.717 0 00-3.717-3.717H179.78a3.717 3.717 0 00-3.717 3.717v21.606h-21.606a3.717 3.717 0 00-3.717 3.717v16.108a3.717 3.717 0 003.717 3.717h21.606v21.606a3.717 3.717 0 003.717 3.717h16.108a3.717 3.717 0 003.717-3.717V93.344h21.606a3.717 3.717 0 003.717-3.717V73.519a3.718 3.718 0 00-3.718-3.717z"
          fill="#f9a7a7"
        />
      </g>
      <g>
        <path
          d="M154.929 153.459h-12.242a2.48 2.48 0 01-2.479-2.479v-12.242a2.48 2.48 0 012.479-2.479h12.242a2.48 2.48 0 012.479 2.479v12.242a2.478 2.478 0 01-2.479 2.479z"
          fill="#4671c6"
        />
      </g>
      <g>
        <path
          d="M235.458 148.583H167.5a4 4 0 010-8h67.958a4 4 0 010 8z"
          fill="#4671c6"
        />
      </g>
      <g>
        <path
          d="M154.929 183.321h-12.242a2.48 2.48 0 01-2.479-2.479V168.6a2.48 2.48 0 012.479-2.479h12.242a2.48 2.48 0 012.479 2.479v12.242a2.478 2.478 0 01-2.479 2.479z"
          fill="#4671c6"
        />
      </g>
      <g>
        <path
          d="M235.458 178.583H167.5a4 4 0 010-8h67.958a4 4 0 010 8z"
          fill="#4671c6"
        />
      </g>
      <g>
        <path
          d="M154.929 213.429h-12.242a2.48 2.48 0 01-2.479-2.479v-12.242a2.48 2.48 0 012.479-2.479h12.242a2.48 2.48 0 012.479 2.479v12.242a2.478 2.478 0 01-2.479 2.479z"
          fill="#4671c6"
        />
      </g>
      <g>
        <path
          d="M235.458 208.583H167.5a4 4 0 010-8h67.958a4 4 0 010 8z"
          fill="#4671c6"
        />
      </g>
      <g>
        <path
          d="M145.342 150.786a2.498 2.498 0 01-2.236-1.382l-4.339-8.677a2.5 2.5 0 014.472-2.236l2.801 5.603 12.683-12.472a2.5 2.5 0 013.506 3.566l-15.134 14.882a2.504 2.504 0 01-1.753.716z"
          fill="#f9a7a7"
        />
      </g>
      <g>
        <path
          d="M145.342 182.076a2.498 2.498 0 01-2.236-1.382l-4.339-8.678a2.5 2.5 0 014.472-2.236l2.801 5.603 12.683-12.472a2.5 2.5 0 013.506 3.566l-15.134 14.882a2.5 2.5 0 01-1.753.717z"
          fill="#f9a7a7"
        />
      </g>
      <g>
        <path
          d="M145.342 211.073a2.498 2.498 0 01-2.236-1.382l-4.339-8.678a2.5 2.5 0 014.472-2.236l2.801 5.603 12.683-12.471a2.5 2.5 0 013.506 3.566l-15.134 14.882a2.504 2.504 0 01-1.753.716z"
          fill="#f9a7a7"
        />
      </g>
      <g>
        <path
          d="M245 235H130.667c-5.514 0-10-4.486-10-10v-84.912l-14.984-8.622c-1.255-.722-2.005-2.018-2.005-3.467s.75-2.745 2.006-3.467l14.984-8.622V31c0-5.514 4.486-10 10-10H245c5.514 0 10 4.486 10 10v194c0 5.514-4.486 10-10 10zM130.667 25c-3.309 0-6 2.691-6 6v86.068c0 .716-.382 1.376-1.002 1.733L107.677 128l15.987 9.198a2 2 0 011.002 1.733V225c0 3.309 2.692 6 6 6H245c3.309 0 6-2.691 6-6V31c0-3.309-2.691-6-6-6z"
          fill="#3762cc"
        />
      </g>
    </svg>
  );
}

export default SvgComponent;
