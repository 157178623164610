import * as React from "react";
import { SVGProps } from "react";

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={682.667}
    height={682.667}
    viewBox="0 0 512 512"
    fill="currentColor"
    {...props}
  >
    <path d="M194.6 18.1c-8.2 1.9-16.9 7.2-24.3 14.6l-7.2 7.3h-13.6c-18 0-23.3 2.2-27.6 11.5-1.6 3.5-1.9 6.8-1.9 28.5 0 26.6.6 29.6 6.1 34.7 5.9 5.4 4 5.3 82.1 5.3 68.2 0 72.5-.1 76.3-1.9 4.8-2.2 7.9-5.4 10-10.3 1.2-3 1.5-8.3 1.5-27.8 0-25.7-.5-28.6-5.3-33.9-4.5-4.9-9-6.1-24-6.1h-13.8l-7.2-7.3c-7.6-7.7-15.8-12.6-24.7-14.6-5.4-1.3-21-1.2-26.4 0zm26.9 56.4c1.6 1.5 2.5 3.6 2.5 5.5s-.9 4-2.5 5.5l-2.4 2.5h-54.2l-2.4-2.5c-1.6-1.5-2.5-3.6-2.5-5.5s.9-4 2.5-5.5l2.4-2.5h54.2l2.4 2.5zm32 0c5 4.9 1.5 13.5-5.5 13.5-4.1 0-8-3.9-8-8s3.9-8 8-8c1.9 0 4 .9 5.5 2.5zM46.6 49.1c-6.2 1.5-15.3 6.8-19.7 11.6-2.2 2.4-5.5 7.4-7.2 11.1l-3.2 6.7v387.1l3.7 7.6c4.5 9.1 10.3 14.7 19.6 19.1l6.7 3.2 74.1.3c74 .3 74.1.3 76.7-1.8 2-1.6 2.7-3 2.7-5.7 0-2.9-1.2-4.9-7-11.6-3.8-4.5-8.9-11.8-11.2-16.1-2.4-4.3-5.3-9-6.5-10.3l-2.2-2.3H69.8l-2.9-2.9-2.9-2.9V101.8l2.9-2.9 2.9-2.9h14.7c14.2 0 14.7-.1 17-2.5 2.5-2.4 2.5-2.5 2.5-21.9V52.1l-2.6-2c-2.5-2-3.8-2.1-26.8-2-13.2.1-25.8.5-28 1zM314.5 50.4C312 52.9 312 52.9 312 72c0 18.9 0 19.1 2.5 21.5 2.3 2.4 2.8 2.5 17 2.5h14.7l2.9 2.9 2.9 2.9v96.5c0 96 0 96.4 2.1 99.1 2.5 3.2 5.7 3.3 16.2.7 4.2-1.1 11.5-2.3 16.3-2.7 7.3-.6 9.2-1.1 11.1-3l2.3-2.4-.2-105.7-.3-105.8-3.2-6.7c-4.4-9.3-10-15.1-19.1-19.6l-7.6-3.7-26.3-.3-26.2-.3-2.6 2.5z" />
    <path d="M144.5 138.4c-5.4 2.5-10.3 7.4-12.4 12.2-.9 2.3-2.6 3.6-6.7 5.4-7.7 3.1-15.6 11.1-19.7 19.7-3 6.5-3.2 7.5-3.5 21-.4 12.8-.6 14.3-2.3 14.8-2.9 1-9.4 9-10.7 13.3-1.6 5.3-1.5 20.7.2 32.2 5.8 38.1 28.8 70.6 62.8 89 7 3.8 26.8 11 30.2 11 1.4 0 1.6 3 1.6 29.9 0 32.6.9 40.8 5.7 53.1 8.7 22 27.3 40.8 48.3 48.9 22.8 8.8 48.3 7.9 69.2-2.4 23.2-11.5 39.5-31.5 45.9-56.4 1.4-5.2 2.2-13.2 2.8-25.1.7-16.2 1-17.9 3.5-23.3 3.5-7.4 10.9-14.7 18.3-18.3 5.2-2.6 6.9-2.9 15.8-2.9s10.7.3 16.2 2.9c7.7 3.5 15.3 11.3 19.1 19.3 2.5 5.5 2.7 6.8 3 23.3l.4 17.5-4.7 4.9c-12.6 13-14.9 32.8-5.8 48.3 3.6 6.2 12.4 13.8 19.1 16.4 8.2 3.3 22.3 3.3 30.7-.1 7.2-2.8 17-12.1 20.3-19.3 7.2-15.5 4.4-33.1-7.1-45.1l-4.5-4.7-.4-20.2c-.5-18.3-.8-21.1-3.2-29.2-7.3-24.2-22.9-42.8-45.1-53.5-12.3-5.9-19.9-7.9-33.5-8.7-23.7-1.3-45.1 6.7-63.1 23.5-12 11.3-20.1 24.7-24.5 40.8-.9 3.6-1.9 12.8-2.4 23.4-.6 14.4-1.1 18.5-3 23.3-2.9 7.6-11.3 16.7-18.8 20.4-5.1 2.5-6.7 2.8-16.2 2.8-9.6 0-11-.3-16.4-2.9-7.9-3.9-14-10.2-17.9-18.4l-3.2-6.7-.3-30.7c-.3-26.9-.1-30.8 1.2-30.8.8 0 5.2-1.2 9.8-2.6 37.3-11.7 66-39.8 78.3-76.6 5.8-17.4 8.4-42.8 5.3-53-1.3-4.3-7.8-12.3-10.7-13.3-1.7-.5-1.9-2-2.3-14.8-.3-13.5-.5-14.5-3.5-21-4.1-8.6-12-16.6-19.7-19.7-4.1-1.8-5.8-3.1-6.7-5.4-4.4-10.5-16.5-16.3-28-13.6-4.2 1-6.4 2.4-10.4 6.4-10.2 10.2-10.2 23 0 33.1 10.2 10.3 22.9 10.2 33.4-.3 6.2-6.2 7.2-6.1 12.9.3 5 5.7 6.2 10 6.2 22.2v10l-4.1 1.8c-5.3 2.5-11.4 9.4-12.7 14.7-.6 2.4-1.3 9.7-1.6 16.3-.8 20.7-6.9 35-21 49.1-9 9-17.5 14.3-29.4 18.2-6.7 2.2-9.4 2.6-21.2 2.6-11.8 0-14.5-.4-21.2-2.6-11.9-3.9-20.4-9.2-29.4-18.2-14.1-14.1-20.2-28.4-21-49.1-.3-6.6-1-13.9-1.6-16.3-1.3-5.3-7.4-12.2-12.7-14.7l-4.1-1.8v-10c0-12.2 1.2-16.5 6.2-22.2 5.7-6.4 6.7-6.5 12.9-.3 7.7 7.7 15.4 9.6 25.1 6.2 5.1-1.7 10.9-6.7 13.4-11.6 2.2-4.2 2.8-12.4 1.4-17.8-.8-3.2-2.6-5.9-6.3-9.5-8-7.8-16.8-9.5-26.2-5.1zm317 312.1c5 4.9 1.5 13.5-5.5 13.5-4.1 0-8-3.9-8-8s3.9-8 8-8c1.9 0 4 .9 5.5 2.5z" />
    <path d="M193.2 185.6c-5.8 3-8.6 7.7-9 15.4l-.4 6.8-6.8.4c-5.4.3-7.4.9-10.2 3-5.9 4.5-7 8.1-6.6 22.2.3 11.7.4 12.4 3.2 16 3.5 4.7 7.9 6.6 15 6.6h5.4l.4 6.9c.3 5.6.9 7.5 3.2 10.6 4.4 5.7 7.9 6.7 22.2 6.3 10.5-.3 12.7-.6 15-2.4 5.2-3.9 6.8-7 7.2-14.3l.4-6.9 6.9-.4c7.3-.4 10.4-2 14.3-7.2 1.8-2.3 2.1-4.5 2.4-15 .4-14.3-.6-17.8-6.3-22.2-3.1-2.3-5-2.9-10.6-3.2l-6.9-.4v-5.4c0-7.1-1.9-11.5-6.6-15-3.6-2.8-4.2-2.9-16.3-3.2-10.2-.2-13.2.1-15.9 1.4zM384.5 376.4c-4.2 1.9-8 5.6-10.4 10.4-1.8 3.5-2.1 5.9-2.1 15.4 0 31.8-8.2 55.6-25.9 75.1-6.8 7.5-7.8 9.7-6.1 13.7 1.8 4.3 5.5 5.3 18.3 4.8 10-.4 12-.8 17.9-3.5 9.2-4.3 15.1-10 19.6-19.1l3.7-7.6.3-42.6c.3-42.1.3-42.6-1.8-45.3-1.7-2.2-3-2.7-6.3-2.7-2.3.1-5.5.7-7.2 1.4z" />
  </svg>
);

export default SvgComponent;
