import React from "react";

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg height={512} viewBox="0 0 497 497" width={512} {...props}>
      <path
        d="M16.567 397.6v66.267C16.567 482.166 31.401 497 49.7 497h397.6c18.299 0 33.132-14.834 33.132-33.133V397.6z"
        fill="#b5dbff"
      />
      <path
        d="M457.433 397.6v66.268c0 18.298-14.834 33.132-33.132 33.132h23c18.299 0 33.132-14.834 33.132-33.132V397.6z"
        fill="#97d0ff"
      />
      <path
        d="M16.567 132.533v298.2c0 18.298 14.834 33.132 33.132 33.132H447.3c18.299 0 33.132-14.834 33.132-33.132v-298.2z"
        fill="#edf5ff"
      />
      <path
        d="M457.433 132.533v298.2c0 18.298-14.834 33.132-33.132 33.132h23c18.299 0 33.132-14.834 33.132-33.132v-298.2z"
        fill="#d5e8fe"
      />
      <path
        d="M480.433 149.1V66.266c0-18.299-14.834-33.132-33.132-33.132H49.7c-18.299 0-33.132 14.834-33.132 33.132V149.1z"
        fill="#ff435b"
      />
      <path
        d="M115.967 73.767H99.4a7.5 7.5 0 010-15h16.567a7.5 7.5 0 010 15zM165.667 73.767H149.1a7.5 7.5 0 010-15h16.566a7.5 7.5 0 01.001 15zM347.9 73.767h-16.566a7.5 7.5 0 010-15H347.9a7.5 7.5 0 010 15zM397.6 73.767h-16.567a7.5 7.5 0 010-15H397.6a7.5 7.5 0 010 15z"
        fill="#e3374e"
      />
      <path
        d="M115.967 66.267c0 9.149 7.417 16.567 16.567 16.567s16.567-7.417 16.567-16.567v-49.7C149.1 7.417 141.683 0 132.533 0c-9.149 0-16.567 7.417-16.567 16.567v49.7zM347.9 66.267c0 9.149 7.417 16.567 16.567 16.567s16.567-7.417 16.567-16.567v-49.7c0-9.15-7.417-16.567-16.567-16.567S347.9 7.417 347.9 16.567z"
        fill="#596c76"
      />
      <g fill="#e3374e">
        <path d="M447.3 33.133h-23c18.299 0 33.132 14.834 33.132 33.132V149.1h23V66.266c.001-18.299-14.833-33.133-33.132-33.133z" />
        <path d="M16.567 108.467h463.866v15H16.567z" />
      </g>
      <path
        d="M322.609 182.233H173.95a8.283 8.283 0 00-8.283 8.283v16.567a8.282 8.282 0 008.283 8.283h148.659z"
        fill="#c3ea21"
      />
      <path
        d="M414.167 256H82.834c-4.142 0-7.5-3.357-7.5-7.5s3.358-7.5 7.5-7.5h331.333c4.142 0 7.5 3.357 7.5 7.5s-3.358 7.5-7.5 7.5zM414.167 289.134H82.834c-4.142 0-7.5-3.357-7.5-7.5s3.358-7.5 7.5-7.5h331.333a7.5 7.5 0 010 15zM414.167 322.267H82.834c-4.142 0-7.5-3.357-7.5-7.5s3.358-7.5 7.5-7.5h331.333c4.142 0 7.5 3.357 7.5 7.5s-3.358 7.5-7.5 7.5z"
        fill="#596c76"
      />
      <g>
        <path
          d="M198.8 355.4H82.834c-4.142 0-7.5-3.357-7.5-7.5s3.358-7.5 7.5-7.5H198.8c4.142 0 7.5 3.357 7.5 7.5s-3.358 7.5-7.5 7.5z"
          fill="#596c76"
        />
      </g>
      <g>
        <path
          d="M198.8 355.4a7.5 7.5 0 01-5.303-12.803l25.05-25.051a7.501 7.501 0 0110.606 10.607l-25.05 25.051a7.477 7.477 0 01-5.303 2.196z"
          fill="#465a61"
        />
      </g>
      <path
        d="M365.115 134.727L212.857 286.985a8 8 0 00-2.343 5.657v35.544a8 8 0 008 8h35.544a8 8 0 005.657-2.343l152.257-152.257z"
        fill="#ffe1ba"
      />
      <path
        d="M381.537 212.021l30.436-30.436-11.479-11.479-163.903 163.903a7.997 7.997 0 01-4.043 2.177h21.511a8.003 8.003 0 005.656-2.343z"
        fill="#ffbfab"
      />
      <path
        d="M419.019 104.252c-6.47-6.469-16.959-6.469-23.429 0l-32.681 32.681 46.858 46.857 32.681-32.681c6.47-6.47 6.47-16.959 0-23.429z"
        fill="#ff7b4a"
      />
      <path
        d="M354.809 145.034L212.857 286.985a7.988 7.988 0 00-1.828 2.858l45.828 45.828a7.988 7.988 0 002.858-1.828l141.951-141.951z"
        fill="#76e2f8"
      />
      <path
        d="M442.448 127.681l-23.429-23.429a16.686 16.686 0 00-1.256-1.135c-.179-.146-.37-.27-.554-.408-.26-.195-.515-.399-.784-.576-.24-.159-.491-.292-.737-.437-.224-.132-.442-.276-.671-.396-.3-.158-.609-.288-.916-.426-.184-.083-.363-.179-.549-.255-.364-.148-.737-.268-1.109-.389-.135-.044-.267-.1-.403-.141a16.305 16.305 0 00-1.247-.306c-.101-.022-.199-.054-.3-.073a16.703 16.703 0 00-1.423-.208c-.049-.005-.097-.016-.146-.021a16.741 16.741 0 00-1.567-.078l-.013-.001a16.522 16.522 0 00-11.69 4.793l-.003.003c.08.077.166.142.244.221l23.429 23.429c6.47 6.47 6.47 16.959 0 23.429l-21.036 21.036 11.479 11.479 32.681-32.681c6.469-6.471 6.469-16.96 0-23.43z"
        fill="#ff624a"
      />
      <path
        d="M228.64 307.453l10.607 10.607 138.218-138.217a7.5 7.5 0 00-10.606-10.607zM390.187 180.412L245.893 324.707l10.964 10.964c.265-.099.525-.212.778-.338l.003-.001c.238-.119.47-.251.696-.393l.057-.034c.204-.13.4-.274.592-.423.034-.027.071-.05.105-.078a7.9 7.9 0 00.627-.561l141.951-141.951z"
        fill="#5bccf5"
      />
      <path
        d="M348.679 151.135l23.425-23.425 46.85 46.85-23.424 23.426z"
        fill="#ffda2d"
      />
      <path
        d="M395.59 197.967l13.017-13.016 10.412-10.412-11.479-11.479-23.429 23.428z"
        fill="#fdbf00"
      />
    </svg>
  );
}

export default SvgComponent;
