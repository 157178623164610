import React from "react";

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      height="512"
      viewBox="0 0 64 64"
      width="512"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g id="document-command-report-sign-pen">
        <path d="m57 16v15h-6v-15a3 3 0 0 1 6 0z" fill="#ff7956" />
        <path
          d="m57 16v15h-3v-15a2.993 2.993 0 0 0 -.88-2.12 3.3 3.3 0 0 0 -.62-.48 3.026 3.026 0 0 1 1.5-.4 3 3 0 0 1 3 3z"
          fill="#ff5023"
        />
        <path d="m56 31v24l-2 6-2-6v-24z" fill="#c0ab91" />
        <path d="m56 31v24l-2 6v-30z" fill="#957856" />
        <rect fill="#fee9ab" height="46" rx="2" width="38" x="9" y="3" />
        <path
          d="m45 3h-4a2.006 2.006 0 0 1 2 2v42a2.006 2.006 0 0 1 -2 2h4a2.006 2.006 0 0 0 2-2v-42a2.006 2.006 0 0 0 -2-2z"
          fill="#ffd422"
        />
        <path d="m31 35h12v10h-12z" fill="#bdfdff" />
        <path d="m34 12v4h-4v4h-4v-4h-4v-4h4v-4h4v4z" fill="#ff3051" />
        <path
          d="m31 49-4 6h-22l-2-21v-4a4 4 0 0 1 4-4h7a4 4 0 1 1 0 8h-5v13a2.006 2.006 0 0 0 2 2z"
          fill="#ffc477"
        />
        <path d="m5 55h22v6h-22z" fill="#2488ff" />
        <path d="m34 12v4h-4v4h-2v-12h2v4z" fill="#cd2a00" />
        <path d="m45 2h-34a3.009 3.009 0 0 0 -3 3v20h-1a5 5 0 0 0 -5 5v4.09l2 20.91v6a1 1 0 0 0 1 1h22a1 1 0 0 0 1-1v-5.7l3.54-5.3h13.46a3.009 3.009 0 0 0 3-3v-42a3.009 3.009 0 0 0 -3-3zm-19 58h-20v-4h20zm.46-6h-20.55l-1.91-20v-4a3.009 3.009 0 0 1 3-3h7a3 3 0 0 1 0 6h-5a1 1 0 0 0 -1 1v13a3.009 3.009 0 0 0 3 3h18.13zm19.54-7a1 1 0 0 1 -1 1h-34a1 1 0 0 1 -1-1v-12h4a5.017 5.017 0 0 0 4.9-4h20.1v-2h-20.1a5.017 5.017 0 0 0 -4.9-4h-4v-20a1 1 0 0 1 1-1h34a1 1 0 0 1 1 1z" />
        <path d="m22 17h3v3a1 1 0 0 0 1 1h4a1 1 0 0 0 1-1v-3h3a1 1 0 0 0 1-1v-4a1 1 0 0 0 -1-1h-3v-3a1 1 0 0 0 -1-1h-4a1 1 0 0 0 -1 1v3h-3a1 1 0 0 0 -1 1v4a1 1 0 0 0 1 1zm1-4h3a1 1 0 0 0 1-1v-3h2v3a1 1 0 0 0 1 1h3v2h-3a1 1 0 0 0 -1 1v3h-2v-3a1 1 0 0 0 -1-1h-3z" />
        <path d="m61 15h-3.14a3.993 3.993 0 0 0 -7.86 1v15a1 1 0 0 0 1 1v23a1.185 1.185 0 0 0 .05.32l2 6a1 1 0 0 0 1.9 0l2-6a1.185 1.185 0 0 0 .05-.32v-23a1 1 0 0 0 1-1v-14h2v15h2v-16a1 1 0 0 0 -1-1zm-6 39.84-1 3-1-3v-22.84h2zm1-24.84h-4v-14a2 2 0 0 1 4 0z" />
        <path d="m43 34h-12a1 1 0 0 0 -1 1v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-10a1 1 0 0 0 -1-1zm-1 10h-10v-8h10z" />
        <path d="m40.51 39.74-1.02 1.72-1.49-.89-1.49.89a1.03 1.03 0 0 1 -.51.14 1.067 1.067 0 0 1 -.52-.14l-1.99-1.2 1.02-1.72 1.49.89 1.49-.89a.984.984 0 0 1 1.02 0z" />
        <path d="m19 23h20v2h-20z" />
      </g>
    </svg>
  );
}

export default SvgComponent;
