import * as React from "react";

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      viewBox="0 0 36 36"
      width={36}
      height={36}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M4.5 0C2.033 0 0 2.033 0 4.5v7C0 13.967 2.033 16 4.5 16h7c2.467 0 4.5-2.033 4.5-4.5v-7C16 2.033 13.967 0 11.5 0h-7zm20 0C22.032 0 20 2.033 20 4.5v7c0 2.467 2.032 4.5 4.5 4.5h7c2.468 0 4.5-2.033 4.5-4.5v-7C36 2.033 33.968 0 31.5 0h-7zm-20 3h7c.847 0 1.5.654 1.5 1.5v7c0 .847-.653 1.5-1.5 1.5h-7c-.846 0-1.5-.653-1.5-1.5v-7C3 3.654 3.654 3 4.5 3zm20 0h7c.846 0 1.5.654 1.5 1.5v7c0 .847-.654 1.5-1.5 1.5h-7c-.846 0-1.5-.653-1.5-1.5v-7c0-.846.654-1.5 1.5-1.5zM6 6v4h4V6H6zm20 0v4h4V6h-4zM4.5 20C2.033 20 0 22.032 0 24.5v7C0 33.968 2.033 36 4.5 36h7c2.467 0 4.5-2.032 4.5-4.5v-7c0-2.468-2.033-4.5-4.5-4.5h-7zM20 20v5.5h5.5V20H20zm5.5 5.5v5h5v-5h-5zm5 0H36V20h-5.5v5.5zm0 5V36H36v-5.5h-5.5zm-5 0H20V36h5.5v-5.5zM4.5 23h7c.847 0 1.5.654 1.5 1.5v7c0 .846-.653 1.5-1.5 1.5h-7c-.846 0-1.5-.654-1.5-1.5v-7c0-.846.654-1.5 1.5-1.5zM6 26v4h4v-4H6z" />
    </svg>
  );
}

export default SvgComponent;
