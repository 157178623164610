import React from "react";
import { connect } from "react-redux";
import { login } from "ducks/auth";

import {
  Button,
  InputAdornment,
  IconButton,
  TextField,
  CircularProgress,
} from "@material-ui/core";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { LoginResponse, LoginForm } from "@udok/lib/api/auth";
import { makeStyles } from "@material-ui/core/styles";

const style = makeStyles((theme) => ({
  root: {},
  input: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  submitBtn: { marginTop: theme.spacing(1), marginBottom: theme.spacing(2) },
}));

export interface NormalLoginFormProps {
  login: (l: LoginForm) => Promise<LoginResponse>;
  onLogin?: (r: any) => any;
}

const NormalLoginForm = (props: NormalLoginFormProps) => {
  const [showPassword, setShowPassword] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const classes = style();

  const handleSubmit = React.useCallback(
    (e: any) => {
      e.preventDefault();
      setLoading(true);
      props
        .login({
          email: email,
          password: password,
          rememberMe: true,
        })
        .then((r: any) => props?.onLogin?.(r))
        .catch((e: any) => {})
        .finally(() => {
          setLoading(false);
        });
    },
    [props, email, password]
  );

  return (
    <form className={classes.root} onSubmit={handleSubmit}>
      <TextField
        id="email"
        label="Nome de usuário ou email"
        variant="outlined"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        margin="normal"
        fullWidth
        disabled={loading}
        className={classes.input}
      />

      <TextField
        id="adornment-password"
        label="Senha"
        variant="outlined"
        fullWidth
        disabled={loading}
        type={showPassword ? "text" : "password"}
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                color="primary"
                aria-label="Toggle password visibility"
                onClick={() => setShowPassword(!showPassword)}
                onMouseDown={(e) => e.preventDefault()}
              >
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          ),
        }}
        className={classes.input}
      />
      <div>
        <Button
          fullWidth
          type="submit"
          disabled={loading}
          variant="contained"
          color="primary"
          className={classes.submitBtn}
        >
          <span>Login</span>
          {loading && <CircularProgress size={24} />}
        </Button>
      </div>
    </form>
  );
};

export default connect(
  () => ({}),
  (dispatch: any) => ({
    login: (d: any) => dispatch(login(d)),
  })
)(NormalLoginForm);
