import axios from "axios";
import { deriveErrorMessage } from "@udok/lib/internal/util";
import { Invitation } from "@udok/lib/api/models";

export type Relationship = { doctID: string; patiID: string };
export const createRelationship = async (
  token: string,
  relationship: Relationship
) => {
  return axios
    .post(`${process.env.API_PATH}/doctorpatient`, relationship, {
      headers: {
        Authorization: token,
      },
    })
    .then((r) => {
      return r.data.data.item as Invitation;
    })
    .catch((e) => {
      const err = deriveErrorMessage(e, "inv1");
      return Promise.reject(err);
    });
};
