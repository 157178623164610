import {
  Appointment,
  DocumentData,
  AppointmentCollectDocument,
  ValidateSchema,
} from "@udok/lib/api/models";
import Ajv from "ajv";

export type AttachmentValue = {
  attachmentID: string;
  required: boolean;
  alternativeAttachments?: string[];
  visibilitySettings?: AttachmentVisibility[];
};
export type AttachmentVisibility = {
  visibility: boolean;
  heplID: string;
};

export function generateValidationSchema(value: AttachmentValue[]) {
  let schema: ValidateSchema = { type: "object", required: [], properties: {} };

  value.forEach((val) => {
    const alternatives = val?.alternativeAttachments ?? [];
    if (val.required && alternatives.length > 0) {
      schema = {
        ...schema,
        properties: {
          ...schema.properties,
          ["anyOf_" + val.attachmentID]: {
            anyOf: [
              {
                properties: { [val.attachmentID]: { type: "string" } },
                required: [val.attachmentID],
              },
              ...alternatives.map((id) => ({
                properties: { [id]: { type: "string" } },
                required: [id],
              })),
            ],
          },
        },
      };
      return;
    }
    schema = {
      ...schema,
      properties: {
        ...schema.properties,
        [val.attachmentID]: { type: "string" },
      },
      ...(val.required
        ? { required: [...schema.required, val.attachmentID] }
        : {}),
    };
  });

  return schema;
}

export function generateAttachmentValue(
  Attachments: string[],
  schema: Partial<ValidateSchema>,
  visibility?: { [ID: string]: AttachmentVisibility[] }
) {
  let values: AttachmentValue[] = [];
  Attachments.forEach((attachmentID) => {
    const anyOf = schema?.properties?.["anyOf_" + attachmentID];
    let alternative: string[] = [];
    ((anyOf as any)?.anyOf ?? []).forEach((prop: any) => {
      alternative = [...alternative, ...Object.keys(prop.properties)];
    });
    alternative = alternative.filter((id) => id !== attachmentID);
    values = [
      ...values,
      {
        attachmentID: attachmentID,
        required:
          ((schema?.required ?? []) as string[]).indexOf(attachmentID) !== -1 ||
          alternative.length > 0,
        alternativeAttachments: alternative,
        visibilitySettings: visibility?.[attachmentID] ?? [],
      },
    ];
  });

  return values;
}

export function validateAttachments(
  appointment: Appointment,
  listForms: DocumentData[],
  listCollectDocu: AppointmentCollectDocument[]
) {
  let schema = appointment?.validationSchema ?? {};
  const healthplans = appointment?.healthplans ?? [];
  const templateVisibilitys = appointment?.documentTemplateVisibilitys ?? [];
  const documentVisibilitys = appointment?.collectDocumentVisibilitys ?? [];
  if (!!schema?.required) {
    schema = {
      ...schema,
      required: schema.required.filter((id) => {
        const tv = templateVisibilitys.filter((v) => v.doteID === id);
        const dv = documentVisibilitys.filter((v) => v.codoID === id);
        const visibilitys = [...tv, ...dv];
        if (visibilitys.length === 0) {
          return true;
        }

        return documentShouldBeVisible(visibilitys, healthplans);
      }),
    };
  }

  if (!!schema?.properties) {
    Object.keys(schema?.properties).forEach((key) => {
      let anyOf = (schema?.properties?.[key] as any)?.anyOf ?? [];
      if (anyOf.length == 0) {
        return;
      }

      anyOf = anyOf.filter((a: any) => {
        const id = Object.keys(a.properties)[0];
        const tv = templateVisibilitys.filter((v) => v.doteID === id);
        const dv = documentVisibilitys.filter((v) => v.codoID === id);
        const visibilitys = [...tv, ...dv];
        if (visibilitys.length === 0) {
          return true;
        }
        return documentShouldBeVisible(visibilitys, healthplans);
      });

      schema = {
        ...schema,
        properties: {
          ...(schema?.properties ?? {}),
          [key]: anyOf.length > 0 ? { anyOf } : ({} as any),
        },
      };
    });
  }

  let values: { [k: string]: string } = {};
  listForms.forEach((form) => {
    values[form.doteID] = form.docuID;
  });
  listCollectDocu.forEach((doc) => {
    values[doc.codoID] = doc.apcdID;
  });

  const data = generateData(schema?.properties, values);
  const ajv = new Ajv();
  const validate = ajv.compile(schema);
  return validate(data);
}

function generateData(properties: any, values: { [k: string]: string }) {
  let data: { [k: string]: any } = {};

  Object.keys(properties ?? {}).forEach((key) => {
    if ((properties[key]?.anyOf ?? []).length > 0) {
      (properties[key].anyOf as any[]).forEach((prop) => {
        data[key] = {
          ...(data[key] ?? {}),
          ...generateData(prop?.properties ?? {}, values),
        };
      });
      return;
    }
    if (!!values[key]) {
      data[key] = values[key];
    }
  });

  return data;
}

export function getAppointmentRequiredAttachments(appointment?: Appointment) {
  const healthplans = appointment?.healthplans ?? [];
  let requiredForms = appointment?.requiredForms ?? [];
  let requiredDocuments = appointment?.requiredDocuments ?? [];

  if ((appointment?.documentTemplateVisibilitys ?? []).length > 0) {
    requiredForms = requiredForms.filter((doteID) => {
      const visibilitys = (
        appointment?.documentTemplateVisibilitys ?? []
      ).filter((v) => v.doteID === doteID);
      if (visibilitys.length === 0) {
        return true;
      }
      return documentShouldBeVisible(visibilitys, healthplans);
    });
  }
  if ((appointment?.collectDocumentVisibilitys ?? []).length > 0) {
    requiredDocuments = requiredDocuments.filter((codoID) => {
      const visibilitys = (
        appointment?.collectDocumentVisibilitys ?? []
      ).filter((v) => v.codoID === codoID);
      if (visibilitys.length === 0) {
        return true;
      }
      return documentShouldBeVisible(visibilitys, healthplans);
    });
  }

  return { requiredForms, requiredDocuments };
}

export function documentShouldBeVisible(
  visibilitys: { heplID: string; visibility: boolean }[],
  healthplans: string[]
) {
  const mustShow = visibilitys.filter((v) => {
    let index = healthplans.indexOf(v.heplID);
    return v.visibility && index >= 0;
  });
  const mustHide = visibilitys.filter((v) => {
    let index = healthplans.indexOf(v.heplID);
    return (!v.visibility && index >= 0) || (index === -1 && v.visibility);
  });
  return mustShow.length > 0 || mustHide.length === 0;
}
