import React from "react";
import Navigator, { NavigatorProps } from "components/UI/Navigator";
import { connect } from "react-redux";
import { getSidebarMenu, MenuView } from "ducks/menu";

export type MenuProps = NavigatorProps & {
  menu: MenuView[];
};
const Menu = (p: MenuProps) => {
  return (
    <Navigator
      {...p}
      categories={p.menu}
      navigationPrefix={``}
      headerTitle={process.env.REACT_APP_APPLICATION_NAME}
    />
  );
};

export default connect(getSidebarMenu, () => ({}))(Menu);
