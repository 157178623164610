import { RootState, AppThunk } from "ducks/state";
import { createSelector } from "reselect";
import { newNotification } from "./notification";
import { hen, Hen } from "@udok/lib/internal/store";
import {
  AppointmentInstruction,
  AppointmentInstructionFilter,
} from "@udok/lib/api/models";
import {
  searchAppointmentInstruction
} from "@udok/lib/api/appointmentInstruction";
import { UNAUTHORIZED } from "./auth";

export type InitialState = {
  appointmentInstructionByID: { [apinID: number]: AppointmentInstruction };
};

// Reducers
const initialState: InitialState = {
  appointmentInstructionByID: {},
};

class AppointmentInstructions extends Hen<InitialState> {
  appointmentInstructionsLoaded(ms: AppointmentInstruction[]) {
    ms.forEach((m) => {
      this.state.appointmentInstructionByID[m.apinID] = m;
    });
  }
}

export const [Reducer, actions] = hen(new AppointmentInstructions(initialState), {
  [UNAUTHORIZED]: () => {
    return initialState;
  },
});

// Selectors
const mainSelector = (state: RootState) => state.appointmentInstruction;

export const getListAppointmentInstruction = createSelector([mainSelector], (state) => {
  return {
    list: Object.keys(state.appointmentInstructionByID)
      .map((apinID) => state.appointmentInstructionByID[Number(apinID)])
  };
});

// Actions
export function loadSearchAppointmentInstructions(
  filter?: AppointmentInstructionFilter
): AppThunk<Promise<void>> {
  return async (dispatch) => {
    return searchAppointmentInstruction(filter)
      .then((r) => {
        dispatch(actions.appointmentInstructionsLoaded(r));
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
        throw e;
      });
  };
}