import * as React from "react";
import { SVGProps } from "react";
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 180 150"
    {...props}
  >
    <style>
      {
        ".a{fill:#f5f5f5}.d{fill:#263238}.e{fill:#ffbf9d}.f{fill:#ff9a6c}.g{fill:#455a64}.i{fill:#ff725e}"
      }
    </style>
    <path
      d="M83.7 142.5c-36.3 0-65.6-29.3-65.6-65.5s29.3-65.5 65.6-65.5 65.6 29.3 65.6 65.5-29.3 65.5-65.6 65.5z"
      className="a"
    />
    <path
      d="M96.7 10.9C95.8 8.4 94 6 91.2 5c-3.7-1.3-8.3.5-10.3 3.6s-1.6 7 .2 10.1c1.9 3.2 5.1 5.6 8.5 7.4l3.2-.4c4.2-3.9 5.7-9.7 3.9-14.8zM128.4 35.8 81.7 25.2c-10 11.1-16.3 25.3-18.1 40 17.7 9.5 42.9 16.7 62.9 18.8 4.3-15.3 1-32.3 1.9-48.2z"
      className="d"
    />
    <path
      d="M84.5 23c6.5-5.9 14.4-8.7 23.2-7.6 4.9.6 6.8 1.9 10.2 3.3 3.4 1.5 6.3 3.8 8 7.1 2 3.9 3.5 8.2 3.4 12.6-.2 4.5-.3 9-1.3 13.4s-2.8 8.7-5.9 11.9l-11.6.4c-6.3-.7-12.7-1.4-18.6-3.7C86 58.1 80.6 54 78 48.2c-3.4-8 .1-19.2 6.5-25.2z"
      className="d"
    />
    <path
      d="m113.7 21.2-3.9-1.1L93.9 24c-2.2-.2-4.2 1.3-4.6 3.5l-4.8 48.1 20.4 9.2 2.2-10.1s10.3.4 14.5-11c2.1-5.7 3.7-15.4 4.8-23.4.9-7.2-3.8-17.9-12.7-19.1z"
      className="e"
    />
    <path
      d="M104.2 58.5c.2-.6.6-1.1 1.1-1.4 1-.7 2.3-.6 3.1.2.9.8 1.2 2.2.9 3.4-.7.7-1.5 1.1-2.5 1h-.4c-.7-.1-1.3-.5-1.8-1-.4-.6-.6-1.5-.4-2.2z"
      className="f"
    />
    <path
      d="M119.4 50.7c-.9 0-1.6-.7-1.6-1.5s.7-1.5 1.6-1.5c.8 0 1.5.7 1.5 1.5s-.7 1.5-1.5 1.5zM123.1 44c-.3.2-1.3-.9-2.9-1.2-1.6-.4-3 .2-3.1 0-.1-.1.2-.4.8-.7.6-.3 1.5-.5 2.5-.3s1.8.8 2.2 1.2c.5.5.6.9.5 1zM110.6 55.4c0-.1 1.1-.1 2.8 0 .4.1.8.1 1-.2.1-.3 0-.8-.1-1.3q-.3-1.6-.6-3.4c-.8-4.9-1.3-8.8-1.1-8.9.2 0 1 3.9 1.8 8.8q.3 1.8.6 3.4c0 .5.2 1.1-.1 1.7-.2.3-.6.4-.8.5-.3 0-.6 0-.8-.1-1.6-.2-2.7-.4-2.7-.5z"
      className="d"
    />
    <path d="M107.1 74.8s-8.1-1.1-15-7.4c0 0 2 9.6 14.1 10.8z" className="f" />
    <path
      d="M105.6 55.6c.3 0-.1 1.8 1.2 3.3 1.3 1.6 3.2 1.8 3.2 2 0 .1-.5.3-1.3.1-.8-.1-1.9-.6-2.7-1.5-.8-1-1-2.1-.9-2.8.1-.8.4-1.2.5-1.1zM123.3 38.1c-.3.4-1.5-.1-3-.3-1.5-.2-2.8 0-3.1-.4-.1-.2.2-.6.8-.9.5-.3 1.5-.5 2.5-.4 1 .2 1.9.6 2.4 1 .4.5.6.9.4 1zM104.9 48.2c-.8 0-1.5-.6-1.5-1.5 0-.8.7-1.4 1.5-1.4.9 0 1.6.6 1.6 1.4 0 .9-.7 1.5-1.6 1.5zM100.8 40.2c.2.2 1.5-.5 3.1-.2 1.7.3 2.8 1.3 3 1.1.1-.1-.1-.4-.5-.9-.5-.5-1.3-1-2.3-1.1-1.1-.2-2 0-2.5.3-.6.4-.8.7-.8.8zM102.6 35.1c.2.4 1.6.2 3.1.4s2.8.7 3.1.4c.2-.2.1-.6-.4-1-.5-.5-1.4-.9-2.5-1.1-1-.1-2 .1-2.6.4-.6.3-.8.7-.7.9z"
      className="d"
    />
    <path
      d="m86.9 44.4 1.7 2.5c10.2-7.3 30.7-26.2 29.5-25.1 0 0-5.8-3.4-12.6-3.2-6.8.2-19.8 1.8-20.7 9.6-1 7.8 2.1 16.2 2.1 16.2z"
      className="d"
    />
    <path
      d="M88.5 47.9c.2-.9-.4-3.1-1.3-3.5-2.2-.8-6.5-1.4-7.7 4.5-1.6 8 6.8 8 6.9 7.8 0-.2 1.5-5.7 2.1-8.8z"
      className="e"
    />
    <path
      d="M84.7 53.3s-.1.1-.4.1c-.2.1-.6.1-1-.2-.8-.5-1.3-2-1-3.4.1-.8.4-1.4.8-1.9.4-.6.8-.9 1.3-.9.4 0 .7.3.7.6.1.2 0 .3.1.4 0 0 .2-.1.2-.5 0-.1-.1-.3-.3-.5-.1-.2-.4-.4-.7-.4-.6-.1-1.3.4-1.7.9-.5.6-.8 1.4-1 2.2-.2 1.6.3 3.3 1.4 3.9.5.2 1 .2 1.3 0 .3-.1.4-.3.3-.3z"
      className="f"
    />
    <path
      d="M75.3 23.3c1 1.8 3.1 2.8 5.1 2.5-2.3 1.5-5.1 2.2-7.8 1.7 2.8 2.7 7.4 3.3 10.9 1.5l3.4-8.1c-2.9 2.8-7.9 3.9-11.6 2.4z"
      className="d"
    />
    <path
      d="m106.5 81.3-21.2-7.7h-5.7l14.1 15.5 6 5.3 7.3-.4 3.1-3.5z"
      className="e"
    />
    <path
      d="M129.3 97.5c-1.7-5.8-9.4-10.2-9.4-10.2l-12.8-6.4s-.9 4.8-9.1 3.2C87.5 82.2 84.7 73 84.7 73s-19.5-.1-23.1.4l-7.4 70 54.9.8 4.5-6.2c2.7-3.6 4.7-7.5 6-11.8.9-2.8 1.3-4.9 1.3-4.9s10.4-.8 13.8-5.5c0 0-4.3-15-5.4-18.3z"
      className="g"
    />
    <path
      d="M119.7 97.6c-.1 0 .3 1.4.7 3.8.5 2.3.9 5.6 1 9.2.1 3.6-.1 6.9-.4 9.3-.3 2.3-.5 3.8-.5 3.8.1 0 .2-.4.3-1 .2-.7.4-1.6.6-2.8.4-2.4.7-5.7.5-9.3-.1-3.7-.6-7-1.1-9.3-.3-1.2-.5-2.1-.7-2.7-.2-.7-.4-1-.4-1zM85.4 133.1l.9-.1q.5-.1 1.1-.3c.4-.1.8-.2 1.3-.4 1-.4 2.1-.9 3.3-1.7 1.2-.7 2.4-1.7 3.5-2.9 1.1-1.1 2-2.4 2.7-3.6.7-1.2 1.2-2.4 1.5-3.4.2-.5.3-1 .4-1.4q.1-.6.1-1.1.1-.8.1-.9c-.1 0-.3 1.3-.9 3.3-.4.9-.9 2.1-1.6 3.2-.7 1.2-1.6 2.4-2.6 3.6-1.1 1.1-2.3 2.1-3.4 2.8-1.2.8-2.3 1.4-3.2 1.8-1.9.8-3.2 1-3.2 1.1z"
      className="d"
    />
    <path
      d="m69.7 73.1-9.1.2q-2.3 0-4.6.8l-22.3 7.7 12.7 12.8 17.1 4z"
      className="g"
    />
    <path
      d="M60.2 99.7c-.1 0-.3-3.5-.3-7.8s.2-7.8.3-7.8c.2 0 .4 3.5.4 7.8s-.2 7.8-.4 7.8z"
      className="d"
    />
    <path
      d="M143.7 144.6H29c-10.1 0-18.3-8.2-18.3-18.3V125c0-10.1 8.2-18.3 18.3-18.3h114.7c10.1 0 18.3 8.2 18.3 18.3v1.3c0 10.1-8.2 18.3-18.3 18.3z"
      style={{
        fill: "#fff",
      }}
    />
    <path
      d="M143.7 144.6h.9c.3-.1.6-.1 1.1-.1q.6-.1 1.5-.3c.5-.1 1.2-.3 1.9-.4l1-.4c.4-.1.7-.3 1.1-.5 1.6-.6 3.2-1.7 4.8-3.2 1.6-1.5 3.1-3.4 4.2-5.8.4-.6.5-1.3.8-1.9.2-.7.4-1.4.5-2.1.1-.7.3-1.4.3-2.1.1-.8.1-1.5.1-2.3 0-.8-.1-1.6-.1-2.4-.2-.8-.2-1.6-.5-2.4-.3-1.6-1-3.2-1.9-4.7-.8-1.5-2-2.9-3.3-4.2-1.4-1.3-3-2.4-4.7-3.2-1.8-.8-3.7-1.4-5.7-1.6-2.1-.2-4.2-.1-6.3-.1H96.6c-20.8 0-43.6 0-67.5.1-3 0-5.9.7-8.5 2.1-2.6 1.3-4.8 3.3-6.5 5.7-1.6 2.4-2.6 5.1-3 7.9q-.2 2.1-.1 4.3v1c0 .4.1.7.2 1.1 0 .3.1.7.1 1l.3 1c1.4 5.4 5.5 9.9 10.5 11.9 2.5 1.1 5.1 1.5 7.8 1.4h7.8c20.8.1 39.5.1 55.2.1h37.2c4.3.1 7.7.1 10.1.1h3.5l-.9.1h-12.7c-8.8 0-21.5 0-37.2.1h-63c-2.7.1-5.4-.3-8-1.4-5-2.1-9.3-6.6-10.7-12.2l-.3-1c0-.4-.1-.7-.1-1.1-.1-.3-.2-.7-.2-1V127q-.2-2.2.1-4.3c.4-2.9 1.4-5.7 3.1-8.1 1.7-2.4 3.9-4.5 6.6-5.9 2.6-1.4 5.6-2.1 8.7-2.2 23.9 0 46.7 0 67.5.1h42.8c2.1 0 4.2-.1 6.3.1 2 .2 4 .8 5.8 1.7 1.8.8 3.4 1.9 4.8 3.2 1.3 1.3 2.5 2.7 3.4 4.3.8 1.5 1.5 3.1 1.9 4.8.2.8.3 1.6.4 2.4.1.8.1 1.6.1 2.4 0 .7 0 1.5-.1 2.3 0 .7-.2 1.5-.3 2.2-.1.7-.3 1.4-.5 2-.3.7-.4 1.4-.8 2-1.1 2.4-2.6 4.4-4.3 5.8-1.6 1.5-3.3 2.6-4.8 3.2-.4.2-.8.4-1.2.5l-1 .4c-.7.1-1.3.3-1.9.4q-.9.2-1.5.2c-.5.1-.8.1-1.1.1h-.9z"
      className="d"
    />
    <path
      d="m35.5 115.5 2.2 4.4c.1.4.5.6.9.7l4.9.7c1 .1 1.4 1.3.6 2l-3.5 3.5c-.3.3-.4.7-.4 1l.9 4.9c.1 1-.9 1.8-1.7 1.3l-4.4-2.3c-.4-.2-.8-.2-1.2 0l-4.4 2.3c-.8.5-1.8-.3-1.7-1.3l.9-4.9c0-.3-.1-.7-.4-1l-3.5-3.5c-.7-.7-.4-1.9.6-2l4.9-.7c.4-.1.8-.3.9-.7l2.2-4.4c.5-.9 1.7-.9 2.2 0zM62 115.5l2.2 4.4c.1.4.5.6.8.7l5 .7c.9.1 1.3 1.3.6 2l-3.5 3.5c-.3.3-.4.7-.4 1l.9 4.9c.1 1-.9 1.8-1.8 1.3l-4.4-2.3c-.3-.2-.7-.2-1.1 0l-4.4 2.3c-.8.5-1.9-.3-1.7-1.3l.9-4.9c0-.3-.1-.7-.4-1l-3.6-3.5c-.7-.7-.3-1.9.7-2l4.9-.7c.4-.1.7-.3.9-.7l2.2-4.4c.5-.9 1.7-.9 2.2 0zM88.6 115.5l2.2 4.4c.2.4.5.6.9.7l5 .7c.9.1 1.3 1.3.6 2l-3.6 3.5c-.2.3-.4.7-.3 1l.8 4.9c.2 1-.8 1.8-1.7 1.3l-4.4-2.3c-.3-.2-.7-.2-1.1 0l-4.4 2.3c-.9.5-1.9-.3-1.7-1.3l.8-4.9c.1-.3 0-.7-.3-1l-3.6-3.5c-.7-.7-.3-1.9.7-2l4.9-.7c.4-.1.7-.3.9-.7l2.2-4.4c.4-.9 1.7-.9 2.1 0zM113 115.5l2.2 4.4c.2.4.5.6.9.7l4.9.7c1 .1 1.4 1.3.7 2l-3.6 3.5c-.3.3-.4.7-.4 1l.9 4.9c.2 1-.9 1.8-1.7 1.3l-4.4-2.3c-.4-.2-.8-.2-1.1 0l-4.5 2.3c-.8.5-1.8-.3-1.7-1.3l.9-4.9c0-.3-.1-.7-.4-1l-3.5-3.5c-.7-.7-.4-1.9.6-2l4.9-.7c.4-.1.8-.3.9-.7l2.2-4.4c.5-.9 1.7-.9 2.2 0z"
      className="i"
    />
    <path
      d="m139.2 116.2 2.2 4.5c.1.3.5.6.9.6l4.9.7c1 .2 1.4 1.4.7 2.1l-3.6 3.4c-.3.3-.4.7-.4 1.1l.9 4.9c.2.9-.9 1.7-1.7 1.2l-4.4-2.3c-.4-.2-.8-.2-1.1 0l-4.5 2.3c-.8.5-1.8-.3-1.7-1.2l.9-4.9c0-.4-.1-.8-.4-1.1l-3.5-3.4c-.7-.7-.4-1.9.6-2.1l4.9-.7c.4 0 .8-.3.9-.6l2.2-4.5c.5-.9 1.7-.9 2.2 0z"
      style={{
        fill: "#e0e0e0",
      }}
    />
    <path
      d="m80.9 111.9 2.8-11.9-38-6.6-11.3-10.7s-9.3 3.4-13.5 8.2c-6.2 7-1 15.2 3.4 16.4 4.3 1.1 56.6 4.6 56.6 4.6z"
      className="e"
    />
    <path
      d="m30.8 91.6.6-.1H33c1.4 0 3.4.1 5.5.3 2.1.3 4 .6 5.4 1q1 .3 1.6.5.6.1.6.2c-.1 0-.9-.2-2.3-.5-1.3-.3-3.2-.6-5.3-.9-2.1-.2-4.1-.3-5.5-.4-1.4 0-2.2 0-2.2-.1z"
      className="f"
    />
    <path
      d="M77.5 99.2c1.9-.2 8.7.9 11.6 2 4.7 1.8 6.6 3.8 10.2 4.4 3.5.5 7.6-.1 7.5 1.8-.1 2-7.7 3-7.7 3s9.3 10.8 8.1 12.3c-.4.5-1.9.9-3-.2-2.5-2.6-5.9-6.2-5.9-6.2s.5 8.6-1.2 10.3c-1.7 1.7-3.3.4-3.3.4s-.9 1.5-2.7 1c-1.2-.3-1.6-1.9-1.6-1.9s-2 .4-2.6-2c-.7-2.2-1.7-9.3-1.7-9.3s-5.7-3.1-5.9-2.8l-7.1-.7-.9-10.5z"
      className="e"
    />
    <path
      d="M94.4 115.2c.1 0 .2.6.2 1.7.1 1.1.2 2.6.1 4.2-.1 1.7-.3 3.1-.5 4.2-.1 1.1-.3 1.7-.4 1.7 0 0 .1-.7.2-1.7.2-1.1.3-2.6.4-4.2.1-1.6.1-3.1 0-4.2v-1.7zM89.4 115.3s.2.6.4 1.6c.1 1 .3 2.3.3 3.9 0 1.5-.1 2.9-.3 3.9-.1.9-.3 1.5-.3 1.5-.1 0 0-.6.1-1.6s.2-2.3.2-3.8-.1-2.9-.3-3.9c-.1-1-.2-1.6-.1-1.6zM96.5 107.2c.1-.1.9.7 1.7 1.7.8 1 1.4 1.9 1.3 1.9-.1.1-.7-.7-1.5-1.7s-1.5-1.8-1.5-1.9z"
      className="f"
    />
    <path
      d="M146.3 114.3c.4-.7.5-1.5.5-2.3.1-.8.2-2.4.4-3.1l.2-5.2c-1.1-2.4-3.8-1.6-4.3-.3-.3-.7-.2-1.1-.8-1.6-.6-.4-1.5-.6-2.2-.4-.7.2-1.3 1-1.2 1.8-.7-1-2-1.5-3.2-1.2-.7.2-1.3.7-1.7 1.3l-.4 1v.4q0 .1.1.2c.1.2.3.5.4.7q.1.2.2.3.3.7.4 1.5v.7c.1.1.2.3.2.4.5.9.4 2 .5 2.9 0 1.1.1 2.2.7 3.1.6.9 1.9 1.4 2.8.7-.1.7.4 1.4 1.1 1.5.7.2 1.4-.2 1.9-.7.4-.5.7-1.1.8-1.8 0 .8.9 1.4 1.7 1.4.8 0 1.5-.6 1.9-1.3z"
      className="e"
    />
    <path
      d="M130.8 103.8c-.7 0-1.4.2-1.8.7-.5.7-.5 1.8-.4 2.6.9.1 1.4.1 2.3.3.2 0 .4-.1.5 0 .5.3.5 1.3.7 1.8.3.9 1.4 1.2 2.3.9.8-.4 1.3-1.3 1.3-2.2 0-.9-.5-1.8-1.1-2.5-1-1-2.4-1.6-3.8-1.6z"
      className="e"
    />
    <path
      d="M138.9 115.2c-.2 0-.3-2.7-.3-6.1 0-3.4.1-6.2.3-6.2.1 0 .3 2.8.3 6.2 0 3.4-.2 6.1-.3 6.1zM143.1 103.3c.2-.1.6 2.4.5 5.6-.1 3.1-.7 5.5-.8 5.5-.2 0 0-2.5.1-5.6.1-3 .1-5.5.2-5.5zM132.2 104.2c0-.1.4-.2 1.1.1.6.2 1.4.9 1.8 1.8.3 1 .5 1.9.5 2.6 0 .7-.6 1-.6.9 0-.1.2-.4.2-.9-.1-.6-.4-1.5-.7-2.3-.4-.8-.9-1.4-1.4-1.8-.5-.3-.9-.3-.9-.4z"
      className="f"
    />
  </svg>
);
export default SvgComponent;
