import React from "react";
import {
  createStyles,
  ThemeProvider,
  withStyles,
  WithStyles,
} from "@material-ui/core/styles";
import { CssBaseline, Hidden, Typography, Link, Grid } from "@material-ui/core";
import Navigator from "./Navigator";
import Header from "./Header";
import theme from "@udok/lib/themes/paperbase";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import {
  HeaderTitleRenderer,
  HeaderTitleContextContainer,
  ChatContextContainer,
} from "@udok/lib/components/UI/UIContext";

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href={process.env.REACT_APP_APPLICATION_URL}>
        {process.env.REACT_APP_APPLICATION_NAME}
      </Link>
      <span>&nbsp;</span>
      <span>{new Date().getFullYear()}.</span>
    </Typography>
  );
}

const drawerWidth = 280;
const rightDrawerWidth = 620;

const styles = createStyles({
  root: {
    display: "flex",
    minHeight: "100vh",
  },
  drawer: {
    [theme.breakpoints.up("xl")]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  app: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
  },
  footer: {
    padding: theme.spacing(2),
    background: "#fff",
  },
  hide: {
    display: "none",
  },
  rightdrawer: {
    width: rightDrawerWidth,
    maxWidth: "100%",
    flexShrink: 0,
  },
  drawerPaper: {
    width: rightDrawerWidth,
    maxWidth: "100%",
    backgroundColor: "#fff",
  },
  content: {
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    height: 80,
  },
  leftItems: {
    width: drawerWidth - theme.spacing(2),
    [theme.breakpoints.down("md")]: {
      width: 120,
    },
    [theme.breakpoints.down(360)]: {
      display: "none",
    },
  },
});

export interface PaperbaseProps extends WithStyles<typeof styles> {
  children?: React.ReactNode;
  headerItems?: React.ReactNode | React.ReactNodeArray;
  toolbarItems?: React.ReactNode | React.ReactNodeArray;
  navigator?: any;
}

function Paperbase(props: PaperbaseProps) {
  const { classes, navigator, headerItems, toolbarItems } = props;
  const theme = useTheme();
  const isSmallerScreen = useMediaQuery(theme.breakpoints.down("sm"), {
    noSsr: true,
  });
  const isMediumScreen = useMediaQuery(theme.breakpoints.between("sm", "md"), {
    noSsr: true,
  });
  const [mobileOpen, setMobileOpen] = React.useState(
    !isMediumScreen && !isSmallerScreen
  );

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const Nav = navigator ?? Navigator;

  return (
    <ThemeProvider theme={theme}>
      <ChatContextContainer>
        <div className={classes.root}>
          <CssBaseline />
          <HeaderTitleContextContainer>
            <Header
              onDrawerToggle={handleDrawerToggle}
              leftItems={
                <Grid item className={classes.leftItems}>
                  {headerItems}
                </Grid>
              }
              rightItems={
                <>
                  <HeaderTitleRenderer />
                  {toolbarItems}
                </>
              }
            />
            <Hidden mdUp implementation="js">
              <Nav
                title=""
                homeLink={{ title: "Página inicial", to: "/" }}
                variant="temporary"
                open={mobileOpen}
                drawerWidth={drawerWidth}
                onClose={handleDrawerToggle}
              />
            </Hidden>
            <Hidden smDown implementation="css">
              <Nav
                title=""
                homeLink={{ title: "Página inicial", to: "/" }}
                drawerWidth={drawerWidth}
                open={mobileOpen}
              />
            </Hidden>
            <main className={classes.content}>
              <div className={classes.toolbar} />
              {props.children}
              <footer className={classes.footer}>
                <Copyright />
              </footer>
            </main>
          </HeaderTitleContextContainer>
        </div>
      </ChatContextContainer>
    </ThemeProvider>
  );
}

export default withStyles(styles)(Paperbase) as React.FC<
  Omit<PaperbaseProps, "classes">
>;
