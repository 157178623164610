import React from "react";

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      id="Capa_1"
      enableBackground="new 0 0 512 512"
      height="512"
      viewBox="0 0 512 512"
      width="512"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g>
        <g>
          <path
            d="m415.982 7.5h-319.965c-3.368 0-6.099 2.731-6.099 6.099v480.808c0 4.848 5.383 7.758 9.439 5.103l20.443-13.38c2.029-1.328 4.652-1.328 6.68 0l26.542 17.373c2.029 1.328 4.652 1.328 6.681 0l26.537-17.372c2.029-1.328 4.652-1.328 6.681 0l26.538 17.372c2.029 1.328 4.652 1.328 6.681 0l26.536-17.372c2.029-1.328 4.652-1.328 6.681 0l26.535 17.371c2.029 1.328 4.652 1.328 6.682 0l26.531-17.371c2.029-1.329 4.653-1.329 6.682 0l26.53 17.37c2.029 1.329 4.653 1.329 6.682 0l26.527-17.37c2.029-1.329 4.653-1.329 6.682 0l20.434 13.378c4.056 2.656 9.44-.255 9.44-5.103v-480.807c-.001-3.368-2.731-6.099-6.1-6.099z"
            fill="#f9f3f1"
          />
        </g>
        <g>
          <path
            d="m422.085 13.598v480.813c0 4.84-5.391 7.751-9.441 5.1l-10.561-6.921v-478.992c0-3.37-2.73-6.101-6.101-6.101h20.002c3.37.001 6.101 2.731 6.101 6.101z"
            fill="#efe2dd"
          />
        </g>
        <g>
          <g>
            <g>
              <path
                d="m304.512 105.709h-22.457c-4.421 0-8.004-3.584-8.004-8.004v-22.458c0-4.421-3.584-8.004-8.004-8.004h-20.093c-4.421 0-8.004 3.584-8.004 8.004v22.457c0 4.421-3.584 8.004-8.004 8.004h-22.457c-4.421 0-8.004 3.584-8.004 8.004v20.093c0 4.421 3.584 8.004 8.004 8.004h22.457c4.421 0 8.004 3.584 8.004 8.004v22.457c0 4.421 3.584 8.004 8.004 8.004h20.093c4.421 0 8.004-3.584 8.004-8.004v-22.457c0-4.421 3.584-8.004 8.004-8.004h22.457c4.421 0 8.004-3.584 8.004-8.004v-20.093c0-4.419-3.583-8.003-8.004-8.003z"
                fill="#ee6161"
              />
            </g>
            <g>
              <path
                d="m261.92 141.806h20.132c-4.42 0-8.001 3.59-8.001 8.011v22.452c0 4.42-3.58 8.011-8.001 8.011h-20.102c4.4-.02 7.961-3.6 7.961-8.011v-22.452c.001-4.421 3.591-8.011 8.011-8.011z"
                fill="#e94444"
              />
            </g>
            <g>
              <path
                d="m282.052 105.713h-20.132c-4.42 0-8.011-3.59-8.011-8.011v-22.452c0-4.411-3.56-7.991-7.961-8.011h20.102c4.42 0 8.001 3.59 8.001 8.011v22.452c0 4.421 3.581 8.011 8.001 8.011z"
                fill="#e94444"
              />
            </g>
            <g>
              <path
                d="m312.515 113.713v20.092c0 4.42-3.58 8.001-8.001 8.001h-20.142c4.42 0 8.011-3.58 8.011-8.001v-20.092c0-4.42-3.59-8.001-8.011-8.001h20.142c4.421.001 8.001 3.581 8.001 8.001z"
                fill="#e94444"
              />
            </g>
          </g>
          <g>
            <path d="m89.918 355.542c4.142 0 7.501-3.358 7.501-7.501v-333.04h241.576c4.142 0 7.501-3.358 7.501-7.501s-3.358-7.5-7.501-7.5h-242.977c-7.499 0-13.6 6.101-13.6 13.6v334.442c0 4.142 3.358 7.5 7.5 7.5z" />
            <path d="m415.982 0h-41.796c-4.142 0-7.501 3.358-7.501 7.501s3.358 7.501 7.501 7.501h40.395v476.813l-18.266-11.959c-4.526-2.962-10.375-2.962-14.898.002l-25.759 16.867-25.761-16.867c-4.526-2.962-10.375-2.963-14.901 0l-25.763 16.868-25.767-16.87c-4.525-2.961-10.373-2.961-14.898 0l-25.768 16.87-25.77-16.87c-4.525-2.961-10.373-2.961-14.898 0l-25.769 16.87-25.776-16.872c-4.525-2.959-10.371-2.958-14.894.001l-18.274 11.961v-108.685c0-4.143-3.358-7.501-7.501-7.501-4.142 0-7.501 3.358-7.501 7.501v111.277c0 5 2.733 9.584 7.131 11.963 4.399 2.379 9.731 2.154 13.917-.584l19.675-12.879 25.773 16.871c2.262 1.481 4.856 2.221 7.449 2.221s5.186-.74 7.449-2.221l25.769-16.87 25.77 16.87c4.525 2.961 10.373 2.961 14.898 0l25.768-16.87 25.767 16.87c4.526 2.962 10.373 2.962 14.899-.001l25.763-16.868 25.761 16.866c4.526 2.965 10.376 2.966 14.901.001l25.76-16.868 19.666 12.876c4.185 2.739 9.517 2.963 13.917.585 4.399-2.378 7.132-6.963 7.132-11.964v-480.806c0-7.499-6.1-13.6-13.6-13.6z" />
            <path d="m266.046 59.743h-20.093c-8.55 0-15.505 6.956-15.505 15.505v22.457c0 .278-.226.504-.503.504h-22.457c-8.55 0-15.505 6.956-15.505 15.504v20.093c0 8.55 6.956 15.505 15.505 15.505h22.457c.277 0 .503.226.503.504v22.457c0 8.55 6.955 15.504 15.505 15.504h20.093c8.55 0 15.505-6.955 15.505-15.504v-22.457c0-.278.226-.504.503-.504h22.458c8.55 0 15.505-6.956 15.505-15.505v-20.093c0-8.549-6.955-15.504-15.505-15.504h-22.458c-.278 0-.503-.226-.503-.504v-22.457c0-8.55-6.955-15.505-15.505-15.505zm16.009 53.467h22.458c.278 0 .503.226.503.503v20.093c0 .278-.226.504-.503.504h-22.458c-8.55 0-15.505 6.956-15.505 15.505v22.457c0 .277-.226.503-.503.503h-20.093c-.278 0-.503-.226-.503-.503v-22.457c0-8.55-6.955-15.505-15.505-15.505h-22.457c-.278 0-.504-.226-.504-.504v-20.093c0-.277.226-.503.504-.503h22.457c8.55 0 15.505-6.956 15.505-15.505v-22.457c0-.278.226-.504.503-.504h20.093c.278 0 .503.226.503.504v22.457c0 8.55 6.955 15.505 15.505 15.505z" />
            <path d="m298.315 436.519c2.362 4.929 7.177 7.565 13.211 7.22 4.202-.237 7.335-2.356 9.846-4.395 2.356-1.915 4.59-3.99 6.684-6.203 4.139 5.59 11.356 8.911 19.01 8.397 6.865-.462 13.682-3.799 19.715-9.65 2.973-2.884 3.046-7.633.162-10.606s-7.632-3.047-10.606-.162c-3.4 3.298-7.05 5.234-10.28 5.451-2.734.186-5.662-1.261-6.391-3.157-.875-2.276.274-6.279 1.113-9.202 1.074-3.743-.894-7.685-4.531-9.076-3.636-1.39-7.733.233-9.43 3.738-3.506 7.243-8.661 13.753-14.907 18.827-.02.017-.039.032-.058.048.238-2.167 1.059-5.785 3.245-11.128 2.775-6.782 5.989-12.063 6.778-12.996 2.773-3.077 2.526-7.82-.551-10.593-3.078-2.773-7.82-2.527-10.598.556-8.969 9.951-18.637 19.272-28.904 27.876 7.984-17.708 16.424-35.268 25.219-52.463 1.886-3.688.426-8.207-3.263-10.094-3.688-1.883-8.207-.427-10.093 3.263-13.287 25.978-25.777 52.784-37.124 79.672-2.059 4.879 1.645 10.418 6.911 10.418 1.472 0 2.948-.433 4.227-1.305 10.157-6.932 19.887-14.484 29.123-22.599-.075 3 .355 5.79 1.492 8.163z" />
            <g>
              <g>
                <g>
                  <path d="m272.772 242.872h-124.127c-4.142 0-7.5-3.357-7.5-7.5s3.358-7.5 7.5-7.5h124.127c4.142 0 7.5 3.357 7.5 7.5s-3.357 7.5-7.5 7.5z" />
                </g>
                <g>
                  <path d="m363.352 242.872h-26.838c-4.142 0-7.5-3.357-7.5-7.5s3.358-7.5 7.5-7.5h26.838c4.142 0 7.5 3.357 7.5 7.5s-3.358 7.5-7.5 7.5z" />
                </g>
              </g>
              <g>
                <g>
                  <path d="m221.309 286.58h-72.664c-4.142 0-7.5-3.357-7.5-7.5s3.358-7.5 7.5-7.5h72.664c4.142 0 7.5 3.357 7.5 7.5s-3.358 7.5-7.5 7.5z" />
                </g>
                <g>
                  <path d="m363.352 286.58h-26.838c-4.142 0-7.5-3.357-7.5-7.5s3.358-7.5 7.5-7.5h26.838c4.142 0 7.5 3.357 7.5 7.5s-3.358 7.5-7.5 7.5z" />
                </g>
              </g>
              <g>
                <g>
                  <path d="m272.772 330.288h-124.127c-4.142 0-7.5-3.357-7.5-7.5s3.358-7.5 7.5-7.5h124.127c4.142 0 7.5 3.357 7.5 7.5s-3.357 7.5-7.5 7.5z" />
                </g>
                <g>
                  <path d="m363.352 330.288h-26.838c-4.142 0-7.5-3.357-7.5-7.5s3.358-7.5 7.5-7.5h26.838c4.142 0 7.5 3.357 7.5 7.5s-3.358 7.5-7.5 7.5z" />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default SvgComponent;
