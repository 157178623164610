import React from "react";

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      id="Filled_stroke_cut_Ex"
      height="512"
      viewBox="0 0 512 512"
      width="512"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g>
        <rect fill="#8d9ca8" height="464" rx="10" width="336" x="136" y="24" />
        <path d="m168 64h272v384h-272z" fill="#f9f3f1" />
        <circle cx="304" cy="168" fill="#fdbf44" r="48" />
        <path
          d="m216 24h176a0 0 0 0 1 0 0v54a10 10 0 0 1 -10 10h-156a10 10 0 0 1 -10-10v-54a0 0 0 0 1 0 0z"
          fill="#fdbf44"
        />
      </g>
      <g>
        <rect fill="#fdbf44" height="64" rx="10" width="176" x="40" y="200" />
        <path
          d="m216 488h-166a10 10 0 0 1 -10-10v-166l16-16v-32h144v32l16 16z"
          fill="#ee6161"
        />
        <path
          d="m176 376h-32v-32h-32v32h-32v32h32v32h32v-32h32z"
          fill="#fffcfc"
        />
      </g>
      <g>
        <circle cx="248" cy="440" fill="#fdbf44" r="48" />
        <g>
          <path d="m464 478a2 2 0 0 1 -2 2h-158v16h158a18.021 18.021 0 0 0 18-18v-341.837h-16z" />
          <path d="m462 16h-316a18.021 18.021 0 0 0 -18 18v142h16v-142a2 2 0 0 1 2-2h62v24h-40a8 8 0 0 0 -8 8v112h16v-104h32v6a18.021 18.021 0 0 0 18 18h156a18.021 18.021 0 0 0 18-18v-6h32v368h-112v16h120a8 8 0 0 0 8-8v-384a8 8 0 0 0 -8-8h-40v-24h62a2 2 0 0 1 2 2v86h16v-86a18.021 18.021 0 0 0 -18-18zm-78 62a2 2 0 0 1 -2 2h-156a2 2 0 0 1 -2-2v-46h160z" />
          <path d="m360 168a56 56 0 1 0 -56 56 56.064 56.064 0 0 0 56-56zm-96 0a40 40 0 1 1 40 40 40.045 40.045 0 0 1 -40-40z" />
          <path d="m312 192v-16h16v-16h-16v-16h-16v16h-16v16h16v16z" />
          <path d="m240 256h120v16h-120z" />
          <path d="m376 256h40v16h-40z" />
          <path d="m256 336h104v16h-104z" />
          <path d="m376 336h40v16h-40z" />
          <path d="m240 296h48v16h-48z" />
          <path d="m304 296h112v16h-112z" />
          <path d="m240 48h16v16h-16z" />
          <path d="m352 48h16v16h-16z" />
          <path d="m272 48h64v16h-64z" />
          <path d="m304 376h112v16h-112z" />
          <path d="m144 336h-32a8 8 0 0 0 -8 8v24h-24a8 8 0 0 0 -8 8v32a8 8 0 0 0 8 8h24v24a8 8 0 0 0 8 8h32a8 8 0 0 0 8-8v-24h24a8 8 0 0 0 8-8v-32a8 8 0 0 0 -8-8h-24v-24a8 8 0 0 0 -8-8zm24 48v16h-24a8 8 0 0 0 -8 8v24h-16v-24a8 8 0 0 0 -8-8h-24v-16h24a8 8 0 0 0 8-8v-24h16v24a8 8 0 0 0 8 8z" />
          <path d="m248 384a55.661 55.661 0 0 0 -24 5.408v-77.408a8 8 0 0 0 -2.343-5.657l-13.657-13.656v-20.8a18.025 18.025 0 0 0 16-17.887v-44a18.021 18.021 0 0 0 -18-18h-156a18.021 18.021 0 0 0 -18 18v44a18.025 18.025 0 0 0 16 17.889v20.8l-13.657 13.654a8 8 0 0 0 -2.343 5.657v40h16v-36.687l11.313-11.313h108.687v-16h-104v-16h128v24a8 8 0 0 0 2.343 5.657l13.657 13.656v85.533a55.91 55.91 0 0 0 .846 79.154h-158.846a2 2 0 0 1 -2-2v-110h-16v110a18.021 18.021 0 0 0 18 18h198a56 56 0 0 0 0-112zm-42-128h-156a2 2 0 0 1 -2-2v-44a2 2 0 0 1 2-2h156a2 2 0 0 1 2 2v44a2 2 0 0 1 -2 2zm42 224a39.983 39.983 0 0 1 -26.515-69.925l39.184 67.869a39.85 39.85 0 0 1 -12.669 2.056zm26.515-10.075-39.184-67.869a39.989 39.989 0 0 1 39.184 67.869z" />
        </g>
      </g>
    </svg>
  );
}

export default SvgComponent;
