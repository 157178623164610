import React from "react";

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      version="1.1"
      id="Capa_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 496 496"
      style={
        {
          enableBackground: "new 0 0 496 496",
        } as any
      }
      {...props}
    >
      <g>
        <rect
          x="144"
          y="296"
          style={{ fill: "#F0BC5E" }}
          width="208"
          height="168"
        />
        <polygon
          style={{ fill: "#F0BC5E" }}
          points="192,90.664 128,176 160,176 160,272 224,272 224,176 256,176 	"
        />
      </g>
      <rect x="240" y="256" width="16" height="16" />
      <rect x="272" y="256" width="16" height="16" />
      <rect x="144" y="288" width="208" height="16" />
      <rect x="144" y="320" width="208" height="16" />
      <rect x="144" y="352" width="208" height="16" />
      <rect x="144" y="384" width="208" height="16" />
      <rect x="144" y="416" width="24" height="16" />
      <rect x="184" y="416" width="168" height="16" />
      <rect x="144" y="448" width="176" height="16" />
      <rect x="336" y="448" width="16" height="16" />
      <path d="M296,32v16c28.192,0,52.08,21.064,55.568,48.992l15.872-1.976C362.96,59.088,332.248,32,296,32z" />
      <path d="M56,192h16c0-17.648,14.352-32,32-32v-16C77.528,144,56,165.528,56,192z" />
      <path
        d="M399.792,97.416C396.384,43.128,351.136,0,296,0c-35.248,0-67.936,17.88-87.064,47.144C198.6,42.456,187.344,40,176,40
	c-41.536,0-75.784,31.824-79.64,72.36C55.824,116.216,24,150.464,24,192c0,44.112,35.888,80,80,80h8v224h272V272
	c48.52,0,88-39.48,88-88C472,140.944,441.424,104.888,399.792,97.416z M320,219.312L356.688,256H320V219.312z M384,256h-4.688
	l-64-64H224v16h80v64h64v208H128V208h32v-16h-48v64h-8c-35.288,0-64-28.712-64-64s28.712-64,64-64h8v-8c0-35.288,28.712-64,64-64
	c11.16,0,22.2,2.976,31.928,8.6l7.064,4.08l3.944-7.144C234.448,33.448,263.984,16,296,16c48.52,0,88,39.48,88.008,87.6
	l-0.032,8.048l7.208,0.712C428.136,116.024,456,146.824,456,184C456,223.696,423.696,256,384,256z"
      />
    </svg>
  );
}

export default SvgComponent;
