import * as React from "react";
import { SVGProps } from "react";

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    style={{ isolation: "isolate" }}
    viewBox="0 0 940 870"
    width="1em"
    height="1em"
    {...props}
  >
    <defs>
      <clipPath id="a">
        <path d="M0 0h940v870H0z" />
      </clipPath>
    </defs>
    <g clipPath="url(#a)">
      <path
        d="M297.491 35.251C409.5-14.015 536.241-1.411 643.309 53.37c76.357 39.125 145.908 101.171 184.255 186.785 38.347 85.615 40.814 196.587-6.113 279.68-17.752 31.489-43.424 59.112-74.848 67.742-50.01 13.565-97.353-23.406-137.3-58.602-39.923-35.01-87.128-72.199-137.256-58.897-38.447 10.312-66.883 47.932-99.907 73.486-56.278 43.725-130.91 52.449-191.484 22.497-8.457-4.14-16.432-9.045-24.249-14.649-60.132-43.018-92.565-122.937-86.083-205.818l.698-9.872C91.881 200.169 185.388 84.439 297.491 35.251Z"
        fill="#ECF6FF"
      />
      <path
        d="M281.4 490.75c0 .2-16 .5-35.6.5-19.7 0-35.6-.3-35.6-.5 0-.3 15.9-.5 35.6-.5 19.6 0 35.6.2 35.6.5Z"
        fill="#E3E3EE"
      />
      <path
        d="m303.8 462.35 166.1 31 162.5-31 160.7 40.5 144 362-269-51.3-201.2 44-196.4-44-267.8 44 142.8-354.7 158.3-40.5Z"
        fill="#FFF"
      />
      <path
        d="m145.5 502.85 2.6-.7q2.7-.7 7.9-2.1c6.9-1.7 17.2-4.4 30.6-7.9 26.9-6.9 66.5-17 117.2-30v-.1h.1c45.8 8.6 101.9 19 166.1 30.9h-.2c48.7-9.3 103.5-19.7 162.5-31h.2c49.9 12.6 104 26.2 160.7 40.4l.2.1.1.2c44 110.6 92.7 232.9 144.1 362l.3.9-.9-.2c-44.2-8.5-91-17.4-138.4-26.4-44.1-8.4-87.7-16.8-130.6-24.9h.2c-69.1 15.1-136.4 29.8-201.2 44h-.2c-68.4-15.4-134.1-30.1-196.4-44.1h.2c-98.4 16.2-188.5 31-267.9 44l-.6.1.2-.6c44.2-109.6 80-198.2 104.7-259.5 12.4-30.7 22-54.4 28.5-70.6 3.3-8 5.8-14.2 7.5-18.3 1.6-4.1 2.5-6.2 2.5-6.2s-.8 2.1-2.5 6.3c-1.6 4.1-4.1 10.3-7.3 18.4-6.5 16.1-16.1 40-28.4 70.6-24.6 61.4-60.2 150-104.3 259.5l-.4-.5c79.4-13 169.4-27.9 267.8-44.1h.2c62.3 14 128 28.7 196.4 44h-.2c64.8-14.2 132.1-28.9 201.2-44l.1-.1h.1c42.9 8.2 86.4 16.5 130.6 25 47.4 9 94.2 17.9 138.4 26.4l-.6.7c-51.3-129.2-100-251.5-144-362l.4.2c-56.8-14.3-110.8-27.9-160.7-40.5h.2c-59 11.2-113.9 21.7-162.5 30.9h-.2c-64.1-12-120.3-22.5-166-31h.1c-50.7 12.9-90.2 22.9-117.1 29.8-13.5 3.4-23.8 6-30.7 7.8q-5.2 1.3-7.9 1.9l-2.7.7Z"
        fill="#263238"
      />
      <path
        d="m301.5 475.45 168.4 30.9 164.9-30.9 149.9 36.9 129.8 334.5-246.4-47.6-198.2 47.6-200.6-46.4-241.6 39.2 126.2-327.3 147.6-36.9Z"
        fill="#FFF"
      />
      <path
        d="m141 545.65 76.8 65.1 24.8 96.7-15.9 10 7.1 41.4-192.1 44.5 99.3-257.7Z"
        fill="#0345B7"
      />
      <path
        d="m27.7 839.65 9.3-24.2 322.1-74.9 17.5 48.2-5.7 9.7-60.5 11.5-41.1-9.5-241.6 39.2ZM555.4 691.95l34.5 52-110.7 32.3-52.8 23.4-37.1-16.1-12.7-47.6 178.8-44Z"
        fill="#E3E3EE"
      />
      <path
        d="m240.6 720.45 11.6-8.2 43.6-10.5 12 38.8-59.9 13-7.3-33.1Z"
        fill="#D2D1DB"
      />
      <path
        d="m360 644.45-74.7 16.7 6.1 27.4-34 7.5-21.7-71.3 124.3 19.7Z"
        fill="#0345B7"
      />
      <path
        d="m153.9 512.35 147.6-36.9 136.2 25 60.8 96.6-133.1 33.1-124.8-23.7-93.9-75.4 7.2-18.7ZM469.9 506.35l9.3-1.7 188.9 294.6-17.1 4.1-195.1-299.5 14 2.5Z"
        fill="#E3E3EE"
      />
      <path
        d="m501.4 615.35 40.6 69.2-219.4 56-24.8-71.9 203.6-53.3ZM419.9 835.25l2.2-3.2 14.4-21.4 34.6-11 23.8-17.9 103.1-28.2 32.1 54.8-160.2 38.5M634.8 475.45l149.9 36.9-130.4 26.6-51.6-57.5 32.1-6ZM812.2 583.05l-95.8 26.7-48.3-64.1 119.9-24.9 24.2 62.3Z"
        fill="#D2D1DB"
      />
      <path
        d="m589.9 483.85 115.9 129.8-115.9 33.3-100.4-144.2 100.4-18.9Z"
        fill="#E3E3EE"
      />
      <path d="m770.2 818.95 115.9-45.4 28.4 73.3-144.3-27.9Z" fill="#D2D1DB" />
      <path
        d="m649 638.35 63-16.4 66.5 79.8-64.7 21.4-64.8-84.8Z"
        fill="#0345B7"
      />
      <path
        d="m814.3 588.55 34.7 89.4-52.8 17.7-70-80.3 88.1-26.8Z"
        fill="#D2D1DB"
      />
      <path
        d="m778.5 800.25-52.3-72.6 126.4-40.3 25.7 66.2-16.5 16.6-83.3 30.1Z"
        fill="#0345B7"
      />
      <path
        d="m640.7 644.05 121.8 166.2-11.1 5.1-59.2-12-96.2-145.8 44.7-13.5ZM41.7 803.35l52-135 84.5-14.8 23.9 112.6-160.4 37.2Z"
        fill="#D2D1DB"
      />
      <path
        d="M279.8 480.85v51.6l18 2.3 50.5 58.8 43.4-5 20.7 29.9-47 11.7-124.8-23.7-93.9-75.4 7.2-18.7 125.9-31.5Z"
        fill="#0345B7"
      />
      <path
        d="m589.9 483.85 85.8 96.1-61.1 16.5-70.9-103.5 46.2-9.1Z"
        fill="#D2D1DB"
      />
      <path
        d="m751.8 599.85-25.6-40.1 69.3-19.6 16.7 42.9-60.4 16.8ZM649 737.85l33.6-11.6 65.1 88.3-55.5-11.2-43.2-65.5Z"
        fill="#E3E3EE"
      />
      <path
        d="m339.3 816.65 31.2-3.8 10.4-15.3 39 12.3-10.2 23.1-70.4-16.3Z"
        fill="#0345B7"
      />
      <path
        d="M153.9 512.35s3.2-.9 9.7-2.5c6.4-1.6 16-4 28.5-7.2 25.1-6.3 62.1-15.6 109.3-27.5h.1c46 8.5 103 18.9 168.5 30.9h-.2c49.3-9.3 105-19.7 164.9-31h.2c46.8 11.5 97.2 23.9 150 36.8l.2.1.1.2c39.7 102.4 83.6 215.4 129.8 334.5l.3.9-.9-.2c-39.5-7.6-81.3-15.7-123.6-23.9-41.5-8-82.5-15.9-122.8-23.7h.2c-68.2 16.4-134.5 32.3-198.2 47.6h-.2c-70.3-16.3-137.4-31.8-200.6-46.5h.2c-88.8 14.4-169.9 27.6-241.7 39.2l-.6.1.2-.6c39.1-101.1 70.7-182.9 92.5-239.5 11-28.2 19.5-50.2 25.2-65.1 2.9-7.4 5.1-13.1 6.6-16.9 1.5-3.8 2.3-5.7 2.3-5.7s-.8 2-2.2 5.8c-1.5 3.8-3.7 9.5-6.5 17-5.7 14.9-14.2 36.9-25 65.2-21.8 56.5-53.3 138.4-92.2 239.5l-.4-.5c71.7-11.7 152.9-24.9 241.6-39.4h.2c63.3 14.6 130.3 30.1 200.6 46.4h-.2c63.7-15.3 130-31.2 198.2-47.6l.1-.1.1.1c40.3 7.8 81.3 15.7 122.8 23.7 42.3 8.2 84.1 16.2 123.6 23.9l-.6.7c-46.2-119.1-90-232.2-129.7-334.5l.3.3c-52.7-13-103.1-25.4-149.9-37h.1c-59.9 11.2-115.5 21.7-164.8 30.9h-.2c-65.4-12.1-122.4-22.6-168.4-31h.1c-47.2 11.7-84.1 20.9-109.2 27.1-12.6 3.1-22.2 5.5-28.6 7.1-6.5 1.6-9.8 2.4-9.8 2.4Z"
        fill="#263238"
      />
      <path
        d="m270.5 813.55 33.3-351.2 166.1 31-3 364.2-196.4-44ZM793.1 502.85l144 362-269-51.3-35.7-351.2 160.7 40.5Z"
        opacity={0.2}
      />
      <path
        d="M695 367.25c0 49.7-18.1 91.9-44.6 132.3l-140 208c-12.8 19.1-40.7 19.7-54.3 1.2l-154.8-210.6q-.2-.2-.3-.4l-2.2-2.9h.1c-26.8-37.3-42.3-83.3-41.1-133 2.8-116 97.4-210.5 213.4-213.2 123.1-2.9 223.8 96.1 223.8 218.6Zm-54.3-5.4c0-90.8-73.5-164.4-164.3-164.4-90.8 0-164.4 73.6-164.4 164.4 0 90.8 73.6 164.4 164.4 164.4 90.8 0 164.3-73.6 164.3-164.4Z"
        fillRule="evenodd"
        fill="#FF725E"
      />
      <path
        d="M695 367.25c0 49.7-18.1 91.9-44.6 132.3l-140 208c-12.8 19.1-40.7 19.7-54.3 1.2l-154.8-210.6q-.2-.2-.3-.4l-2.2-2.9h.1c-26.8-37.3-42.3-83.3-41.1-133 2.8-116 97.4-210.5 213.4-213.2 123.1-2.9 223.8 96.1 223.8 218.6Zm-54.3-5.4c0-90.8-73.5-164.4-164.3-164.4-90.8 0-164.4 73.6-164.4 164.4 0 90.8 73.6 164.4 164.4 164.4 90.8 0 164.3-73.6 164.3-164.4Z"
        fillRule="evenodd"
        opacity={0.3}
      />
      <path
        d="M682.1 367.25c0 49.7-18.2 91.9-44.7 132.3l-139.9 208c-12.9 19.1-40.7 19.7-54.4 1.2l-154.8-210.6q-.1-.2-.3-.4l-2.1-2.9c-26.8-37.3-42.2-83.3-41.1-133 2.8-116 97.4-210.5 213.4-213.2 123.1-2.9 223.9 96.1 223.9 218.6Zm-54.3-5.4c0-90.8-73.6-164.4-164.4-164.4-90.8 0-164.4 73.6-164.4 164.4 0 90.8 73.6 164.4 164.4 164.4 90.8 0 164.4-73.6 164.4-164.4Z"
        fillRule="evenodd"
        fill="#FF725E"
      />
      <path
        d="M550.3 558.95c-15.5 12.1-35.9 15.6-55.6 16.7-34.7 2-75.5-6.8-108.8-17 2.6-3 176.4-48.9 179.1-51.8 9.4 17.2.8 40-14.7 52.1Z"
        opacity={0.3}
      />
      <path
        d="M483.4 283.55c-17.8 17.2-44.1 25.4-68.6 21.2-5.2-.8-10.5-2.3-14.2-5.9-3.8-3.6-5.6-9.7-2.8-14.2q-3.6 6.3-7.2 12.5c-11.6.1-23.3-3.1-33.2-9.2 7.7-.1 14.8-6.7 15.5-14.4.7-8.3-4.9-17.2-.7-24.3 3.5-6.1 12.4-7.1 16.2-13.1 3.8-5.9.7-13.7-.8-20.6-3.1-14.2 1.3-29.9 11.4-40.5"
        fill="#263238"
      />
      <path
        d="M529.6 271.55c-8.9 11.3-40.4 21.9-54.4 18.7l-75-73.1c-9.1-5.5-13.5-17.7-10-27.7 1.7-4.7 4.9-8.9 5.8-13.8.8-4.5-.4-9.1-.3-13.7.2-9.3 6-18 13.7-23.2 7.8-5.3 17.2-7.5 26.6-8.2 9-.7 18.8.1 26.1 5.6 5.1 3.8 8.6 9.5 13.7 13.4 7 5.1 16.2 6.1 24.8 7.4 8.6 1.4 17.8 3.9 23 11 7.2 9.8 3.3 24 7.5 35.5 2 5.4 5.6 9.9 8.1 15 6.4 13-.7 41.8-9.6 53.1Z"
        fill="#263238"
      />
      <path
        d="M400.4 206.45c0-18.9 11.1-42.9 30-42.1l59.8 11.2c5.8.2 10.4 4.8 10.6 10.6l2.6 142.3q.2 0 .4.1s-10.8 40.9-50.4 31.2c-7.9-2-12-10.2-14.1-18.7h-.4c-.1-1-.1-2-.2-2.9-1.9-10-1.5-19.5-1.5-19.5-.9-11.5-1.1-16.2-1.1-16.1 0 0-24.5-2.4-31.4-33.7-3.5-15.5-4.3-41.3-4.3-62.4Z"
        fill="#FFBE9D"
      />
      <path
        d="M459.1 222.05c2.2 0 4.1 1.7 4.1 3.8.1 2.2-1.7 3.9-3.9 3.9-2.2.1-4.1-1.7-4.2-3.8 0-2.1 1.8-3.9 4-3.9ZM417.8 225.25c2.2-.1 4.1 1.6 4.1 3.8.1 2.1-1.7 3.9-3.9 3.9s-4.1-1.7-4.2-3.8c0-2.2 1.7-3.9 4-3.9ZM406.9 217.35c1.5-1.3 4-2.6 6.9-2.6 3 0 5.5 1.3 7 2.6s2 2.5 1.8 2.8c-.6.6-4-2.2-8.7-2.2-4.8 0-8.2 2.9-8.7 2.2-.3-.3.2-1.5 1.7-2.8ZM450.6 216.15c1.4-1.4 4-2.9 7.1-3.2 3.2-.4 6 .6 7.7 1.7 1.7 1.1 2.4 2.2 2.2 2.5-.5.7-4.5-1.7-9.5-1.1-5.1.5-8.3 3.6-9 3-.3-.2.1-1.5 1.5-2.9ZM428.7 251.35c-.6 0-1.2 0-1.9-.1-.8-.2-1.6-.7-2-1.5-.6-1.7 0-3.2.3-4.4q1.3-4.2 2.7-8.8c3.8-12.3 7.3-22.2 7.8-22 .5.1-2.2 10.2-6 22.6q-1.5 4.5-2.8 8.6c-.5 1.4-.8 2.6-.5 3.4.2.7 1.3.9 2.4.9 4.4.3 7.1.6 7.1.9 0 .2-2.7.4-7.1.4Z"
        fill="#263238"
      />
      <path
        d="M436.1 302.55s21.3.1 41.5-13.9c0 0-8.9 22.3-40.7 21.4l-.8-7.5Z"
        fill="#EB996E"
      />
      <path
        d="M438.3 261.25c1.5-2.1 4.1-3.4 6.9-3.3 1.9.1 3.8.9 5.1 2.3 1.2 1.5 1.6 3.7.7 5.2-1.1 1.7-3.5 2.3-5.7 1.8-2.1-.5-3.9-1.7-5.7-3-.5-.3-1-.7-1.3-1.2-.4-.5-.5-1.2-.1-1.6"
        fill="#FF725E"
      />
      <path
        d="M449.7 256.25c0 1.9-.9 4.7-3.3 6.9-2.3 2.2-5.3 3.1-7.4 3.1-2.1.1-3.4-.4-3.3-.7 0-.7 5.1-.5 8.9-4.1 3.8-3.6 3.5-8.3 4.2-8.3.4-.1 1 1.1.9 3.1ZM450 201.95c1.8-.9 4.5-1.5 7.6-1.4 3.1.2 5.8 1.1 7.5 2.1 1.8 1.1 2.6 2.1 2.3 2.7-.6 1.1-4.8-.2-10-.4-5.1-.3-9.4.6-9.9-.6-.2-.5.6-1.5 2.5-2.4ZM409 204.25c1.4-1.1 3.8-2.2 6.6-2.4 2.8-.3 5.4.3 6.9 1.1 1.6.9 2.2 1.8 1.9 2.4-.7 1.1-4.3.4-8.4.9-4.1.3-7.5 1.5-8.4.6-.4-.5 0-1.5 1.4-2.6Z"
        fill="#263238"
      />
      <path
        d="M508.3 194.15q3.3 40.6 6.6 81.1c-6.7.8-13.7-.8-19.3-4.8-7-5-11.3-13.4-11.3-22-.1-7.6 3-15.7-.5-22.5-4.6-8.9-18.1-10.1-22.8-18.9-2.7-5.2-1.7-11.8-4.7-16.8-2.8-4.7-8.4-6.9-13.8-8.1-5.3-1.2-10.9-1.7-15.7-4.5-4.7-2.8-8.2-8.8-6-13.8 23-.2 46.1 2.4 68.5 7.6 5.2 1.2 10.7 2.8 14.2 6.8 3.7 4.2 4.3 10.3 4.8 15.9Z"
        fill="#263238"
      />
      <path
        d="M573.9 252.65c-.9 2.2-2.9 3.9-4.8 5.4q-9.3 7.2-18.5 14.5c-17.4 13.7-36.3 28.1-58.4 29.4 4.4-4.2 5-11.3 3.3-17.2-1.6-5.9-5.2-11.1-8.1-16.5-5.2-9.9-3.1-19.8-2.2-38.1l29.1-58.2c4.6-1.2 11.7 1.9 13.4 6.3 1.7 4.5-.8 9.6 2.7 14.4 4.4 6.3 15.3 4.4 20 10.4 5.1 6.7 0 16.9 3.6 24.4 2.5 5.2 8.2 7.8 13 11 4.8 3.2 9.2 8.9 6.9 14.2Z"
        fill="#263238"
      />
      <path
        d="M550.4 267.15s-.7.4-2.2.7c-1.6.4-3.9.8-6.9.5-3-.3-6.7-1.3-10.3-3.8-3.5-2.4-6.7-6.6-8-11.9-.7-2.6-.9-5.5-.5-8.5.5-3 1.7-5.8 2.9-8.7 1.3-2.9 2.7-5.8 3.1-9 .3-3.2-.2-6.8-2.5-9.2-2.4-2.3-6.2-2.5-9.8-3.4-1.8-.4-3.8-1.2-5.2-2.8-1.3-1.5-2-3.5-2.3-5.5-.5-4 .7-7.9.9-11.8.1-1.9 0-3.8-.7-5.6-.7-1.7-2.1-3.1-3.8-4-3.5-1.8-7.6-1.6-11.5-1.4-3.9.3-8.1 0-11.7-1.7-3.7-1.6-6.8-4.2-8.9-7.3-2.2-3.2-3.3-6.7-4.4-10.1-1-3.4-1.9-6.7-3.5-9.5-1.6-2.9-3.8-5.2-6.2-7.1-5-3.6-10.9-5-16.1-4.7-5.3.3-9.8 2.3-13.2 4.7-3.4 2.4-5.9 5.2-7.5 7.6-3.4 4.9-4 8.3-4.2 8.2l.1-.6c.1-.4.3-1 .6-1.7.5-1.5 1.4-3.6 3-6.2 1.7-2.5 4.1-5.3 7.6-7.9 3.4-2.5 8-4.7 13.5-5 5.4-.4 11.5 1.1 16.8 4.8 2.6 1.8 4.9 4.3 6.6 7.3 1.7 3 2.6 6.4 3.7 9.8 1 3.3 2.1 6.7 4.2 9.7 2 2.9 4.9 5.3 8.4 6.8 3.4 1.6 7.2 1.9 11.1 1.7 3.9-.2 8.3-.5 12.2 1.5 1.9 1 3.5 2.6 4.4 4.6.8 2 .9 4.2.8 6.2-.2 4.1-1.4 7.8-.9 11.5.2 1.9.8 3.6 2 4.9 1.1 1.3 2.8 2 4.5 2.4 1.7.5 3.6.7 5.4 1.2 1.7.5 3.6 1.2 4.9 2.6 2.7 2.8 3.2 6.8 2.8 10.1-.5 3.5-1.9 6.5-3.2 9.3-1.3 2.9-2.5 5.7-2.9 8.5-.4 2.8-.3 5.5.4 8.1 1.2 5.1 4.2 9.1 7.6 11.5 3.3 2.5 6.9 3.5 9.8 3.9 5.9.7 9.1-1 9.1-.7Z"
        fill="#0345B7"
      />
      <path
        d="M574.3 225.25s.6.5 1 1.7c.5 1.2.7 3.3-.2 5.6-.4 1.1-1.2 2.3-2.3 3.3-1.2 1-2.8 1.7-4.6 1.9-3.4.6-7.8-1-10.4-4.7-1.3-1.8-2.2-4.1-2.5-6.5-.3-2.4 0-4.9.5-7.4 1.1-5 3.6-10.1 2.9-15.6-.3-2.7-1.3-5.5-3.6-7.2-2.2-1.7-5.3-1.7-8.3-1.4-3 .4-6.2 1.2-9.6.4-1.7-.4-3.3-1.4-4.4-2.7-1.1-1.4-1.8-3-2.3-4.7-.8-3.3-.5-6.7-.5-9.8 0-3.2-.6-6.2-1.8-9-2.4-5.5-6.9-9.8-12.1-11.9-5.1-2.2-10.6-1.7-15.6-1.3-5 .4-9.9.8-14.2-.3-4.3-.9-7.9-2.8-10.7-4.8-2.8-2-4.8-4.2-6.3-6.1-2.9-3.9-3.6-6.6-3.6-6.6.2-.1 1 2.5 4 6.3 1.5 1.8 3.5 3.9 6.3 5.8s6.3 3.7 10.5 4.5c4.1 1 8.8.6 13.9.1 5-.4 10.7-1 16.2 1.3 5.4 2.2 10.2 6.7 12.8 12.5 1.2 3 1.9 6.2 1.9 9.5 0 3.2-.3 6.5.5 9.5.7 3.1 2.7 5.8 5.7 6.4 2.9.8 6.1 0 9.2-.3 3-.4 6.5-.4 9.2 1.6 2.6 2 3.7 5.2 4 8 .3 3-.1 5.9-.9 8.5-.7 2.6-1.5 5.1-2.1 7.5-.6 2.4-.9 4.8-.6 7.1.3 2.2 1.1 4.3 2.3 6.1 2.3 3.5 6.3 4.9 9.6 4.5 1.6-.2 3.1-.8 4.2-1.7 1.1-.8 1.8-1.9 2.3-3 .9-2.2.7-4.1.4-5.3-.4-1.2-.8-1.7-.8-1.8ZM345.4 270.75c.2-.1-.9 2.2.9 5.7.9 1.6 2.6 3.5 5.2 4 2.5.4 5.8-.7 7.7-3.4 1-1.3 1.4-3 1.3-4.8-.2-1.8-.8-3.7-1.7-5.4-1.6-3.6-4.6-7.3-4.9-12.2-.4-4.7 1.3-9.8 4.9-13.6 1.8-1.8 3.9-3.4 6.3-4.5 2.4-1.2 5.1-1.7 7.7-2.2 2.5-.5 5.1-1 7.4-1.9 2.3-.9 4.3-2.4 5.4-4.5 1.1-2 1.4-4.4 1.2-6.7-.1-2.4-.7-4.6-1.5-6.7-1.5-4.3-3.4-8.3-3.8-12.6-.3-4.1.7-8.1 2.5-11.1 1.7-3.2 4.2-5.3 6.3-7.1 2.1-1.7 3.9-3.3 5.1-4.7 2.6-2.9 2.8-5.2 2.9-5.1q.1 0 0 .4c-.1.2-.1.6-.3 1.1-.3 1-1 2.4-2.2 3.9-1.2 1.6-3 3.2-5.1 5-2 1.8-4.4 3.9-5.9 6.9-1.6 3-2.6 6.7-2.2 10.6.4 4 2.2 8 3.8 12.3.8 2.2 1.5 4.5 1.6 7 .2 2.4 0 5.1-1.3 7.4-1.2 2.4-3.5 4.1-6 5.1s-5.1 1.4-7.7 1.9c-2.6.5-5.1 1.1-7.3 2.2-2.3 1-4.3 2.5-6 4.2-3.3 3.5-4.9 8.2-4.6 12.6.2 4.5 3 8.1 4.7 11.8.9 1.9 1.5 3.8 1.6 5.8.2 2-.3 4-1.4 5.5-2.2 2.9-5.8 4.1-8.7 3.5-2.8-.7-4.6-2.8-5.5-4.5-.8-1.8-.9-3.4-.8-4.4.1-1.1.4-1.5.4-1.5Z"
        fill="#263238"
      />
      <path
        d="M352.8 392.65s3.4-26.9 16.3-40.3c13-13.5 67.2-30.3 67.2-30.3s2.3 19.2 15.3 23.8c13.7 4.9 29.3 3.1 39.4-8.6 9.7-11.4 12.6-25.9 12.6-25.9s64.8 6 75.3 23.7c10.6 17.8 24.2 86.6 24.2 86.6l-60.5 12.9 8.2 118.5H389.9l3.1-111.3-43.8 6.3 3.6-55.4Z"
        fill="#0345B7"
      />
      <path
        d="M530.6 438.45c-.1 0-.2-1-.3-2.7v-7.3c0-6.1.1-14.6.3-24 .2-9.4.4-17.9.7-24 .1-3.1.2-5.6.3-7.3.2-1.7.3-2.6.4-2.6.1 0 .1.9.2 2.6 0 1.7.1 4.2.1 7.3 0 6.1-.1 14.6-.3 24-.2 9.4-.5 17.9-.7 24-.2 3.1-.3 5.6-.4 7.3-.1 1.7-.2 2.7-.3 2.7ZM378.1 389.65c.4-.1 4.2 11.9 8.5 26.8s7.4 27.1 7.1 27.2c-.4.1-4.2-11.9-8.5-26.8s-7.4-27.1-7.1-27.2ZM572.2 330.85c0 .2-2.9.7-7.3 2.4-4.5 1.7-10.3 4.7-15.8 9.5-5.4 4.7-9.2 10.1-11.5 14.2-2.3 4.2-3.2 7-3.4 6.9-.1 0 0-.8.4-2 .4-1.3 1.1-3.2 2.1-5.3 2.1-4.4 5.9-10 11.5-14.9 5.6-4.8 11.7-7.9 16.3-9.3 2.3-.7 4.2-1.2 5.6-1.4 1.3-.2 2-.2 2.1-.1ZM368.2 357.75c.3-.1 1.6 3.7 2.8 8.4 1.2 4.7 1.9 8.6 1.5 8.7-.4 0-1.7-3.7-2.9-8.4s-1.8-8.6-1.4-8.7Z"
        fill="#263238"
      />
      <path
        d="M592.4 423.95s-18.2 92.1-20.2 100c-2.1 7.9-5.5 29.1-27.4 33.5-29.7 5.9-146.2 1.8-146.2 1.8l22-27.8 101.9-28.6 13.9-67 56-11.9Z"
        fill="#FFBE9D"
      />
      <path
        d="M418.7 515.85c.1 0 .4.7.9 2.1.4 1.4.9 3.5 1.1 6.2.6 5.2-.2 12.8-4.1 20-2 3.6-4.2 6.8-6.7 9.3-2.5 2.5-5.3 4.2-7.8 5.2-2.5.9-4.6 1.2-6.1 1.3-1.5.1-2.3 0-2.3 0 0-.3 3.3-.2 8-2.3 2.4-1 5-2.7 7.3-5.1 2.3-2.5 4.4-5.6 6.3-9 3.8-6.9 4.7-14.3 4.4-19.4-.2-5.1-1.3-8.2-1-8.3Z"
        fill="#EB996E"
      />
      <path
        d="m352.8 394.05 19.8 51.2 20.4-3.5 26.3 75.9s7.5 33.3-18.1 41.1c-33.7 10.2-49.6-47.4-56.8-72.8-6.8-23.9-23.2-79.1-23.2-79.1l31.6-12.8Z"
        fill="#FFBE9D"
      />
      <path
        d="M410.3 520.05c-.3 0 1.8-4.8 0-12.5-.8-3.8-2.6-8.1-5.4-12.4-2.8-4.3-6.6-8.6-10.7-13.1-4-4.4-7.8-8.8-10.7-13.2-2.9-4.4-5-8.6-6.7-12.2-1.6-3.7-2.6-6.7-3.2-8.8-.7-2.1-.9-3.3-.8-3.4.1 0 .5 1.1 1.3 3.2.8 2 1.9 5 3.6 8.5 1.7 3.6 4 7.7 6.9 11.9 2.9 4.3 6.6 8.7 10.7 13.1 4 4.5 7.8 8.8 10.6 13.3 2.9 4.5 4.6 9 5.4 12.9.8 3.9.5 7.3.1 9.4q-.4 1.7-.7 2.5-.3.8-.4.8Z"
        fill="#EB996E"
      />
      <path
        d="m332.3 404.15-10.8 3.5-18.7-18.6-17.4-22.5-12.1-20.8s-.6-2.6 2.8-3.2c3.4-.6 9.9 10.8 9.9 10.8l6 7.7s6.2 4.5 6.2 3.7c0-.9-7.1-12-7.1-12l-14.3-24.8s-1-3.2 2.3-4.6c1.8-.8 4.6.5 7.2 3.8 2.5 3.3 5.7 9.8 7.1 11.7 1.4 2 9.2 13.3 9.2 13.3l-13.4-30s-5.4-8.9-1.2-10.6c4.2-1.6 6.9 5.8 8.8 8.3 1.9 2.5 11.3 22.8 15.6 27 4.3 4.1 3 1.3 3 1.3s-4.5-9.1-8.2-16.3c-3.6-7.1-4.3-12.5-2.1-15.1 2.2-2.6 10.5 14 10.5 14l12.2 22.7 6.3 5.2-.5-9.2s-6-7.2-4.1-14.1c1.9-6.9 5.9-4.4 5.9-4.4l15.6 34.8c1.9 6.2 1.8 28.3 1.8 28.3l-20.5 10.1Z"
        fill="#FFBE9D"
      />
      <path
        d="M318.3 383.15c.2-2.2.6-4.8 1.7-7.6 1-2.8 2.9-5.7 5.6-7.9 2.5-2 5.6-3.1 8.4-3l-.8.9c-.2-4.8.2-8.5.3-11.3.2-2.7.3-4.3.4-4.4.3 0 .6 1.7.6 4.4.2 2.7 0 6.8.3 11.1l.1.9h-.9c-2.4 0-5.1 1-7.3 2.8-5 4-6.5 10.2-7.2 14.3-.6 4.3-.8 7-1.1 7-.2-.1-.5-2.7-.1-7.2Z"
        fill="#EB996E"
      />
    </g>
  </svg>
);

export default SvgComponent;
