import { RootState, AppThunk } from "ducks/state";
import { createSelector } from "reselect";
import { newNotification } from "./notification";
import { hen, Hen } from "@udok/lib/internal/store";
import {
  cancelCallCareRecipient,
  entryToImmediateCareSession,
  startCareToImmediateCareSession,
  completeCareToImmediateCareSession,
} from "@udok/lib/api/immediateCareSession";
import { listPublicImmediateCare } from "@udok/lib/api/immediateCare";
import {
  StartCareForm,
  PublicImmediateCare,
  FilterPublicImmediateCare,
} from "@udok/lib/api/models";
import { getToken } from "./auth";

export type InitialState = {
  immediateCareByID: { [imcaID: string]: PublicImmediateCare | undefined };
  immediateCareByImcsID: { [imcsID: string]: string | undefined };
};

// Reducers
const initialState: InitialState = {
  immediateCareByID: {},
  immediateCareByImcsID: {},
};

class ImmediateCares extends Hen<InitialState> {
  immediateCareLoaded(ic: PublicImmediateCare) {
    this.state.immediateCareByID[ic.imcaID] = ic;
    const currentSession = ic.currentSession;
    if (currentSession) {
      this.state.immediateCareByImcsID[currentSession?.imcsID] = ic.imcaID;
    }
  }
  immediateCaresLoaded(list: PublicImmediateCare[]) {
    list.forEach((ic) => {
      this.state.immediateCareByID[ic.imcaID] = ic;
      const currentSession = ic.currentSession;
      if (currentSession) {
        this.state.immediateCareByImcsID[currentSession?.imcsID] = ic.imcaID;
      }
    });
  }
}

export const [Reducer, actions] = hen(new ImmediateCares(initialState));

// Selectors
const mainSelector = (state: RootState) => state.immediateCare;

export const getImmediateCareByImcsID = (props: { imcsID: string }) =>
  createSelector([mainSelector], (state) => {
    const imcaID = state.immediateCareByImcsID?.[props.imcsID] ?? "";
    return {
      immediateCare: state.immediateCareByID?.[imcaID],
    };
  });

// Actions
export function entryToImmediateCare(
  imcsID: string,
  appoID: string
): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;
    return entryToImmediateCareSession(apiToken, imcsID, appoID)
      .then((r) => {
        dispatch(
          newNotification("general", {
            status: "success",
            message: "Adicionado na fila de espera com sucesso",
          })
        );
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
        throw e;
      });
  };
}

export function cancelCall(imcsID: string): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;
    return cancelCallCareRecipient(apiToken, imcsID)
      .then((r) => {
        dispatch(
          newNotification("general", {
            status: "success",
            message: "Atendimento recusado",
          })
        );
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
        throw e;
      });
  };
}

export function startCare(
  imcsID: string,
  form: StartCareForm
): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;
    return startCareToImmediateCareSession(apiToken, imcsID, form)
      .then(() => {})
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
        throw e;
      });
  };
}

export function completeCare(
  imcsID: string,
  appoID: string
): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;
    return completeCareToImmediateCareSession(apiToken, imcsID, appoID)
      .then(() => {})
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
        throw e;
      });
  };
}

export function listImmediateCare(
  filter: FilterPublicImmediateCare
): AppThunk<Promise<void>> {
  return async (dispatch, _getState) => {
    return listPublicImmediateCare(filter)
      .then((r) => {
        dispatch(actions.immediateCaresLoaded(r));
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
        throw e;
      });
  };
}

export function loadCachedImmediateCareByImcsID(
  imcsID: string
): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const icExist = Boolean(state.immediateCare.immediateCareByImcsID[imcsID]);
    if (icExist) {
      return Promise.resolve();
    }
    return dispatch(listImmediateCare({ imcsID }));
  };
}
