import { createReducer } from "@udok/lib/internal/store";
import { createSelector } from "reselect";
import { Permission as pm } from "@udok/lib/internal/constants";
import { RootState } from "ducks/state";
import Icons from "components/Icon";

export interface MenuItem {
  title: string;
  description?: string;
  to: string;
  icon: keyof typeof Icons;
  illustration?: string;
  permissions: pm[];
}
export interface MenuView {
  title?: string;
  to?: string;
  icon?: keyof typeof Icons;
  permissions?: pm[];
  children: MenuItem[];
}

export interface InitialState {
  lateralMenu: MenuView[];
  quickAccess: MenuView[];
}

// Reducers
export const initialState = {
  lateralMenu: [
    {
      title: "Dashboard",
      permissions: [pm.Admin],
      to: "/",
      icon: "Dashboard",
      children: [],
    },
    {
      title: "Agendamentos",
      to: "/appointments",
      icon: "Calendar",
      permissions: [],
      children: [],
    },
    {
      title: "Documentos médicos",
      to: "/documents",
      icon: "Prescription",
      permissions: [],
      children: [],
    },
    {
      title: "Mensagens",
      to: "/conversations",
      icon: "Chat",
      permissions: [],
      children: [],
    },
  ],
  quickAccess: [
    {
      title: "Acesso rápido",
      permissions: [pm.Admin],
      children: [
        {
          title: "Agendamentos",
          to: "/appointments",
          icon: "CalendarToday",
          illustration: "CalendarWriteColor",
          description: "Visualize seus agendamentos",
          permissions: [],
        },
        {
          title: "Documentos médicos",
          to: "/documents",
          icon: "LocalHospital",
          illustration: "DocumentMedical",
          description: "Acesse todos seus documentos médicos.",
          permissions: [],
        },
      ],
    },
  ],
};

export const Reducer = createReducer(initialState, {});

// Selectors
const mainSelector = (state: RootState) => state.menu;

export const getSidebarMenu = createSelector([mainSelector], (state) => {
  return {
    menu: state.lateralMenu,
  };
});

export const quickAccessView = createSelector([mainSelector], (state) => {
  return {
    menu: state.quickAccess,
  };
});
