import * as React from "react";
import { SVGProps } from "react";

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    viewBox="0 0 58 58"
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    {...props}
  >
    <g fillRule="nonzero" fill="none">
      <rect fill="#84b5cb" height={43} rx={2} width={58} y={5} />
      <path
        d="M46.22 5C45 21 36 40 0 45.49V7a2.006 2.006 0 0 1 2-2z"
        fill="#9fc9d3"
      />
      <path
        d="M18 3.35V18H4V3.35A3.188 3.188 0 0 1 7 0h8a3.188 3.188 0 0 1 3 3.35z"
        fill="#f0c419"
      />
      <path
        d="M18 18v16.65A3.188 3.188 0 0 1 15 38H7a3.188 3.188 0 0 1-3-3.35V18z"
        fill="#ecf0f1"
      />
      <path
        d="M36 3.35V18H22V3.35A3.188 3.188 0 0 1 25 0h8a3.188 3.188 0 0 1 3 3.35z"
        fill="#f0c419"
      />
      <path
        d="M36 18v16.65A3.188 3.188 0 0 1 33 38h-8a3.188 3.188 0 0 1-3-3.35V18z"
        fill="#ecf0f1"
      />
      <path
        d="M54 3.35V18H40V3.35A3.188 3.188 0 0 1 43 0h8a3.188 3.188 0 0 1 3 3.35z"
        fill="#f0c419"
      />
      <path
        d="M54 18v16.65A3.188 3.188 0 0 1 51 38h-8a3.188 3.188 0 0 1-3-3.35V18z"
        fill="#ecf0f1"
      />
      <path d="m37 48 3 6-11 2-11-2 3-6 8-2z" fill="#2980ba" />
      <path
        d="M14 23H8a1 1 0 0 1 0-2h6a1 1 0 0 1 0 2zM32 23h-6a1 1 0 0 1 0-2h6a1 1 0 0 1 0 2zM32 27h-6a1 1 0 0 1 0-2h6a1 1 0 0 1 0 2zM14 27H8a1 1 0 0 1 0-2h6a1 1 0 0 1 0 2z"
        fill="#3f5c6c"
      />
      <path
        d="M11 8a1 1 0 1 1 .867-1.5 1 1 0 0 0 1.731-1A3 3 0 0 0 12 4.2V4a1 1 0 0 0-2 0v.184A2.993 2.993 0 0 0 11 10a1 1 0 1 1-.867 1.5 1 1 0 0 0-1.731 1 3 3 0 0 0 1.6 1.3v.2a1 1 0 0 0 2 0v-.184A2.993 2.993 0 0 0 11 8zM29 8a1 1 0 1 1 .867-1.5 1 1 0 0 0 1.731-1A3 3 0 0 0 30 4.2V4a1 1 0 0 0-2 0v.184A2.993 2.993 0 0 0 29 10a1 1 0 1 1-.867 1.5 1 1 0 0 0-1.731 1 3 3 0 0 0 1.6 1.3v.2a1 1 0 0 0 2 0v-.184A2.993 2.993 0 0 0 29 8zM50 11a3 3 0 0 0-3-3 1 1 0 1 1 .867-1.5 1 1 0 0 0 1.731-1A3 3 0 0 0 48 4.2V4a1 1 0 0 0-2 0v.184A2.993 2.993 0 0 0 47 10a1 1 0 1 1-.867 1.5 1 1 0 0 0-1.731 1 3 3 0 0 0 1.6 1.3v.2a1 1 0 0 0 2 0v-.184A3 3 0 0 0 50 11z"
        fill="#e57e25"
      />
      <g fill="#3f5c6c">
        <path d="M50 23h-6a1 1 0 0 1 0-2h6a1 1 0 0 1 0 2zM50 27h-6a1 1 0 0 1 0-2h6a1 1 0 0 1 0 2zM50 31h-6a1 1 0 0 1 0-2h6a1 1 0 0 1 0 2zM32 31h-6a1 1 0 0 1 0-2h6a1 1 0 0 1 0 2zM50 35h-6a1 1 0 0 1 0-2h6a1 1 0 0 1 0 2z" />
      </g>
      <rect fill="#3b97d3" height={4} rx={2} width={34} x={12} y={54} />
      <path
        d="M58 42v4a2.006 2.006 0 0 1-2 2H2a2.006 2.006 0 0 1-2-2v-4z"
        fill="#3b97d3"
      />
    </g>
  </svg>
);

export default SvgComponent;
