import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { AppDispatch } from "ducks/state";
import {
  loadEventAlerts,
  getEventAlertsList,
  getAppointmentEvents,
  readEventAlertList,
} from "ducks/events";
import { FilterEventAlert, EventAlertType } from "@udok/lib/api/models";
import moment from "moment";

export const useAppointmentEvents = () => {
  const dispatch: AppDispatch = useDispatch();
  const paths: string[] = React.useMemo(
    () => [
      EventAlertType.newAppointment,
      EventAlertType.appointmentStatusChange,
      EventAlertType.appointmentReschedule,
      EventAlertType.appointmentConfirmed,
    ],
    []
  );

  const loadEvents = React.useCallback(() => {
    const filter: FilterEventAlert = {
      paths: paths,
      limit: 100,
      "createdAt[gte]": moment().subtract(30, "days").format(),
    };
    return dispatch(loadEventAlerts(filter));
  }, [dispatch, paths]);

  React.useEffect(() => {
    loadEvents();
    const t = setInterval(() => loadEvents(), 60000);
    return () => {
      clearInterval(t);
    };
  }, [loadEvents]);

  const { alerts } = useSelector(getEventAlertsList);
  const list = alerts
    .filter((a) => paths.indexOf(a.path) !== -1)
    .sort((a, b) => moment(b.createdAt).diff(moment(a.createdAt)));
  const orderedList = [
    ...list.filter((eve) => !eve?.readAt),
    ...list.filter((eve) => !!eve?.readAt),
  ];

  return [orderedList] as [typeof orderedList];
};

export const useReadAppointmentEvents = (appoID: string) => {
  const dispatch: AppDispatch = useDispatch();

  const getList = React.useCallback(getAppointmentEvents({ appoID }), [appoID]);
  const { alerts } = useSelector(getList);

  const unread = React.useMemo(
    () => alerts.filter((a) => !a?.readAt),
    [alerts]
  );

  const handleReadAll = React.useCallback(() => {
    const notiIDs = unread.map((ur) => ur.notiID);
    dispatch(readEventAlertList(notiIDs));
  }, [unread, dispatch]);

  React.useEffect(() => {
    handleReadAll();
    // eslint-disable-next-line
  }, []);
};
