import * as React from "react";

function Healthplan(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={140.367}
      height={100.353}
      viewBox="0 0 140.367 100.353"
      {...props}
    >
      <g data-name="Group 48">
        <path
          data-name="&lt;Path&gt;"
          d="M133.8 76.776H19.688c-3.609 0-6.563 1.789-6.563 3.975 0 1.514 1.417 2.838 3.487 3.509H6.563C2.954 84.26 0 86.049 0 88.237s2.954 3.975 6.563 3.975h3.5a3.253 3.253 0 00-1.571 2.573v1.59c0 2.188 2.952 3.977 6.563 3.977h112.418c3.609 0 6.563-1.789 6.563-3.977v-1.59c0-2.186-2.954-3.977-6.563-3.977l-7.644.089a3.631 3.631 0 002.161-.839 3.127 3.127 0 00.619-3.709 2.711 2.711 0 00-2.317-1.676l13.512.053c3.609 0 6.563-1.789 6.563-3.977s-2.954-3.973-6.567-3.973z"
          fill="#0B93D8"
          opacity={0.4}
        />
        <g data-name="Group 47">
          <g data-name="&lt;Group&gt;">
            <path
              data-name="&lt;Path&gt;"
              d="M46.638 23.508l2.26.595 9.516 8.327-1.427 4.52h-4.639z"
              fill="#8ec3cb"
            />
            <g data-name="&lt;Group&gt;">
              <path
                data-name="&lt;Path&gt;"
                d="M101.112 27.077l-7.732 5.829-6.543 1.189v2.379l9.6-.764 8.841-4.589-1.665-4.947z"
                fill="#4EA6D1"
              />
            </g>
            <g data-name="&lt;Group&gt;">
              <g data-name="&lt;Group&gt;">
                <path
                  data-name="&lt;Path&gt;"
                  d="M111.49 91.16l.115 1.142-2.071 1.424-1.21-1 .978-1.282 2.072-1.424z"
                  fill="#211e1c"
                />
              </g>
              <g data-name="&lt;Group&gt;">
                <path
                  data-name="&lt;Path&gt;"
                  d="M111.44 56.242l.351 3.467-3.107 11.917 2.885 19.151-2.123.733-5.6-18.7-.889-5.439.032-11.514z"
                  fill="#cab5a1"
                />
              </g>
            </g>
            <g data-name="&lt;Group&gt;">
              <g data-name="&lt;Group&gt;">
                <path
                  data-name="&lt;Path&gt;"
                  d="M95.108 60.107l4.47 32.088h2.031l.365-20.569 3.695-14.436z"
                  fill="#cab5a1"
                />
              </g>
              <g data-name="&lt;Group&gt;">
                <path
                  data-name="&lt;Path&gt;"
                  d="M101.593 91.942v1.153l-2.195 1.153-1.1-1.153 1.1-1.153z"
                  fill="#211e1c"
                />
              </g>
            </g>
            <g data-name="&lt;Group&gt;">
              <path
                data-name="&lt;Path&gt;"
                d="M111.327 52.386a127.624 127.624 0 01-1.2-21.971 4.733 4.733 0 00-1.69-3.462c-.7-.156-1.312-.608-2.021-.786-4.794-1.206-7.409 2.454-9.2 6.4a113.324 113.324 0 00-5.571 15.019 84.483 84.483 0 00-3.192 13.712c-.228 1.927-.419 3.755 1.691 4.6 3.362 1.355 7.141 1.325 10.692 1.76 4.079.5 8.164.864 12.27 1.034 2.137.097-.045-4.911-1.779-16.306z"
                fill="#047FBA"
              />
            </g>
            <g data-name="&lt;Group&gt;">
              <path
                data-name="&lt;Path&gt;"
                d="M90.712 59.288l6.237-2.83 4.044-4.282 1.309 1.427-3.331 5.115-5.092 3.934z"
                fill="#d34e4e"
              />
            </g>
            <g data-name="&lt;Group&gt;">
              <path
                data-name="&lt;Path&gt;"
                d="M85.688 62.644l-3.327-5.7-.32-6.891 1.9-.088 1.135 6.5 3.1 2.6z"
                fill="#d34e4e"
              />
            </g>
            <g data-name="&lt;Group&gt;">
              <path
                data-name="&lt;Path&gt;"
                d="M84.959 79.481v13.255h2l1.25-8 1.126-6.127z"
                fill="#3b455a"
              />
            </g>
            <g data-name="&lt;Group&gt;">
              <path
                data-name="&lt;Path&gt;"
                d="M89.337 78.105l.75 5.753 1 9.255 2-.125v-7.129l1-7.253z"
                fill="#3b455a"
              />
            </g>
            <g data-name="&lt;Group&gt;">
              <path
                data-name="&lt;Path&gt;"
                d="M88.398 58.783q-2.125-.375-3 2.876c-.875 3.252-3.251 12.757-3.377 17.759s3.127 1.751 7 2.376 7.066.689 7.066-1.563 1.377-21.385-7.689-21.448z"
                fill="#d34e4e"
              />
            </g>
            <g data-name="&lt;Group&gt;">
              <g data-name="&lt;Group&gt;">
                <path
                  data-name="&lt;Path&gt;"
                  d="M87.178 52.32c1.771-3.123 5.738-1.124 6.01 1.987.155 1.769-1.029 4.72-3.113 4.57-.13.285-.357.826-.651.947-.316.131-1.017-.337-1.294-.509a.494.494 0 01-.2-.183.916.916 0 01.083-.536 2.992 2.992 0 01-.634-.366 3.415 3.415 0 01-1.21-2.864 6.852 6.852 0 011.009-3.046z"
                  fill="#fcd8b9"
                />
              </g>
              <g data-name="&lt;Group&gt;">
                <path
                  data-name="&lt;Path&gt;"
                  d="M89.65 51.509q2.834.708 2.834 1.889c0 1.775.118 2.966 0 4.022l2.007-1.188 1.18-3.306q-.235-2.716-1.652-3.187a29.837 29.837 0 00-4.368-.945l-2.243.708-1.652-.118.571 4.958q.726-3.542 3.323-2.833z"
                  fill="#3b455a"
                />
              </g>
            </g>
            <g data-name="&lt;Group&gt;">
              <path
                data-name="&lt;Path&gt;"
                d="M84.959 92.736l-1.125 1.127.5.749 2.252-.749v-1.127z"
                fill="#2a2c2d"
              />
            </g>
            <g data-name="&lt;Group&gt;">
              <path
                data-name="&lt;Path&gt;"
                d="M91.421 93.091l.416 1.646 1.376.5.75-.375-1.165-1.858z"
                fill="#2a2c2d"
              />
            </g>
            <g data-name="&lt;Group&gt;">
              <path
                data-name="&lt;Path&gt;"
                d="M100.993 52.176l.238-1.427h.476l.952-.952.357.714-.238 1.784-.845.811z"
                fill="#fcd8b9"
              />
            </g>
            <g data-name="&lt;Group&gt;">
              <path
                data-name="&lt;Path&gt;"
                d="M83.546 49.985l1.031-1.615-.476-.595-.595.595-.594-1.666-1.308.595.672 2.746z"
                fill="#fcd8b9"
              />
            </g>
            <g data-name="&lt;Group&gt;">
              <path
                data-name="&lt;Path&gt;"
                d="M110.152 33.124l-11.063 8.227-12.466-.69.071-2.518 10.663-1.192 8.42-7.221 4.508-1.387z"
                fill="#5EB6EC"
              />
            </g>
            <g data-name="&lt;Group&gt;">
              <path
                data-name="&lt;Path&gt;"
                d="M86.712 38.205l-2.806-.076-1.359 1.383.382.754 1.588.294 2.127-.6z"
                fill="#fcd8b9"
              />
            </g>
            <g data-name="&lt;Group&gt;">
              <g data-name="&lt;Group&gt;">
                <path
                  data-name="&lt;Path&gt;"
                  d="M103.591 15.186q-1.536.728-1.891 4.724a22.835 22.835 0 01-1.342 6.43 2.377 2.377 0 002.284.567c1.111-.362 1.5-.393 1.529-2.045q.029-1.652 1.157-9.283z"
                  fill="#3b455a"
                />
              </g>
              <g data-name="&lt;Group&gt;">
                <path
                  data-name="&lt;Path&gt;"
                  d="M106.436 17.272a4.014 4.014 0 00-3.581 2.573 3.535 3.535 0 012.132-5.361 4.087 4.087 0 011.333 2.787z"
                  fill="#3b455a"
                />
              </g>
              <g data-name="&lt;Group&gt;">
                <path
                  data-name="&lt;Path&gt;"
                  d="M102.98 18.493c1.745-3.8 6.55-1.876 7.175 1.709.354 2.039-.727 5.581-3.161 5.615-.123.345-.333 1-.661 1.165-.354.183-1.215-.289-1.554-.462a.585.585 0 01-.246-.194 1.075 1.075 0 01.043-.632 3.379 3.379 0 01-.772-.362 3.978 3.978 0 01-1.69-3.206 7.985 7.985 0 01.866-3.633z"
                  fill="#fcd8b9"
                />
              </g>
              <g data-name="&lt;Group&gt;">
                <path
                  data-name="&lt;Path&gt;"
                  d="M105.091 14.497q-1.019 2.045 1.6 3.332c2.615 1.288 3.872 3.9 2.829 6.063s-3.507 3.34-2.574 4.21q.933.869 5.048 1.254 4.406-7.455 2.026-11.748c-2.386-4.292-7.044-5.577-8.929-3.111z"
                  fill="#3b455a"
                />
              </g>
            </g>
            <g data-name="&lt;Group&gt;">
              <g data-name="&lt;Group&gt;">
                <path
                  data-name="&lt;Path&gt;"
                  d="M102.285 8.81C87.743 9.097 81.929 1.228 81.162.083c0-.065-.016-.089-.045-.068q-.029-.046-.068.046c-1.142.77-9.494 5.865-22.854.112q-7.057 24.679-.932 36.512c6.544 12.638 13.228 17.381 13.269 17.359.032.036 8.062-1.759 18.839-11.07q10.085-8.708 12.914-34.164z"
                  fill="#0345B7"
                />
              </g>
              <g data-name="&lt;Group&gt;">
                <path
                  data-name="&lt;Path&gt;"
                  d="M99.735 10.992c-13.186.208-18.483-6.821-19.183-7.843 0-.058-.014-.08-.041-.061-.018-.027-.039-.013-.062.04-1.032.683-8.59 5.195-20.723.026q-6.32 21.965-.727 32.526c5.975 11.281 11.928 16.124 11.966 16.105.029.032 7.427-2.138 17.17-10.4q9.121-7.724 11.6-30.393z"
                  fill="#056DC8"
                />
              </g>
              <g data-name="&lt;Group&gt;">
                <path
                  data-name="&lt;Path&gt;"
                  d="M80.02 5.33q.038-.086.064-.043c.028-.019.043 0 .044.064.719 1.049 6.067 8.147 19.295 8.217q.385-2.291.543-3.739c-13.187.208-18.483-6.821-19.182-7.843 0-.06-.014-.08-.041-.061-.019-.027-.039-.014-.062.04-1.032.683-8.59 5.195-20.723.026q-.4 1.4-.916 3.664c12.278 4.934 19.915.375 20.978-.325z"
                  fill="#0354C5"
                />
              </g>
              <g data-name="&lt;Group&gt;">
                <path
                  data-name="&lt;Path&gt;"
                  d="M94.77 16.465c-9.971.2-15.05-5.65-15.576-6.434 0-.046-.011-.062-.031-.048q-.022-.03-.046.032c-.784.528-7.63 4.025-16.789.082q-3.719 16.915.482 25.027c4.487 8.665 9.069 11.917 9.1 11.9.021.025 5.527-1.206 12.915-7.589q6.911-5.97 9.945-22.97z"
                  fill="#C7E0EC"
                  opacity={0.341}
                />
              </g>
            </g>
            <g data-name="&lt;Group&gt;">
              <path
                data-name="&lt;Path&gt;"
                d="M37.519 9.691a2.3 2.3 0 00-1.642.859c-.606.759-2.523 4.194-2.5 6.863s-.391 5.982 4.884 8.292c2.628.858 2.687-1.5 4.789-1.639s3.917.591 3.629-2.676-2.349-4-3.494-7.65-2.944-5.121-5.666-4.049z"
                fill="#0B93D8"
              />
            </g>
            <g data-name="&lt;Group&gt;">
              <path
                data-name="&lt;Path&gt;"
                d="M36.76 9.902l-4.675 1.581-3.528.513.052 1.531 3.443.483 3.7-.729z"
                fill="#0B93D8"
              />
            </g>
            <g data-name="&lt;Group&gt;">
              <path
                data-name="&lt;Path&gt;"
                d="M41.123 9.489l3.409 1.235 2.861-1.012.444.959-2.7 1.956-2.7.183z"
                fill="#0B93D8"
              />
            </g>
            <g data-name="&lt;Group&gt;">
              <g data-name="&lt;Group&gt;">
                <path
                  data-name="&lt;Path&gt;"
                  d="M40.256 2.919a3.69 3.69 0 011.941 3.532 2.178 2.178 0 00.8-2.686c-.6-1.563-1-2.178-2.48-1.849q-1.48.331-.261 1.003z"
                  fill="#3b455a"
                />
              </g>
              <g data-name="&lt;Group&gt;">
                <path
                  data-name="&lt;Path&gt;"
                  d="M42.17 5.27c-.259-3.165-4.187-3.037-5.562-.641-.783 1.362-.93 4.171.8 4.826 0 .277-.021.8.169 1 .206.224.942.109 1.229.074a.44.44 0 00.226-.074.827.827 0 00.133-.463 2.579 2.579 0 00.645-.057 3.021 3.021 0 002.038-1.849 6.06 6.06 0 00.322-2.816z"
                  fill="#fcd8b9"
                />
              </g>
              <g data-name="&lt;Group&gt;">
                <path
                  data-name="&lt;Path&gt;"
                  d="M40.755 3.751c-1.1 1.155-2.845 1.229-3.893 2.349a3.121 3.121 0 00-.509 2.48 2.584 2.584 0 01-2.631-1.745q-.8-1.824 1.044-5.417a5.849 5.849 0 015-.906 1.832 1.832 0 011.621 2.05 2.356 2.356 0 01-.632 1.189z"
                  fill="#3b455a"
                />
              </g>
            </g>
            <g data-name="&lt;Group&gt;">
              <path
                data-name="&lt;Path&gt;"
                d="M36.721 9.939q-.214 1.6.633 1.781a6.189 6.189 0 003.139-.469q.467-.407 0-1.583l.629-.181q2.91 5.94.566 6.833c-2.343.892-5.31 1.144-5.8-.732a12.888 12.888 0 01.208-5.393z"
                fill="#F78F9B"
              />
            </g>
            <g data-name="&lt;Group&gt;">
              <path
                data-name="&lt;Path&gt;"
                d="M28.563 12.164l-1.727-.407-.079.363 1.178 1.012-.294.19.215.172.746-.19z"
                fill="#fcd8b9"
              />
            </g>
            <g data-name="&lt;Group&gt;">
              <path
                data-name="&lt;Path&gt;"
                d="M47.435 9.837l.445-.551.873.061.234.683-.4.676-.756-.082z"
                fill="#fcd8b9"
              />
            </g>
            <g data-name="&lt;Group&gt;">
              <path
                data-name="&lt;Path&gt;"
                d="M54.36 49.422l.431 18.406-3.187 23.776-2.668.032-1.944-21.878-3.91-21.857z"
                fill="#3b455a"
              />
            </g>
            <g data-name="&lt;Group&gt;">
              <path
                data-name="&lt;Path&gt;"
                d="M48.948 91.319l.018 1.442 2.9 1.408 1.425-1.46-1.46-1.425z"
                fill="#3b455a"
              />
            </g>
            <g data-name="&lt;Group&gt;">
              <path
                data-name="&lt;Path&gt;"
                d="M39.872 92.013l2.293 1.95-.573 1.147-4.243-.917v-2.179z"
                fill="#3b455a"
              />
            </g>
            <g data-name="&lt;Group&gt;">
              <path
                data-name="&lt;Path&gt;"
                d="M45.111 56.663l-1.907 17.84-2.515 17.784-3.588-.171.131-18.448-2.16-17.111 5.072-2.362z"
                fill="#3b455a"
              />
            </g>
            <g data-name="&lt;Group&gt;">
              <path
                data-name="&lt;Path&gt;"
                d="M47.225 23.864s4.43 2.93 7.245 12.431a71 71 0 012.445 22.475q-25.2 4.264-24.678.067-.833-33.9 10.349-34.735c.508-.038 4.365-.448 4.639-.238z"
                fill="#8ec3cb"
              />
            </g>
            <g data-name="&lt;Group&gt;">
              <path
                data-name="&lt;Path&gt;"
                d="M46.155 24.816c1.9 4.636 3.326 9.007 5.279 13.62 1.157 2.735 1.169 5.59 1.335 8.5l.493 8.685q.105 1.834.208 3.67l-10.533 1.107c.607-3.507 1.3-7 1.843-10.517.5-3.244 1.266-6.886.669-10.158-.29-1.592-.6-3.182-.9-4.775-.547-2.92-.791-5.909-1.364-8.824a8.77 8.77 0 01.472-1.546z"
                fill="#fff"
              />
            </g>
            <g data-name="&lt;Group&gt;">
              <g data-name="&lt;Group&gt;">
                <path
                  data-name="&lt;Path&gt;"
                  d="M43.199 24.622l.007.547-2.872 6.746-1.27-.42.743-6.563 1.976-1.121a.947.947 0 011.416.811z"
                  fill="#fcd8b9"
                />
              </g>
              <g data-name="&lt;Group&gt;">
                <path
                  data-name="&lt;Path&gt;"
                  d="M40.501 31.835l.686 1.235-.536.311-1.677-1.118.112-.895z"
                  fill="#3b455a"
                />
              </g>
            </g>
            <g data-name="&lt;Group&gt;">
              <g data-name="&lt;Group&gt;">
                <path
                  data-name="&lt;Path&gt;"
                  d="M48.669 22.489l.331.437 1.69 7.131-1.272.415-3.3-5.723.924-2.076a.948.948 0 011.627-.184z"
                  fill="#e0c5a9"
                />
              </g>
              <g data-name="&lt;Group&gt;">
                <path
                  data-name="&lt;Path&gt;"
                  d="M50.779 30.015l1.283.586-.246.567-2.014.1-.441-.786z"
                  fill="#3b455a"
                />
              </g>
            </g>
            <g data-name="&lt;Group&gt;">
              <g data-name="&lt;Group&gt;">
                <path
                  data-name="&lt;Path&gt;"
                  d="M46.111 24.992l-2.462-.4a12.487 12.487 0 01.255-1.971c-.673-.283-1.447-.695-1.523-1.421a2.025 2.025 0 01.082-.683l.655-2.779a2.541 2.541 0 01.349-.917 2.115 2.115 0 011.525-.708 5.134 5.134 0 014.092 1.264 2.238 2.238 0 01.646.967 3.05 3.05 0 01-.211 1.774l-.523 1.592c-.2.61-.478 1.3-1.09 1.5a5.306 5.306 0 00-.994.211c-.528.292-.505 1.048-.801 1.571z"
                  fill="#fcd8b9"
                />
              </g>
              <g data-name="&lt;Group&gt;">
                <path
                  data-name="&lt;Path&gt;"
                  d="M54.761 16.135a8 8 0 00-.086-1.889c-.158-1.019-1.317-1.545-2.339-1.681-2.209-.291-4.288-1.431-6.5-.789-1.266.366-2.935.37-4.094.925a3.319 3.319 0 00-1.2 1.049 4.994 4.994 0 00-.334 5.271 7.1 7.1 0 00.716 1.025 5.343 5.343 0 001.988 1.778c.063-.717-.454-3.026.133-3.582.934-.883 2.9-.435 3.971-.139 1.171.323 2.177 1.228 3.343 1.479a4.437 4.437 0 002.69-.563 3.37 3.37 0 001.712-2.884z"
                  fill="#3b455a"
                />
              </g>
            </g>
            <g data-name="&lt;Group&gt;">
              <path
                data-name="&lt;Path&gt;"
                d="M39.85 24.935l-13.085 8.089 2.736 4.4 8.089-3.807z"
                fill="#8ec3cb"
              />
            </g>
            <g data-name="&lt;Group&gt;">
              <path
                data-name="&lt;Path&gt;"
                d="M35.65 21.543l-8.9 11.484 2.755 4.428 3.093-1.456 5.041-13.016z"
                fill="#a5dfe5"
              />
            </g>
            <g data-name="&lt;Group&gt;">
              <path
                data-name="&lt;Path&gt;"
                d="M36.042 21.827l1.191-1.531 2.855-.476.476 1.19-1.427 1.19-1.807.561z"
                fill="#fcd8b9"
              />
            </g>
            <g data-name="&lt;Group&gt;">
              <g data-name="&lt;Group&gt;">
                <path
                  data-name="&lt;Path&gt;"
                  d="M34.364 76.3q.846.712 1.432-.671c.586-1.382 1.2-1.184 1.538-.7s-.388.454-.777 1.539a9.142 9.142 0 01-1.744 3.237c-.3.182-1.2.621-1.223 1.3s-.88-2.064-.8-2.2q.086-.122 1.574-2.505z"
                  fill="#474ebb"
                />
              </g>
              <g data-name="&lt;Group&gt;">
                <path
                  data-name="&lt;Path&gt;"
                  d="M34.768 78.313q.564.948 1.571-.165c1.008-1.113 1.521-.726 1.682-.154s-.516.3-1.238 1.2a9.164 9.164 0 01-2.709 2.486c-.341.074-1.341.191-1.582.832s-.155-2.239-.033-2.338q.126-.095 2.309-1.861z"
                  fill="#fff"
                />
              </g>
              <g data-name="&lt;Group&gt;">
                <path
                  data-name="&lt;Path&gt;"
                  d="M27.849 84.506q1.137 3.158.581 4.019c-.557.861-2.094 3.716-1.86 4.2s.113.7-.3.547a1.227 1.227 0 01-.748-1.62c.237-1.08.712-2.334.356-3.242q-.357-.906.439-3.577z"
                  fill="#474ebb"
                />
              </g>
              <g data-name="&lt;Group&gt;">
                <path
                  data-name="&lt;Path&gt;"
                  d="M28.996 73.3q-1.174-.182-1.091 1.159c.083 1.341-1.19 3.675-3.282 5.405-2.046 1.692-3.96 4.313-1.588 7.279s6.871-.115 7.838-1.813 3.664-2.774 4.217-5.282-.846-3.657-.912-4.435q-.063-.777-5.182-2.313z"
                  fill="#fff"
                />
              </g>
              <g data-name="&lt;Group&gt;">
                <path
                  data-name="&lt;Path&gt;"
                  d="M27.889 86.954a2.369 2.369 0 01-.611 2.766c-1.217 1.149-2.336 1.67-2.775 2.506s.521 1.121-.209 1.12-1.22-.113-1.155-1.02 1.548-1.693 1.347-2.962-1.178-1.529-1.292-2.464q-.114-.937 2.775-2.507l2.1 2.625z"
                  fill="#fff"
                />
              </g>
              <g data-name="&lt;Group&gt;">
                <path
                  data-name="&lt;Path&gt;"
                  d="M22.408 85.696a1.587 1.587 0 01-1.929-.335 1.916 1.916 0 01.059-2.867c.911-.761 1.6.064 1.517.345s-1.063 1.363-.05 2.21q.488.313.403.647z"
                  fill="#474ebb"
                />
              </g>
              <g data-name="&lt;Group&gt;">
                <path
                  data-name="&lt;Path&gt;"
                  d="M34.45 75.116l-.32.409q-1.811 1.119-4.665-.854-1.208-1.121-.981-1.414l.528-.012q1.639 2.69 5.438 1.871z"
                  fill="#d34e4e"
                />
              </g>
              <g data-name="&lt;Group&gt;">
                <path
                  data-name="&lt;Path&gt;"
                  d="M30.882 68.686a.887.887 0 01.678-.5c.556-.15 1.629.557 1.852.58s.751.024.3.334a3.483 3.483 0 01-2.83-.414z"
                  fill="#474ebb"
                />
              </g>
              <g data-name="&lt;Group&gt;">
                <path
                  data-name="&lt;Path&gt;"
                  d="M29.255 70.379a2.744 2.744 0 011.894-1.765 3.216 3.216 0 012.246.311c.357.18.665.426 1.008.625.228.132.477.156.7.306a2.244 2.244 0 01.924 1.769c-.017.642 1.1 2.241-.808 3.168s-4.877.368-5.7-.539-1.595-1.842-.264-3.875z"
                  fill="#fff"
                />
              </g>
              <g data-name="&lt;Group&gt;">
                <path
                  data-name="&lt;Path&gt;"
                  d="M29.621 70.446a1.125 1.125 0 00-1.128.155c-.439.371-.858.378-.708.934s.4 1.975 1.17 2.239.333-.842.576-1.322.495-1.924.09-2.006z"
                  fill="#474ebb"
                />
              </g>
            </g>
          </g>
          <g data-name="Group 46" fill="#fff">
            <path
              data-name="Rectangle 15"
              d="M75.705 15.464l4.827 1.027-4.546 21.378-4.828-1.027z"
            />
            <path
              data-name="Rectangle 16"
              d="M87.047 26.527l-1.027 4.827-21.378-4.547 1.027-4.827z"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}

export default Healthplan;
