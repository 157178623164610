import React from "react";
import { useParams } from "react-router";
import {
  createStyles,
  makeStyles,
  Theme,
  ThemeProvider,
} from "@material-ui/core/styles";
import theme from "@udok/lib/themes/paperbase";
import UdokDownload from "@udok/lib/components/UdokDownload";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: "100vh",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      backgroundColor: theme.palette.background.paper,
    },
  })
);

type UdokPescriptionDownloadProps = {
  guid: string;
  verification: string;
};

const UdokPescriptionDownload = (props: UdokPescriptionDownloadProps) => {
  const { guid, verification } = props;
  const classes = useStyles();
  const url = `${process.env.REACT_APP_AUTH_PATH}/prescription-share/${guid}/${verification}`;

  return (
    <div className={classes.root}>
      <UdokDownload url={url} type="application/pdf" />
    </div>
  );
};

const UdokPescriptionQueryParams = (p: any) => {
  const { guid, verification } =
    useParams<{ guid: string; verification: string }>();
  return (
    <ThemeProvider theme={theme}>
      <UdokPescriptionDownload guid={guid} verification={verification} {...p} />
    </ThemeProvider>
  );
};

export default UdokPescriptionQueryParams;
